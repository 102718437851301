import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import { useHomePageSelector } from "../../redux/selector/homePage";
import { getHelperpagesInfo } from "../../redux/slices/homePage";

const PrivacyPolicy = () => {
  document.title = "Privacy Policy";
  const homeSelector = useHomePageSelector();
  const { loading } = homeSelector;
  const dispatch = useDispatch();
  const [privacyPolicy, setprivacyPolicy] = useState("");

  /**
   * to get privacy poilicy
   * @param {*} e
   */
  const privacyAndPolicy = () => {
    let params = {
      slug: "privacy_policy",
    };
    dispatch(
      getHelperpagesInfo({
        ...params,
        cb(res) {
          if (res.status) {
            setprivacyPolicy(res?.data?.data);
          }
        },
      })
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    privacyAndPolicy();
  }, []);
  return (
    <>
      <div className="privacy_Page">
        <Navbar />
        <section className="privay_Policy">
          {loading ? (
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          ) : (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="privacy_Txt_Parent text-center">
                    <h2 className="subheading">{privacyPolicy?.title}</h2>
                    <p className="fontSize20 privacy_Lastdate">
                      Last Updated{" "}
                      {moment(privacyPolicy?.updatedAt).format("MMM Do YYYY")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="table_Content_Summary privacy_Content_Summary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: privacyPolicy?.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

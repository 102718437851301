import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import { useSubscriptionSelector } from "../../redux/selector/subscription";
import {
  getAllPaymentCards,
  getAllSubscription,
  getSubscriptionInfo,
} from "../../redux/slices/subscription";
import * as Image from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import CardPaymentModal from "../Modal/AddCardPaymentModal";
import PaymentSuccessModal from "../Modal/paymentSuccessModal";
import PaymentDetail from "./paymentdetail";

const Subscriptions = () => {
  document.title = "Pricing";
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = Boolean(localStorage.getItem("authToken"));
  const subscriptionSelector = useSubscriptionSelector();
  console.log(subscriptionSelector?.getSubscriptionInfo,"subscriptionSelector");
  
  const { loading } = subscriptionSelector;
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [getAllPlans, setAllPlans] = useState([]);
  const [selectedPlanType, setSelectedPlanType] = useState([]);
  const [currentPlannfo, setCurrentPlannfo] = useState("");
  
  console.log(currentPlannfo, "currentPlannfo");

  const [selectedPlanData, setSelectedPlanData] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = (flag) => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
    if (flag === "paymentDetail") {
      navigate("/subscriptions");
    }
  };

  // open modal
  const handleOpenModal = (flag) => {
    if (flag?.token === "confirmToken") {
    }
    const modalFlag = flag?.flags || flag;
    setModalDetail({
      show: true,
      flag: modalFlag,
      type: modalFlag,
    });
    setKey(Math.random());
  };

  const allSubscriptionPlans = () => {
    dispatch(
      getAllSubscription({
        cb(res) {
          if (res?.data) {
            setAllPlans(res?.data?.data);
            let planType = [];
            res?.data?.data?.map((i) => {
              let isexist = i.plans.find(
                (item) =>
                  item._id ===
                  subscriptionSelector?.getSubscriptionInfo?.planId?._id
              );
              return i?.plans?.forEach((j) => {
                if (
                  isexist &&
                  j._id ==
                    subscriptionSelector?.getSubscriptionInfo?.planId?._id
                ) {
                  planType.push(j);
                } else if (!isexist && j.planType == "month") {
                  planType.push(j);
                } else if (!isexist && j.planType == "Free") {
                  planType.unshift(j);
                }
              });
            });
            setSelectedPlanType(planType);
          }
        },
      })
    );
  };

  /**
   * To get current subscription of user
   */
  const getCurrentSubscriptionInfo = () => {
    dispatch(
      getSubscriptionInfo({
        cb(res) {
          if (res?.data) {
            setCurrentPlannfo(res?.data)
          }
        },
      })
    );
  };

  useEffect(() => {
    allSubscriptionPlans();
    if (isAuthenticated) {
      getCurrentSubscriptionInfo();
    }
  }, []);

  useEffect(() => {
    if (location?.state?.flag === "Plan") {
      handleOpenModal("paymentModal");
    }
  }, []);

  return (
    <>
      <div className="subscriptMain">
        {!isAuthenticated ? <Navbar /> : <></>}
        <section className=" subscriptionBanner_">
          <div className="container">
            <div className={isAuthenticated ? "planInnerHead pt-2" : ""}>
              {isAuthenticated && subscriptionSelector?.getSubscriptionInfo? (
                <img
                  src={Image.arrowLink}
                  className="img-fluid pointCursor arrowLink_Img"
                  alt="arrowLink image"
                  onClick={() => {
                    navigate("/subscriptions");
                  }}
                />
              ) : (
                ""
              )}

              <div className="row">
                <h2 className="subheading mb-4">
                  Pricing for companies of all sizes
                </h2>
                <p className="sub_p_text">
                  Start for free, then scale as you grow.
                </p>
              </div>
            </div>
            {console.log("selectedPlanType", selectedPlanType)}
            <div className="row mt-4 justify-content-center">
              {loading ? (
                <div className="loaderOuter d-flex justify-content-center text-light">
                  <div className="spinner-grow text-light" role="status"></div>
                </div>
              ) : (
                <>
                  {console.log(getAllPlans, "getAllPlans")}
                  {getAllPlans?.length > 0 ? (
                    getAllPlans?.map((val, idx) => {
                      const curentPlan = val?.plans.find(
                        (data) =>
                          data?._id ==
                          subscriptionSelector?.getSubscriptionInfo?.planId?._id
                      );
                      const isCurrentPlanActive =
                        curentPlan &&
                        selectedPlanType?.at(idx)?.planType ===
                          curentPlan.planType;

                      const PlanArticleInfo = selectedPlanType?.at(idx);

                      const featuresToShow =
                        isCurrentPlanActive && isAuthenticated
                          ? subscriptionSelector?.getSubscriptionInfo?.features
                          : selectedPlanType?.at(idx)?.features;

                      const planPriceToShow =
                        isCurrentPlanActive && isAuthenticated
                          ? subscriptionSelector?.getSubscriptionInfo.price
                          : selectedPlanType?.at(idx)?.price

                          const yearlyDiscountToShow =
                        isCurrentPlanActive && isAuthenticated
                          ? subscriptionSelector?.getSubscriptionInfo?.discountedYearlyPrice
                          : selectedPlanType?.at(idx)?.discountedYearlyPrice

                          const maxArtcleToShow =
                        isCurrentPlanActive && isAuthenticated
                          ? subscriptionSelector?.getSubscriptionInfo?.maxArticlesPerMonth
                          : selectedPlanType?.at(idx)?.maxArticlesPerMonth

                          const markupToShow =
                          isCurrentPlanActive && isAuthenticated
                            ? subscriptionSelector?.getSubscriptionInfo?.markUp
                            : selectedPlanType?.at(idx)?.markUp
                          console.log(yearlyDiscountToShow,"yearlyDiscountToShow");
                          
                      return (
                        <div
                          className="col-xl-3 col-md-12 col-lg-4 mt-4"
                          key={idx}
                        >
                            <div className="subscriptionPlan_">
                            <div className="planhead_">
                              {selectedPlanType?.at(idx)?.planType !== "Free" &&
                                selectedPlanType?.at(idx)?.planType !==
                                  "month" && (
                                  <h3 className="subscriptTextHead yearlyDiscount_Txt mb-2">
                                    Save $
                                    {typeof yearlyDiscountToShow=== "string"
                                      ? parseFloat(yearlyDiscountToShow) % 1 === 0
                                        ? parseFloat(yearlyDiscountToShow) // Convert string to number and display as-is if no decimal places
                                        : parseFloat(yearlyDiscountToShow).toFixed(2) // Convert string to number and format to 2 decimal places
                                      : typeof yearlyDiscountToShow === "number"
                                      ? yearlyDiscountToShow % 1 === 0
                                        ? yearlyDiscountToShow // Display as-is if no decimal places
                                        : yearlyDiscountToShow.toFixed(2) // Format to 2 decimal places
                                      : "0.00"}
                                    {/* {
                                      selectedPlanType?.at(idx)
                                        ?.discountedYearlyPrice
                                    } */}
                                  </h3>
                                )}
                              <h3 className="subscriptTextHead mb-2">
                                {selectedPlanType?.at(idx)?.planName}
                              </h3>
                              <h3 className="innerHeadText">
                                {selectedPlanType?.at(idx)?.planType !==
                                  "Free" && (
                                  <>
                                    $
                                    {typeof planPriceToShow === "string"
                                      ? parseFloat(planPriceToShow) % 1 === 0
                                        ? parseFloat(planPriceToShow) // Convert string to number and display as-is if no decimal places
                                        : parseFloat(planPriceToShow).toFixed(2) // Convert string to number and format to 2 decimal places
                                      : typeof planPriceToShow === "number"
                                      ? planPriceToShow % 1 === 0
                                        ? planPriceToShow // Display as-is if no decimal places
                                        : planPriceToShow.toFixed(2) // Format to 2 decimal places
                                      : "0.00"}
                                    /
                                    <span className="subscriptText">
                                      {selectedPlanType?.at(idx)?.planType}
                                    </span>
                                  </>
                                )}
                              </h3>
                              {selectedPlanType?.at(idx)?.planType !==
                                "Free" && (
                                <ul className="planTabs_">
                                  <li
                                    className={
                                      "planttabslist " +
                                      (selectedPlanType?.at(idx)?.planType ===
                                        "month" && "active")
                                    }
                                    onClick={() => {
                                      let data = [...selectedPlanType];
                                      data?.splice(
                                        idx,
                                        1,
                                        val?.plans?.find(
                                          (i) => i?.planType == "month"
                                        )
                                      );
                                      setSelectedPlanType(data);
                                    }}
                                  >
                                    Monthly
                                  </li>
                                  <li
                                    className={
                                      "planttabslist " +
                                      (selectedPlanType?.at(idx)?.planType ===
                                        "year" && "active")
                                    }
                                    onClick={() => {
                                      let data = [...selectedPlanType];
                                      data?.splice(
                                        idx,
                                        1,
                                        val?.plans?.find(
                                          (i) => i?.planType == "year"
                                        )
                                      );
                                      setSelectedPlanType(data);
                                    }}
                                  >
                                    Yearly
                                  </li>
                                </ul>
                              )}
                            </div>

                            <h5 className="subgreyText_">INCLUDES:</h5>
                            <ul className="subscriptionUl_">
                              <li className="subscriptionList_">
                                Maximum Articles{" "}
                                {PlanArticleInfo?.planType !== "Free"
                                  ? `per ${PlanArticleInfo?.planType}`
                                  : ""}{" "}
                                :{" "}
                                {maxArtcleToShow || "----"}
                              </li>
                              {PlanArticleInfo?.planType !== "Free" && (
                                <li className="subscriptionList_">
                                  Markup :{" "}{markupToShow}%
                                </li>
                              )}
                              {featuresToShow?.map((val, idx) => {
                                return (
                                  <li className="subscriptionList_" key={idx}>
                                    {val}
                                  </li>
                                );
                              })}
                            </ul>
                            {selectedPlanType?.at(idx)?.planType !== "Free" &&
                            (!curentPlan ||
                              selectedPlanType?.at(idx)?.planType !==
                                curentPlan.planType) ? (
                              <button
                                className="secondry_btn"
                                type="button"
                                onClick={() => {
                                  setSelectedPlanData(
                                    selectedPlanType?.at(idx)
                                  );
                                  if (isAuthenticated) {
                                    const modalType =
                                      subscriptionSelector?.allCardInfo?.data
                                        ?.length == 0
                                        ? "paymentModal"
                                        : "cardPaymentModal";
                                    handleOpenModal(modalType);
                                  } else {
                                    navigate("/signup", {
                                      state: {
                                        PlanData:
                                          selectedPlanType?.at(idx)?.planType,
                                        flag: "Plan",
                                      },
                                    });
                                  }
                                }}
                              >
                                Start Now
                              </button>
                            ) : isAuthenticated && curentPlan ? (
                              <button
                                className="secondry_btn currentplan_Btn"
                                type="submit"
                                disabled
                              >
                                Current Plan
                              </button>
                            ) : !subscriptionSelector?.getSubscriptionInfo
                                ?.planId?._id && !isAuthenticated ? (
                              <button
                                className="secondry_btn"
                                type="button"
                                onClick={() => {
                                  setSelectedPlanData(
                                    selectedPlanType?.at(idx)
                                  );
                                  if (isAuthenticated) {
                                    const modalType =
                                      subscriptionSelector?.allCardInfo?.data
                                        ?.length == 0
                                        ? "paymentModal"
                                        : "cardPaymentModal";
                                    handleOpenModal(modalType);
                                  } else {
                                    navigate("/signup", {
                                      state: {
                                        PlanData:
                                          selectedPlanType?.at(idx)?.planType,
                                        flag: "Plan",
                                      },
                                    });
                                  }
                                }}
                              >
                                Start Now
                              </button>
                            ) : (
                              selectedPlanType?.at(idx)?.planType === "Free" &&
                              !subscriptionSelector?.getSubscriptionInfo &&
                              isAuthenticated && (
                                <button
                                  className="secondry_btn currentplan_Btn"
                                  type="button"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title="You have already redeemed this plan"
                                  disabled
                                >
                                  Redeemed
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center noFoundText">No Plan Found!</p>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
        <section className="comparePlans_">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-7">
                <h2 className="subheading mb-4">Compare plans</h2>
                <p className="sub_p_text mb-3">
                  Start with what you need, when you need it, with our
                  month-to-month, pay-as-you-go plans.1Contact us for attractive
                  discounts with our annual plans and volume discounts.
                </p>
              </div>
            </div>
            <div className="planTable table-responsive mt-5">
              <table className="table">
                <thead>
                  <tr>
                    <th className="planHead">Plans </th>
                    <th className="planHead">Free</th>
                    <th className="planHead">$500/month</th>
                    <th className="planHead">$1500/month</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={4} className="planMain">
                      <h3 className="planHeading">
                        Platform Core functionality
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Universal sync</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center"></td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Full CRUD capabilities</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Sync policy</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center"></td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">IMAP data retention</h4>
                    </td>
                    <td className="planSubHead text-center"></td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="planMain">
                      <h3 className="planHeading">
                        Authentication and security
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Authentication scopes</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center"></td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Custom authentication</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center"></td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Hosted authentication</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center"></td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">
                        Automatic provider detection
                      </h4>
                    </td>
                    <td className="planSubHead text-center"></td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="planMain">
                      <h3 className="planHeading">Scheduling</h3>
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Availability endpoint</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Unlimited visibility window</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Free/busy status</h4>
                    </td>
                    <td className="planSubHead text-center">
                      <img
                        src={Image.tableTick}
                        alt="tableTick image"
                        className="img-fluid"
                      />
                    </td>
                    <td className="planSubHead text-center"></td>
                    <td className="planSubHead text-center"></td>
                  </tr>
                  <tr>
                    <td className="planSubHead">
                      <h4 className="agreement">Round-robin availability</h4>
                    </td>
                    <td className="planSubHead text-center"></td>
                    <td className="planSubHead text-center"></td>
                    <td className="planSubHead text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {!isAuthenticated && (
          <section className="faq home_Faq subcription_Faq">
            <div className="container text-center">
              <div className="row">
                <div className="col-md-12">
                  <div className="frequently">
                    <h2 className="medium_h">
                      FAQs (Frequently Asked Questions)
                    </h2>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="accordion yloreAccordion mt-5"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is OwnBuzz?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          OwnBuzz is a subscription-based platform that provides
                          PR and marketing agencies with unparalleled access to
                          over 1000 digital publications, 100 TV stations,
                          leading print magazines, and popular podcasts. We
                          offer an automated PR service with guaranteed
                          publication, fast turnaround times, and innovative AI
                          content creation to empower agencies to amplify their
                          clients' brands.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How does the automated PR service work?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Our automated PR service streamlines the submission,
                          tracking, and publication process. Once you upload
                          your article or press release, you can track its
                          progress through real-time approval, submission, and
                          publication stages. Our system ensures a smooth,
                          transparent journey from content creation to
                          publication, with guaranteed placement and no need for
                          traditional pitching.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What types of content can I publish with OwnBuzz?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          OwnBuzz supports the publication of articles and press
                          releases. Our platform offers flexibility in content
                          format, allowing agencies to tailor their message
                          according to their client's brand voice and campaign
                          goals.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Can OwnBuzz help improve my clients' SEO?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Yes, one of the key benefits of publishing through
                          OwnBuzz is the provision of do-follow backlinks from
                          high-authority publications. This significantly boosts
                          your clients' SEO, improving their online visibility
                          and search engine rankings.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What is the turnaround time for publication?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Our basic package offers a fast publishing turnaround,
                          with articles going live within 24 hours. This ensures
                          timely publication of your clients' content, enabling
                          leveraging current trends and news for maximum impact.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          How does the AI content creation feature work?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          OwnBuzz’s proprietary AI software can generate
                          high-quality, human-like articles in seconds. This
                          tool is designed to assist agencies in creating
                          compelling content efficiently, suitable for campaigns
                          that require quick turnaround without compromising on
                          quality. Agencies also have the option to upload
                          custom-written articles.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          Are there any subscription plans?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Yes, OwnBuzz offers scalable subscription plans to
                          cater to the diverse needs of PR and marketing
                          agencies. Whether you're a small agency looking to
                          grow or a large firm managing multiple clients, our
                          plans are designed to scale with your needs.s.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          How do I track the performance of my campaigns?
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Subscribers have access to detailed analytics and
                          reports that provide insights into campaign
                          performance, media reach, and audience engagement.
                          These tools help agencies refine their strategies and
                          demonstrate the value of their campaigns to clients.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          What support does OwnBuzz offer to agencies?
                        </button>
                      </h2>
                      <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          OwnBuzz provides dedicated support and consultation
                          services, including access to PR and marketing
                          experts. Our team is available to assist with campaign
                          strategy and content optimization and answer any
                          questions about using the platform.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          How can I subscribe to OwnBuzz?
                        </button>
                      </h2>
                      <div
                        id="collapseTen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Subscribing to OwnBuzz is simple. Visit our website
                          and choose the subscription plan that fits your
                          agency's needs best. If you need help selecting a plan
                          or have any questions, our support team is ready to
                          assist you.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="sub_p_text pt-5 text-left">
                OwnBuzz is committed to empowering PR and marketing agencies to
                maximize their clients' media exposure and campaign success. If
                you have any other questions, feel free to contact our support
                team for more information.
              </p>
            </div>
          </section>
        )}
        {!isAuthenticated && <Footer />}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "cardPaymentModal"
            ? "cardPaymentModal"
            : modalDetail.flag === "paymentModalSuccess"
            ? "paymentSuccess"
            : "paymentModal"
        }
        child={
          modalDetail.flag === "cardPaymentModal" ? (
            <CardPaymentModal
              close={() => handleOnCloseModal()}
              selectedPlanData={selectedPlanData || ""}
              handleOpenModal={(data) => handleOpenModal(data)}
            />
          ) : modalDetail.flag === "paymentModal" ? (
            <PaymentDetail
              close={() => handleOnCloseModal()}
              selectedPlanData={selectedPlanData || ""}
              handleOpenModal={handleOpenModal}
            />
          ) : modalDetail.flag === "paymentModalSuccess" ? (
            <PaymentSuccessModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "cardPaymentModal" ? (
            <>
              <h2 className="modal_Heading">Payment</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={Image.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "paymentModal" ? (
            <>
              <h2 className="modal_Heading">Add Card</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={Image.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "paymentModalSuccess" ? (
            <>
              <p
                onClick={() => handleOnCloseModal("paymentDetail")}
                className="modal_cancel"
              >
                <img src={Image.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Subscriptions;

import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  setUploadImage,
  onErrorStopLoad,
  setGetCategories,
  setUploadArticleImage,
  setCreateArticle,
  setGetAllArticles,
  setGetPublication,
  setArticleCategory,
  setGetAllRegions,
} from "../../slices/web";

const queryString = require("query-string");

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* getAllRegions(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.webApiPath.GET_ALL_REGIONS),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetAllRegions(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* UploadImage(action) {
  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = ApiPath.webApiPath.IMAGE_UPLOAD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUploadImage(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUploadImage({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* GetCategories(action) {
  try {
    const paramToData = { ...action.payload };
    delete paramToData.cb;
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.webApiPath.GET_CATEGORIES),
      { params: paramToData }
    );
    if (resp.status) {
      yield put(setGetCategories(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetCategories({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* GetPublications(action) {
  try {
    const paramToData = { ...action.payload };
    delete paramToData.cb;
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.webApiPath.GET_PUBLICATION),
      { params: paramToData }
    );
    if (resp.status) {
      yield put(setGetPublication(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetPublication({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* UploadArticleImage(action) {
  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = ApiPath.webApiPath.ARTICLE_IMAGE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUploadArticleImage(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUploadArticleImage({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* articleCategory(action) {
  let url = `${ApiPath.webApiPath.ARTICLES_CATEGORIES}`;
  if (action.payload.flag) {
    url = `${url}?flag=${action.payload.flag}`;
  }
  if (action.payload.categoryId) {
    url = `${url}&categoryId=${action.payload.categoryId}`;
  }

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setArticleCategory(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setArticleCategory({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* webSaga() {
  yield all([
    takeLatest("web/uploadImage", UploadImage),
    takeLatest("web/uploadArticleImage", UploadArticleImage),
    takeLatest("web/getCategories", GetCategories),
    takeLatest("web/articleCategory", articleCategory),
    takeLatest("web/articleSubCategory", articleCategory),
    takeLatest("web/getPublication", GetPublications),
    takeLatest("web/getAllRegions", getAllRegions),
  ]);
}

export default webSaga;

import React from "react";
import { deleteDraft } from "../../redux/slices/articles";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const DeleteArticleModal = (props) => {
  const dispatch = useDispatch();

  //API for delete Publication...............
  const deleteDraftData = () => {
    let params = {
      id: props?.deleteId,
    };
    dispatch(
      deleteDraft({
        ...params,
        cb(res) {
          if (res?.data) {
            props?.allArticles();
            props?.close();
            toast.success("Article Deleted successfully!");
          }
        },
      })
    );
  };
  return (
    <>
      <div className="logoutModalSec text-center">
        <button
          type="submit"
          className="signUpBtn my-3"
          onClick={() => {
            deleteDraftData();
          }}
        >
          Yes, Delete
        </button>
      </div>
    </>
  );
};

export default DeleteArticleModal;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import { resetPassword } from "../../redux/slices/auth";
import * as Image from "../../utilities/images";

const ResetPassword = () => {
  const [confirmPassword, setShowConfirmPassword] = useState("false");
  const [showPassword, setShowPassword] = useState("false");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location?.state?.userId;
  const authSelector = useAuthSelector();
  const { loading } = authSelector;
  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // validation of input fields
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!formData.password) {
      formIsValid = false;
      toast.error("Please enter your password.");
    } else if (
      !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
        formData.password
      )
    ) {
      toast.error(
        "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
      );
      return;
    } else if (!formData.confirmpassword) {
      formIsValid = false;
      toast.error("Please enter your confirm password.");
    }
    return formIsValid;
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();



    if (validateForm()) {
      let params = {
        new_password: formData.password,
        confirm_password: formData.confirmpassword,
        user_id: userId,
      };
      dispatch(
        resetPassword({
          ...params,
          cb(ress) {
            if (ress.status === 200) {
              navigate("/login");
            }
          },
        })
      );
    }
  };

  const togglePasswordVisiblity = (data) => {
    if (data == "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!confirmPassword);
    }
  };

  return (
    <>
      <section className="authbgSection authOtherSection">
        <img
          src={Image.authlogo}
          alt="authLogo"
          className="authLogoImg img-fluid pointCursor"
          onClick={() => navigate("/login")}
        />
        <div className="authOuterSec">
          <div className="signupSection authInnerSec">
            <h1 className="authheading"> Reset Password</h1>
            <p className="authPara mt-3">Create your new password here.</p>
            <form
              className="authForm"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="signupForm">
                <div className="row mt-2">
                  <div className="col-lg-12 mt-3">
                    <div className="signupField position-relative">
                      <input
                        type={showPassword ? "password" : "text"}
                        className="form-control nameControl "
                        placeholder="Enter New Password"
                        name="password"
                        value={formData.password}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <img
                        src={showPassword ? Image.hiddenEye : Image.showEye}
                        alt="showEyeImage"
                        className="img-fluid eyeIcon"
                        onClick={() => togglePasswordVisiblity("password")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField position-relative">
                      <input
                        type={confirmPassword ? "password" : "text"}
                        className="form-control nameControl "
                        placeholder="Enter Confirm Password"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formData.confirmpassword}
                        name="confirmpassword"
                      />
                      <img
                        src={confirmPassword ? Image.hiddenEye : Image.showEye}
                        alt="showEyeImage"
                        className="img-fluid eyeIcon"
                        onClick={() => togglePasswordVisiblity("confirm")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-5">
                    <button type="submit" className="signUpBtn w-100">
                      Reset
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-1"></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;

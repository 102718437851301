import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useHomePageSelector } from "../../redux/selector/homePage";
import {
  onErrorStopLoad,
  sheduleDemoFormApi,
} from "../../redux/slices/homePage";
import CustomModal from "../Modal/CustomModal";
import ScheduleDemoModal from "../Modal/scheduleDemoModal";
import WrongScheduleModal from "../Modal/wrongScheduleModal";

const SheduleDemoForm = (props) => {
  const toastId = useRef(null);
  const dateValue = moment(props?.date).format("DD/MMM/YYYY");
  const homeSelector = useHomePageSelector();
  const { loading } = homeSelector;
  const dispatch = useDispatch();
  const [phone_no, setPhone_no] = useState("");
  const [dial_code, setDial_code] = useState("");
  const [isTermConditionChecked, setIsTermConditionChecked] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    jobTitle: "",
    websiteUrl: "",
    aboutUs: "",
  });
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  //onchange input
  const handleChange = (e, fieldname) => {
    const { value, name } = e.target;
    let filteredValue = value;

    // Check if the field is the first name or last name
    if (
      fieldname === "firstName" ||
      fieldname === "lastName" ||
      fieldname === "company"
    ) {
      // Filter out non-alphanumeric characters
      filteredValue = value?.replace(/[^a-zA-Z\s]/g, "");
    }
    setFormData({ ...formData, [name]: filteredValue });
    if (fieldname === "termCondition") {
      setIsTermConditionChecked(!isTermConditionChecked);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidUrl = (url) => {
      // Regular expression for validating URLs
      const urlRegex =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
      return urlRegex.test(url);
    };
    if (!formData.firstName || formData.firstName.trim() === "") {
      showToast("Please enter first name");
      return;
    } else if (!formData.lastName) {
      showToast("Please enter last name");
      return;
    } else if (!formData.company) {
      showToast("Please enter company name");
      return;
    } else if (!formData.jobTitle) {
      showToast("Please enter job title");
      return;
    } else if (!formData.email) {
      showToast("Please enter email address");
      return;
    } else if (
      formData.email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formData.email
      )
    ) {
      showToast("Please enter valid email address");
      return;
    } else if (phone_no === undefined) {
      showToast("Please enter phone number");
      return;
    } else if (phone_no.length < 11) {
      showToast("Please enter valid phone number");
      return;
    } else if (!formData.websiteUrl) {
      showToast("Please enter website Url");
      return;
    } else if (!formData.aboutUs) {
      showToast("Please enter about Us");
      return;
    } else if (!isTermConditionChecked) {
      showToast("Please agree to our terms and conditions to proceed further.");
      return;
    } else if (!isValidUrl(formData.websiteUrl)) {
      showToast("Please enter a valid website URL");
      return;
    }
    let params = {
      submissionType: "demo",
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email.trim(),
      dialCode: dial_code,
      phoneNo: phone_no,
      companyName: formData.company,
      jobTitle: formData.jobTitle,
      hearFrom: formData.aboutUs,
      websiteurl: formData.websiteUrl,
      Consent: "true",
      date: dateValue,
      time: props?.time || undefined,
    };
    try {
      dispatch(
        sheduleDemoFormApi({
          ...params,
          cb(res) {
            if (res.status == 200) {
              handleOpenModal("scheduleModal");
            } else {
              handleOpenModal("scheduleWrong");
              dispatch(onErrorStopLoad());
            }
          },
        })
      );
    } catch (eror) {}
  };

  const handleOnChange = (value, data) => {
    setPhone_no(value);
    setDial_code(data.dialCode);
  };
  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <section className="scheduleForm">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="timeSelector">
              <section className="">
                <div className="authOuterSec p-0">
                  <div className="">
                    <form
                      className="authForm"
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <div className="signupForm">
                        <div className="row">
                          <div className="col-lg-6 mb-3 ps-0 pe-0">
                            <div className="signupField">
                              <label for="firstName" className="input_Label">
                                First Name
                              </label>
                              <input
                                type="Text"
                                className="form-control nameControl"
                                placeholder="First Name"
                                onChange={(e) => handleChange(e, "firstName")}
                                maxLength={20}
                                value={formData.firstName}
                                name="firstName"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3 pe-0 signupField_LastName">
                            <div className="signupField">
                              <label for="firstName" className="input_Label">
                              Last Name
                              </label>
                              <input
                                type="Text"
                                className="form-control nameControl"
                                placeholder="Last Name"
                                onChange={(e) => handleChange(e, "lastName")}
                                value={formData.lastName}
                                name="lastName"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3 p-0">
                            <div className="signupField">
                              <label for="firstName" className="input_Label">
                              Company
                              </label>
                              <input
                                type="text"
                                className="form-control nameControl"
                                placeholder="Company"
                                onChange={(e) => handleChange(e, "company")}
                                value={formData.company}
                                name="company"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3 p-0">
                            <div className="signupField">
                              <label for="firstName" className="input_Label">
                              Job Title
                              </label>
                              <input
                                type="Text"
                                autoComplete="disable"
                                name="jobTitle"
                                className="form-control nameControl"
                                placeholder="Job Title"
                                onChange={(e) => handleChange(e)}
                                value={formData.jobTitle}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3 p-0">
                            <div className="signupField">
                              <label for="firstName" className="input_Label">
                              Business Email Address
                              </label>
                              <input
                                type="email"
                                autoComplete="disable"
                                name="email"
                                className="form-control nameControl"
                                placeholder="Business Email Address"
                                onChange={(e) => handleChange(e)}
                                value={formData.email}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label for="firstName" className="input_Label">
                            Phone
                            </label>
                            <div className="col-lg-12 mb-3 d-flex justify-content-end p-0">
                              <div className="phoneControl">
                                <PhoneInput
                                  onChange={handleOnChange}
                                  country="us"
                                  className="inputTel"
                                  placeholder="Enter Phone Number"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3 p-0">
                            <div className="signupField ">
                              <label for="firstName" className="input_Label">
                              Website URL
                              </label>
                              <input
                                type="Text"
                                className="form-control nameControl "
                                placeholder="Website Url"
                                onChange={(e) => handleChange(e)}
                                name="websiteUrl"
                                value={formData.websiteUrl}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3 p-0">
                            <div className="signupField ">
                              {/* <label for="firstName" className="input_Label">
                                First Name:
                              </label> */}
                              <input
                                type="text"
                                className="form-control nameControl "
                                placeholder="How Did You Hear About Us?"
                                onChange={(e) => handleChange(e)}
                                name="aboutUs"
                                value={formData.aboutUs}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3 ">
                            <div className="form-group checkbx">
                              <input
                                type="checkbox"
                                id="javascript"
                                onChange={(e) => {
                                  handleChange(e, "termCondition");
                                }}
                              />
                              <label for="javascript">
                                I have read and accepted the{" "}
                                <Link
                                  to="/termsConditions"
                                  className="linkText"
                                >
                                  {" "}
                                  Terms of Use
                                </Link>{" "}
                                and{" "}
                                <Link to="/privacy" className="linkText">
                                  {" "}
                                  Privacy Policy.
                                </Link>
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3 p-0">
                            <button
                              type="submit"
                              className="signUpBtn"
                              disabled={loading}
                            >
                              Schedule Demo
                              {loading && (
                                <span className="spinner-border spinner-border-sm ms-2"></span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "scheduleModal"
            ? "scheduleModal"
            : modalDetail.flag === "scheduleWrong"
            ? "scheduleModal"
            : ""
        }
        child={
          modalDetail.flag === "scheduleModal" ? (
            <ScheduleDemoModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "scheduleWrong" ? (
            <WrongScheduleModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={modalDetail.flag === "scheduleModal" ? <></> : ""}
        onCloseModal={() => handleOnCloseModal()}
      />
    </section>
  );
};

export default SheduleDemoForm;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSubscriptionSelector } from "../../redux/selector/subscription";
import {
  createSubscription,
  getAllPaymentCards,
} from "../../redux/slices/subscription";
import * as Image from "../../utilities/images";
import DeleteCardModal from "./deleteCardModal";
import CustomModal from "./CustomModal";
import { useArticleSelector } from "../../redux/selector/article";

const CardPaymentModal = (props) => {
  console.log(props?.paymentFlag,"props?.paymentFlag");
  
  const subscriptionSelector = useSubscriptionSelector();
  const articleSelector = useArticleSelector();
  const { loading } = subscriptionSelector;
  const dispatch = useDispatch();
  const [allCardsInfo, setAllCardsInfo] = useState("");
  const [selectedCardId, setSelectedCardId] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  // function for Add card through callback
  const addCardHandle = (flags) => {
    if (allCardsInfo?.length >= 5) {
      toast.error("Max Card Limit Reached");
      return;
    }
    setIsLoading(flags);
    props?.handleOpenModal({ flags: flags, allCardsInfo: allCardsInfo });
  };

  // API for get all card list
  const getAllCardInfo = () => {
    setIsLoading("allCard");
    dispatch(
      getAllPaymentCards({
        cb(res) {
          if (res.status === 200) {
            setAllCardsInfo(res?.data?.data?.data);
            if (res?.data?.data?.data.length === 0) {
              props?.handleOpenModal("paymentModal");
            }
          }
        },
      })
    );
  };

  // function for use article payment and subscription buy

  const paymentHandle = (flag) => {
    if (props?.paymentFlag === "articlePayment") {
      if (!selectedCardId) {
        toast.error("Please select card");
        return;
      }
      props?.paymentToken({
        articleFlag: "addArticle",
        cardId: selectedCardId,
      });
    } else {
      if (!selectedCardId) {
        toast.error("Please select card");
        return;
      }
      let params = {
        cardId: selectedCardId,
        planId: props?.selectedPlanData?._id,
      };
      setIsLoading(flag);
      dispatch(
        createSubscription({
          ...params,
          cb(ress) {
            if (ress.status === 200) {
              props?.handleOpenModal("paymentModalSuccess");
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    getAllCardInfo();
  }, []);

  return (
    <>
      {loading && isLoading == "allCard" ? (
        <div className="loaderOuter d-flex justify-content-center text-light">
          <div className="spinner-grow text-light" role="status"></div>
        </div>
      ) : (
        <>
          {allCardsInfo?.length > 0
            ? allCardsInfo?.map((val, index) => (
                <>
                  <div
                    className={`card_Payment  ${
                      selectedCardId === val?.id ? "active" : ""
                    }`}
                    onClick={() => setSelectedCardId(val?.id)}
                    key={val?.id}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="card_Brand">{val?.card?.brand}</h2>
                      {/* <img src={Image.cardPaymentLogo} alt="cardlogo" /> */}
                      <h3 className="card_date">
                        {val?.card?.exp_month}/{val?.card?.exp_year}
                      </h3>
                    </div>
                    <div className="cardNo_Parent">
                      <h3 className="cardNo d-flex">
                        <span className="cardNo_Hidden">**** **** ****</span>
                        <span>{val?.card?.last4}</span>
                      </h3>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="card_Name"></p>
                        <img
                          src={Image.deleteLogo}
                          alt="deleteLogo"
                          className="delete_Logo"
                          onClick={() => handleOpenModal("deleteCardModal")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))
            : ""}
        </>
      )}

      <div className="text-center d-flex align-items-center justify-content-between">
        <button
          className="addNewCard_Btn mb-3"
          onClick={() => addCardHandle("paymentModal")}
        >
          Add New Card
          {loading && isLoading === "paymentModal" && (
            <span className="spinner-border spinner-border-sm ms-2"></span>
          )}
        </button>
        <button
          className="addNewCard_Btn mb-3 ms-2 pay_Btn"
          onClick={(e) => paymentHandle("buySub")}
        >
          {props?.paymentFlag === "articlePayment"
            ? `Pay $${props?.totalAmount}`
            : `Pay $${props?.selectedPlanData?.price}`}

          {(loading && ((isLoading === "buySub")) || (articleSelector.loading &&(props?.paymentFlag ==="articlePayment"))) && (
            <span className="spinner-border spinner-border-sm ms-2"></span>
          )}
        </button>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "deleteCardModal" ? "deleteCardModal" : ""}
        child={
          modalDetail.flag === "deleteCardModal" ? (
            <DeleteCardModal
              close={() => handleOnCloseModal()}
              selectedCardId={selectedCardId}
              getAllCardInfo={getAllCardInfo}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "deleteCardModal" ? (
            <>
              <h2 className="modal_Heading text-center">
                Are You Sure You Want <br /> To Delete Card
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={Image.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default CardPaymentModal;

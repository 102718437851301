import React, { useEffect, useState } from "react";
import * as images from "../../../utilities/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import { getAllArticles } from "../../../redux/slices/articles";
import { useArticleSelector } from "../../../redux/selector/article";
import CustomModal from "../../Modal/CustomModal";
import DeleteArticleModal from "../../Modal/deleteArticleModal";
import { getPublication } from "../../../redux/slices/web";


const Draft = () => {
  document.title = "Draft";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [articleData, setArticleData] = useState([]);
  const [draftId, setDraftid] = useState("");
  const articleSelector = useArticleSelector();
  const { loading } = articleSelector;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [status, setStatus] = useState(state?.flag || "draft");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [getPublicateInfo, setGetPublicateInfo] = useState([]);
  console.log(articleData,"getPublicateInfo");
  

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  /**
   * to get all article list
   */
  const allArticles = () => {
    let param = {
      status: status,
      limit: 10,
      page: page,
    };
    dispatch(
      getAllArticles({
        ...param,
        cb(res) {
          if (res.status) {
            setArticleData(res?.data?.data?.data);
            setPageCount(res?.data?.data?.total_pages);
          } else {
          }
        },
      })
    );
  };

  const GetAllPublication = () => {
    dispatch(
      getPublication({
        cb(res) {
          if (res.status) {
            setGetPublicateInfo(res?.data?.data?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    GetAllPublication();
  }, []);

  useEffect(() => {
    allArticles();
  }, [page, status]);

  return (
    <>
      <div className="content-wrapper  draftSection">
        <h2 className="innerHeadText my-3">Draft</h2>
        <div className="recentUserSec">
          <div className="userListStatus">
            <h4
              className={
                status === "draft" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("draft")}
            >
              Draft
            </h4>
            <h4
              className={
                status === "schedule" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("schedule")}
            >
              Schedule
            </h4>
          </div>
          <div className="table-responsive recentTable drafTable">
            <table className="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th className="recentHead">ARTICLE</th>
                  <th className="recentHead">TITLE</th>
                  <th className="recentHead">DATE</th>
                  <th className="recentHead ">ACTION</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {loading ? (
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {articleData?.length > 0 ? (
                      articleData?.map((val, idx) => {
                        console.log(val,"valvalval");
                        
                        let serialNumber = (page - 1) * 10 + idx + 1;
                        return (
                          <tr key={idx}>
                            <td className="recentSubHead align-middle" >
                              <h6 className="tableSubText">{serialNumber}</h6>
                            </td>
                            <td className="recentSubHead align-middle pointCursor"
                            onClick={() => {
                              navigate("/draftDetail", {
                                state: {
                                  type: "view",
                                  draftData: val,
                                },
                              });
                            }}>
                              <h6 className="tableSubText">{val?.title}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                                {moment(val?.createdAt).format("MM/DD/YYYY")}
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <div className="categoryOuterBtn">
                                <button
                                  className="viewBtn"
                                  type="button"
                                  onClick={() => {
                                    navigate("/draftDetail", {
                                      state: {
                                        type: "view",
                                        draftData: val,
                                      },
                                    });
                                  }}
                                >
                                  <img
                                    src={images.viewIcon}
                                    alt="viewIcon image"
                                    className="img-fluid"
                                  />
                                  view
                                </button>
                                <button
                                  className="editBtn_"
                                  type="button"
                                  onClick={() => {
                                    const nextState =
                                    status === "draft" ? "draft" : "schedule";
                                    {val?.articleType ==="ai"?
                                    navigate("/articlesCreation", {
                                      state: {
                                        type: nextState,
                                        draftData: {
                                          ...val,
                                          publications: val.publications
                                            ?.filter((i) => {
                                              return getPublicateInfo?.some(
                                                (apiItem) =>
                                                  apiItem._id ===
                                                  i.publicationId
                                              );
                                            })
                                            .map((publication) => ({
                                              _id: publication.publicationId,
                                              value:
                                                publication.publicationName,
                                              publicationLogo:
                                                publication.publicationLogo,
                                              publicationAmount:
                                                publication.publicationPrice,
                                                needInterviewerAssistant:publication.needInterviewerAssistant,
                                                needProfessionalAssistant:publication.needProfessionalAssistant

                                            })),
                                        },
                                      },
                                    }):

                                    
                                      

                                    navigate("/write-article", {
                                      state: {
                                        type: nextState,
                                        draftData: {
                                          ...val,
                                          publications: val.publications
                                            ?.filter((i) => {
                                              return getPublicateInfo?.some(
                                                (apiItem) =>
                                                  apiItem._id ===
                                                  i.publicationId
                                              );
                                            })
                                            .map((publication) => ({
                                              _id: publication.publicationId,
                                              value:
                                                publication.publicationName,
                                              publicationLogo:
                                                publication.publicationLogo,
                                              publicationAmount:
                                                publication.publicationPrice,
                                                needInterviewerAssistant:publication.needInterviewerAssistant,
                                                needProfessionalAssistant:publication.needProfessionalAssistant
                                            })),
                                        },
                                      },
                                    })}
                                  }}
                                >
                                  <img
                                    src={images.editIcon}
                                    className="img-fluid "
                                    alt="editPen image"
                                  />
                                  Edit
                                </button>
                                <button
                                  className="delete_btn"
                                  type="button"
                                  onClick={() => {
                                    handleOpenModal(
                                      "deleteModal",
                                      setDraftid(val?._id)
                                    );
                                  }}
                                >
                                  <img
                                    src={images.DeleteIcon}
                                    className="img-fluid "
                                    alt="eyeImg"
                                  />
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                      <td colSpan={8} className="noFoundHead">
                        <p className="text-center noFoundText">
                          {" "}
                          No Articles Found!
                        </p>
                      </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {articleData?.length > 0 && (
          <div className=" paginateSec">
            <ReactPaginate
              className="paginationBox mb-0"
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "deleteModal" ? "deletCategoryModal" : ""}
        child={
          modalDetail.flag === "deleteModal" ? (
            <DeleteArticleModal
              close={() => handleOnCloseModal()}
              deleteId={draftId}
              allArticles={() => allArticles()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "deleteModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to Delete?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Draft;

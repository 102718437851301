import moment from "moment/moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as Images from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import UnderreviewModal from "./UnderreviewModal";

const ScheduleModal = (props) => {
  // Get the current time
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();

  // Format the current time as a string with leading zeros if needed
  const formattedCurrentTime = `${currentHour
    .toString()
    .padStart(2, "0")}:${currentMinute.toString().padStart(2, "0")}`;

  const [key, setKey] = useState(Math.random());
  const [selectedDate, setSelectedDate] = useState(
    props?.dateValue ? new Date(props.dateValue) : new Date()
  );
  console.log(selectedDate, "selectedDate");

  const [selectTime, setSelectTime] = useState(
    props?.timeValue ? moment(props?.timeValue, "HH:mm") : null
  );

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  function handleGetTimeValue(value) {
    setSelectTime(value);
  }

  const formattedDate = selectedDate
    ? `${selectedDate.getFullYear()}-${
        selectedDate.getMonth() + 1
      }-${selectedDate.getDate()}`
    : "";

  const handleScheduleArticle = () => {
    if (!selectedDate) {
      toast.error("Please select date");
      return false;
    } else if (!selectTime) {
      toast.error("Please select time");
      return false;
    }
    props?.createNewArticle("2", selectTime, formattedDate);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div className="schedulecontent_">
        <div
          className="row
        "
        >
          <div className="col-md-12 mb-3">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              minDate={new Date()}
              className="customInput"
              placeholderText={selectedDate ? selectedDate : "Select Date"}
              dateFormat="MM/dd/yyyy" // Corrected the format string
            />
          </div>
          <div className="col-md-12 mb-3">
            <div className="publishInput mt-3">
              {/* <input
                type="time"
                className="form-control nameControl publishTime input"
                name="tIME"
                placeholder="Select Time"
                value={""}
                onChange={(e) => {
                  setSelectTime(e.target.value);
                }}
              /> */}
              <TimePicker
                showSecond={false}
                className="timepicker-outer schedule_Time"
                onChange={handleGetTimeValue}
                value={selectTime}
                placeholder="Select time"
                format="HH:mm"
                inputReadOnly
                // clearIcon={
                //   <img
                //     alt="clock-icon"
                //     className="clock-icon"
                //     src={Images.modalCross}
                //   />
                // }
                clearIcon=""
                inputIcon={
                  <img
                    alt="clock-icon"
                    className="clock-icon"
                    src={Images.clockImg}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className="modalfooterbtn deleteBtnModal mt-4">
          <button
            className="signUpBtn"
            onClick={() => {
              handleScheduleArticle("underreviewModal");
            }}
          >
            Confirm
          </button>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        ids={modalDetail.flag === "underreviewModal" ? "UnderreviewModal" : ""}
        child={
          modalDetail.flag === "underreviewModal" ? (
            <UnderreviewModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ScheduleModal;

import React from "react";
import { useDispatch } from "react-redux";
import { deleteSubscription } from "../../redux/slices/subscription";
import { useSubscriptionSelector } from "../../redux/selector/subscription";

const DeleteSubscriptionModal = (props) => {
  const subscriptionSelector = useSubscriptionSelector();
  const { loading } = subscriptionSelector;
  const dispatch = useDispatch();

  //API for delete Publication...............
  const deleteSubscriptionPlans = () => {
    dispatch(
      deleteSubscription({
        cb(res) {
          if (res?.data) {
            props?.getCurrentSubscriptionInfo();
            props?.close();
          }
        },
      })
    );
  };
  
  return (
    <>
      <div className="logoutModalSec text-center">
        <button
          type="submit"
          className="signUpBtn my-3"
          onClick={() => {
            deleteSubscriptionPlans();
          }}
        >
          Yes
          {loading && (
            <span className="spinner-border spinner-border-sm ms-2"></span>
          )}
        </button>
      </div>
    </>
  );
};

export default DeleteSubscriptionModal;

import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
  article_image_loader: false,
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    getAllTickets: (state) => {
      state.loading = true;
    },
    setGetAllTicket: (state) => {
      state.loading = false;
    },
    createTicket: (state) => {
      state.loading = true;
    },
    setCreateTicket: (state) => {
      state.loading = false;
    },
    getOneTickets: (state) => {
      state.loading = true;
    },
    setGetOneTicket: (state) => {
      state.loading = false;
    },
    setUpdateOneTicket: (state) => {
      state.loading = true;
    },
    updateOneTickets: (state) => {
      state.loading = false;
    },
    ticketUploadDocument: (state) => {
      state.loading = true;
    },
    setTicketUploadDocument: (state) => {
      state.loading = false;
    },
    
    onErrorStopLoad: (state) => {
      state.loading = false;
      state.article_image_loader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getAllTickets, setGetAllTicket, createTicket,setCreateTicket,getOneTickets,setGetOneTicket,updateOneTickets,setUpdateOneTicket,ticketUploadDocument,setTicketUploadDocument,onErrorStopLoad } =
  ticketSlice.actions;

export default ticketSlice.reducer;

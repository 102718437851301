import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
  article_image_loader: false,
  articleInfo: {},
  getAllArticles: {}
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    createArticle: (state) => {
      state.loading = true;
    },
    setCreateArticle: (state, action) => {
      state.loading = false;
      state.articleInfo = action.payload
    },
    getAllArticles: (state) => {
      state.loading = true;
    },
    setGetAllArticles: (state, action) => {
      state.loading = false;
      state.getAllArticles = action.payload
    },
    deleteDraft: (state) => {
      state.loading = true;
    },
    setDeleteDraft: (state, action) => {
      state.loading = false;
    },
    updateArticleDraft: (state) => {
      state.loading = true;
    },
    setUpdateArticleDraft: (state, action) => {
      state.loading = false;
      state.articleInfo = action.payload
    },
    getArticleInfo: (state) => {
      state.loading = true;
    },
    setGetArticleInfo: (state, action) => {
      state.loading = false;
    },
    
    onErrorStopLoad: (state) => {
      state.loading = false;
      state.article_image_loader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  createArticle,
  setCreateArticle,
  getAllArticles,
  setGetAllArticles,
  updateArticleDraft,
  setUpdateArticleDraft,
  deleteDraft,
  setDeleteDraft,
  getArticleInfo,
  setGetArticleInfo,
  onErrorStopLoad,
} = articleSlice.actions;

export default articleSlice.reducer;

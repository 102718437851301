import React from "react";

const PublicationGenreModal = ({ allCategoryNames }) => {
  return (
    <div class="categoryOuterBox">
      {allCategoryNames?.map((value) => (
        <div className="categoryBoxModal">{value?.categoryName}</div>
      ))}
    </div>
  );
};

export default PublicationGenreModal;

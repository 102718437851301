import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux";
import { persistor } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-phone-input-2/lib/style.css";
import "./public/css/custom.css";
import "./public/css/customNew.css";
import "./public/css/landingPage.css";
import "./public/css/responsive.css";
import "../src/public/css/custom_R.css";
import "react-calendar/dist/Calendar.css";
import { VAPID_KEY, messaging } from "./config/firebase-config";
import { getToken, onMessage } from "firebase/messaging";
import * as Image from "./utilities/images";
import { getNotification } from "./redux/slices/notification";

function App() {
  // Get FCM token , Generate Token
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });
      console.log(token,"token");
      

      localStorage.setItem("fcmToken", token);
      onMessage(messaging, (payload) => {
        const { title, body, icon } = payload.notification;
        new Notification(title, {
          body,
          icon,
        });
        fetchNotification();
      });

    }
  }
  const fetchNotification = () => {
    store.dispatch(
      getNotification({
        cb(res) {
          if (res?.status === 200) {
          }
        },
      })
    );
  };

  // Req user for notification permission
  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
      </Provider>
      <ToastContainer theme="dark" />
    </>
  );
}

export default App;

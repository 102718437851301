import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { CKEditor } from "ckeditor4-react";
import mammoth from "mammoth";
import { getDocument } from "pdfjs-dist/legacy/build/pdf";
import "pdfjs-dist/legacy/build/pdf.worker.entry";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../../public/css/ckEditor.css";
import { useWebSelector } from "../../../../redux/selector/web";
import { createArticle } from "../../../../redux/slices/articles";
import {
  articleCategory,
  articleSubCategory,
  uploadArticleImage,
} from "../../../../redux/slices/web";
import * as Images from "../../../../utilities/images";
import CustomModal from "../../../Modal/CustomModal";
import { useConvertMarkDownHtml } from "../../../../redux/customHooks/useConvertMarkDownHtml";
import AddUrlModal from "../../../Modal/AddUrlModal";

// Forward ref wrapper for CKEditor
const CKEditorWithRef = React.forwardRef((props, ref) => (
  <CKEditor {...props} ref={ref} />
));

const WriteArticle = (props) => {
  const toastId = useRef(null);
  const editorRef = useRef();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const draftData = state?.draftData;
  console.log(state, "draftDatanaresh");

  const webSelector = useWebSelector();
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [photoLoader, setPhotoUploadLoader] = useState(false);
  const [multiplePhotoLoader, setMultiplePhotoLoader] = useState(false);
  const [publicationIds, setPublicationIds] = useState(
    state?.selectedPublicationIds ||
      state?.draftData?.publications ||
      state?.publicationIds ||
      []
  );

  const [googleDriveUrl, setGoogleDriveUrl] = useState(
    state?.googleDriveUrl ? state?.googleDriveUrl : draftData?.googleDriveUrl
  );

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  console.log(modalDetail, "");

  const [categorydata, setCategoryData] = useState(
    state?.categorydata ? state?.categorydata : draftData?.categoryId
  );
  const [subCategoryData, setSubCategoryData] = useState(
    state?.subCategoryData ? state?.subCategoryData : draftData?.subCategoryId
  );
  const [title, setTitle] = useState(
    state?.title ? state?.title : draftData?.title
  );
  const [articleUrl, setArticleUrl] = useState(
    state?.url || draftData?.url || state?.articleUrl
  );
  const [bannerImgUpload, setBannerImgUpload] = useState(
    draftData?.photos || state?.photo || state?.bannerImgUpload || ""
  );

  const [articleMultiImgUpload, setArticleMultiImgUpload] = useState(
    state?.multipleImages || draftData?.multipleImages || []
  );
  console.log(articleMultiImgUpload, "articleMultiImgUpload");

  const [creditImgUpload, setCreditImgUpload] = useState(
    state?.draftData?.creditImage || state?.creditImgUpload || ""
  );

  const [ckEditorContent, setCkEditorContent] = useState("");
  const htmlConetent = useConvertMarkDownHtml(
    state?.contentData || draftData?.description || state?.ckEditorContent
  );

  const [isDivVisible, setIsDivVisible] = useState(false);

  const [formData, setFormData] = useState({
    publicationIds: "",
    categorydata: "",
    creditImgUpload: "",
    subCategoryData: "",
    title: "",
    articleUrl: "",
    bannerImgUpload: "",
    ckEditorContent: "",
    googleDriveUrl: "",
    multipleImages: "",
  });
  const [selectedFilters, setSelectedFilters] = useState({
    googleDriveLink:
      draftData?.googleDriveUrl || state?.googleDriveUrl ? "yes" : "no",
  });
  console.log(selectedFilters, "selectedFilters");

  const handleFilesUpload = (files) => {
    console.log(files, "fie");

    const uploadedFiles = Array.from(files).map((file) =>
      URL?.createObjectURL(file)
    );
    console.log(uploadedFiles, "fie upl");
    setArticleMultiImgUpload((prevState) => [...prevState, ...uploadedFiles]);
  };

  const removeMultipleFiles = (fileToRemove) => {
    setArticleMultiImgUpload((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  useEffect(() => {
    if (htmlConetent) {
      setCkEditorContent(htmlConetent);
    }
  }, [htmlConetent]);

  useEffect(() => {
    const stopLoader = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => {
      clearTimeout(stopLoader);
    };
  }, []);

  // const handleArticleAddUrl = (url) => {
  //   setArticleAddUrl(url);
  // };

  /**
   *
   * @param {*} idx
   * @param {*} event
   */

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const openPublicationSection = () => {
    setIsDivVisible(!isDivVisible);
  };

  //API for get all category...............
  const getAllCategories = () => {
    dispatch(
      articleCategory({
        cb(res) {
          if (res?.data) {
            setCategory(res?.data?.data?.data);
            if (categorydata?._id ? categorydata?._id : categorydata) {
              handleCategoryChange(
                categorydata?._id ? categorydata?._id : categorydata,
                res?.data?.data?.data
              );
            }
          }
        },
      })
    );
  };

  // Reusable onDrop handler
  const handleDrop = (setPhoto) => (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      showToast("Please choose a file");
      return;
    }

    const allowedTypes = ["jpg", "jpeg", "png", "svg+xml"];
    const fileType = file.type.split("/")[1];
    console.log(fileType, "fileType");

    if (!allowedTypes.includes(fileType)) {
      showToast("Please choose only supported file types (JPG, PNG, SVG)");
      return;
    }
    setPhotoUploadLoader(true);
    dispatch(
      uploadArticleImage({
        images: file,
        cb(res) {
          setPhotoUploadLoader(false);
          if (res.status) {
            setPhoto(res.data.data?.[0]);
          }
        },
      })
    );
  };

  // Reusable onDrop handler for multiple files
  const handleMultipleFileDrop = (acceptedFiles) => {
    console.log("fie 241");
    if (acceptedFiles.length === 0) {
      showToast("Please choose a file");
      setMultiplePhotoLoader(false);
      return;
    }

    const allowedTypes = ["jpg", "jpeg", "png", "svg+xml"];
    const unsupportedFiles = acceptedFiles.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });

    if (unsupportedFiles.length > 0) {
      showToast("Please choose only supported file types (JPG, PNG, SVG)");
      setMultiplePhotoLoader(false);
      return;
    }
    setMultiplePhotoLoader(true);

    dispatch(
      uploadArticleImage({
        multiImages: acceptedFiles,
        cb(res) {
          setMultiplePhotoLoader(false);
          if (res.status) {
            setArticleMultiImgUpload((prevState) => [
              ...prevState,
              ...res.data.data,
            ]);
          }
        },
      })
    );
  };
  const handleOptionClick = (option) => {
    setSelectedFilters({ googleDriveLink: option });
  };

  // useDropzone hooks with reusable handlers
  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: handleDrop(setBannerImgUpload),
      multiple: false,
    });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: handleMultipleFileDrop,
      multiple: true,
    });

  const removeFile = (setPhoto) => {
    setPhoto("");
  };
  // FUNCTION FOR CHNAGE CATEGORY AND UPDATE CATEGORY
  const handleCategoryChange = (id, data) => {
    // Handle empty category selection
    if (id === "") {
      setCategoryData("");
    } else {
      const selectedCategory = data?.find((cat) => cat._id === id);
      if (selectedCategory) {
        setCategoryData(selectedCategory);
        // setPublicationIds([])
      }
    }

    let params = {
      categoryId: id,
      flag: "dropdownsubcate",
    };
    dispatch(
      articleSubCategory({
        ...params,
        cb(res) {
          if (res?.data) {
            setSubCategory(res?.data?.data?.data);
            if (subCategoryData?._id ? subCategoryData?._id : subCategoryData) {
              handleSubCategoryChange(
                subCategoryData?._id ? subCategoryData?._id : subCategoryData,
                res?.data?.data?.data
              );
            }
          }
        },
      })
    );
  };

  // FUNCTION FOR CHNAGE SUB CATEGORY AND UPDATE SUB  CATEGORY
  const handleSubCategoryChange = (id, data) => {
    // Handle empty subcategory selection
    if (id === "") {
      setSubCategoryData("");
    } else {
      const selectedSubCategory = data.find((subCat) => subCat._id === id);
      if (selectedSubCategory) {
        setSubCategoryData(selectedSubCategory);
      }
    }
  };

  /**
   *
   * @param {*} e
   * @returns to navigate or save article as draft
   */

  const veiwArticleDetails = () => {
    const urlTest = new RegExp(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?/gm
    );

    // if (!formData?.categorydata) {
    //   showToast("Please select Genre");
    //   return;
    // } else if (!formData?.subCategoryData) {
    //   showToast("Please select Type");
    //   return;
    // } else

    if (!publicationIds?.length) {
      showToast("Please select publication");
      return;
    } else if (!formData?.title) {
      showToast("Please select title");
      return;
    } else if (!formData?.articleUrl) {
      showToast("Please enter URL");
      return;
    } else if (!formData?.articleUrl && !urlTest.test(formData?.articleUrl)) {
      showToast("Please enter valid URL");
      return;
    } else if (!formData?.bannerImgUpload.length) {
      showToast("Please select feature image");
      return;
    } else if (!formData?.ckEditorContent && !googleDriveUrl) {
      showToast("Please enter article description");
      return;
    } else if (googleDriveUrl && !urlTest.test(googleDriveUrl)) {
      showToast("Please enter valid URL");
      return;
    }

    if (
      state?.type === "draft" ||
      state?.type === "schedule" ||
      state?.type === "rejected" ||
      state?.type === "draftupdate"
    ) {
      navigate(
        state?.type === "draft" ||
          state?.type === "schedule" ||
          state?.type === "draftupdate" ||
          state?.type === "rejected"
          ? "/draftDetail"
          : "/article-details",
        {
          state: {
            selectedPublicationDetails: formData,
            draftData: draftData || "",
            type:
              state?.type === "draft" || state?.type === "draftupdate"
                ? "draft"
                : state?.type === "rejected"
                ? "rejected"
                : "schedule",
          },
        }
      );
    } else {
      /////////////user for save draft article before submit//////////////////////
      const publicationData =
        publicationIds.map((data) => data._id) || publicationIds;
      let params = {
        articleType: "upload",
        creditImage: formData?.creditImgUpload || undefined,
        url: formData?.articleUrl,
        publicationId: publicationData,
        categoryName: formData?.categorydata?.name,
        categoryId: formData?.categorydata?._id,
        subCategoryName: formData?.subCategoryData?.name,
        subCategoryId: formData?.subCategoryData?._id,
        title: formData?.title || formData?.data?.title,
        description: formData?.ckEditorContent || undefined,
        googleDriveUrl: googleDriveUrl || undefined,
        photos: [formData?.bannerImgUpload],
        multipleImages: formData?.multipleImages || undefined,
        needInterviewerAssistant: publicationIds.map(() => false),
        needProfessionalAssistant: publicationIds.map(() => false),
        saveAsDraft: "1",
        addOn: "0",
      };
      dispatch(
        createArticle({
          ...params,
          cb(res) {
            navigate(
              state?.type === "draft" ||
                state?.type === "schedule" ||
                state?.type === "draftupdate" ||
                state?.type === "rejected"
                ? "/draftDetail"
                : "/article-details",
              {
                state: {
                  selectedPublicationDetails: formData,
                  userId: res?.data?.data,
                  draftData: draftData || "",
                  type:
                    state?.type === "draft" || state?.type === "draftupdate"
                      ? "draft"
                      : state?.type === "rejected"
                      ? "rejected"
                      : "CreateArticle",
                },
              }
            );
          },
        })
      );
    }
  };

  // Update formData whenever any of the individual state variables change
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      publicationIds,
      categorydata,
      subCategoryData,
      title,
      articleUrl,
      bannerImgUpload,
      creditImgUpload,
      ckEditorContent,
      googleDriveUrl,
      multipleImages: articleMultiImgUpload,
    }));
  }, [
    publicationIds,
    categorydata,
    subCategoryData,
    title,
    articleUrl,
    bannerImgUpload,
    creditImgUpload,
    ckEditorContent,
    googleDriveUrl,
    articleMultiImgUpload,
  ]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (
      // file.type === "application/pdf" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      try {
        setLoader(true);
        let html = "";
        if (file.type === "application/pdf") {
          // Extract text from PDF and convert to HTML
          const text = await extractTextFromPDF(file);
          html = textToHtml(text);
        } else if (
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type === "application/msword"
        ) {
          // Convert Word document to HTML
          html = await convertDocxToHtml(file);
        } else {
          console.error("Unsupported file type");
          return;
        }
        setLoader(false);
        setCkEditorContent(html);
        const editorInstance = await editorRef.current.editor;

        if (editorInstance) {
          // Update editor content
          editorInstance.setData(html);
        }
      } catch (error) {
        console.log("Error processing file:", error);
      } finally {
        setLoader(false);
      }
    } else {
      toast.error("Please uplaod docx file format only.");
    }
  };

  const extractTextFromPDF = async (file) => {
    const pdfData = await file.arrayBuffer();
    const pdf = await getDocument({ data: pdfData }).promise;
    let text = "";

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const content = await page.getTextContent();
      text += content.items.map((item) => item.str).join(" ") + "\n";
    }

    return text;
  };

  const textToHtml = (text) => {
    return `<p>${text.replace(/\n/g, "</p><p>")}</p>`;
  };

  const convertDocxToHtml = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });
      return result.value; // result.value contains the HTML
    } catch (error) {
      console.error("Error converting .docx file to HTML:", error);
      throw error; // Rethrow to handle it in the calling function
    }
  };

  return (
    <>
      <div className="content-wrapper writeArticleSec ">
        <div className="content-header">
          <div className="innerHead">
            <img
              src={Images.arrowLink}
              className="img-fluid pointCursor"
              alt="arrowLink image"
              onClick={() => {
                if (state?.type === "draft") {
                  navigate("/drafts");
                } else {
                  navigate("/select-publication", {
                    state,
                  });
                }
              }}
            />
            {draftData ||
            state?.type == "draftupdate" ||
            state?.type == "rejected" ? (
              <h2 className="innerHeadText">Update Article</h2>
            ) : (
              <h2 className="innerHeadText">Write or upload article</h2>
            )}
          </div>

          {/* <form onSubmit={(e) => veiwArticleDetails(e)}> */}
          <div className="row">
            <div className="col-md-12">
              <div className="addPublishLeft">
                <div className="row">
                  {draftData?.articleType === "ai" && (
                    <div className="col-md-12">
                      <div className="searchBox">
                        <label for="Publication" className="input_Label">
                          Genre
                        </label>
                        <FormControl>
                          <Select
                            className="customSelect"
                            value={categorydata?._id || categorydata}
                            onChange={(e) => {
                              handleCategoryChange(e.target.value, category);
                              setPublicationIds([]);
                            }}
                            label="Category"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              fontFamily: "Poppins",
                              ".MuiSvgIcon-root": {
                                color: "#fff", // Change this to the desired color
                              },
                              fontStyle: "normal",
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  borderRadius: "12px",
                                  border: "1px solid #FFFFFF4D",
                                  background: "#616161",
                                  color: "#ffffff",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  marginTop: "5px",
                                  " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                                    {
                                      color: "#ffffff",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      fontFamily: "inherit",
                                      marginTop: "3px",
                                      "&.Mui-selected": {
                                        backgroundColor: "#757575",
                                      },
                                      "&:hover": {
                                        backgroundColor: "#757575",
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: "#757575",
                                      },
                                    },
                                },
                              },
                            }}
                          >
                            <MenuItem
                              value={categorydata?._id || categorydata}
                              disabled
                            >
                              Select Genre
                            </MenuItem>

                            {category.map((category, idx) => (
                              <MenuItem value={category?._id} index={idx}>
                                {category?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}
                </div>
                <div className="searchBox">
                  <label for="Publication" className="input_Label">
                    Publications
                  </label>
                  <div
                    className={
                      isDivVisible
                        ? "publicationSelect  select_Publication_active"
                        : "publicationSelect  select_Publication"
                    }
                    onClick={openPublicationSection}
                  >
                    {publicationIds && publicationIds.length > 0 ? (
                      <>
                        {publicationIds?.map((value, idx) => {
                          return (
                            <div className="selectPublish" key={idx}>
                              <h6 className="ratingText">{value?.value}</h6>
                              {/* <img
                              src={Images.selectCross}
                              alt="selectCross img"
                              className="img-fluid pointCursor"
                              onClick={(event) => handleDeleteItem(idx, event)}
                            /> */}
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <h4 className="authPara article_AuthPara">
                        Select Publications
                      </h4>
                    )}
                    {/* <img
                    src={Images.selectArrow}
                    alt="selectArrow image"
                    className="img-fluid publishArrow"
                  /> */}
                  </div>
                </div>
                {/* {isDivVisible && (
                  <div
                    className={`target-div ${isDivVisible ? "visible" : ""}`}
                  >
                    <SelectPublication
                      handleAddPublication={handleAddPublication}
                      publicationIds={publicationIds}
                      categorydata={categorydata}
                      subCategoryData={subCategoryData}
                    />
                  </div>
                )} */}

                <div className="searchBox">
                  <label for="title" className="input_Label">
                    Title
                  </label>
                  <input
                    className="form-control customInput"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="searchBox">
                  <label for="url" className="input_Label">
                    URL Link
                  </label>
                  <input
                    className="form-control customInput"
                    placeholder="Add URL"
                    value={articleUrl}
                    onChange={(e) => setArticleUrl(e.target.value)}
                  />
                </div>
                <div className="searchBox">
                  <label for="email" className="input_Label">
                    Feature Image
                  </label>
                  <div className="form-group col-md-12 ">
                    <>
                      {bannerImgUpload?.length > 0 ? (
                        ""
                      ) : (
                        <div className="uploadImgeboxNewArticle writeArticle_">
                          {webSelector?.loading || photoLoader ? (
                            <div className="row">
                              <div className="col-sm-12 text-center pt-5 pb-5">
                                <div
                                  className="spinner-grow text-success"
                                  role="status"
                                ></div>
                              </div>
                            </div>
                          ) : (
                            <div {...getRootProps1({ className: "dropzone" })}>
                              <input {...getInputProps1()} />

                              <img
                                src={Images.upload}
                                alt="uploadIcon"
                                className="img-fluid  articleupload_ mb-2"
                              />

                              <p className="uploadbtntext">
                                Drag image here or
                                <span className="ColoredText"> Browse </span>
                              </p>
                              <p className="uploadText mt-2">
                                Supports JPG, PNG, SVG
                              </p>
                              <p className="uploadText mt-2">
                                Upload Image with Resolution Min 1200 x 600
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {bannerImgUpload && (
                        <aside>
                          <ul className="uploadedArticle">
                            <div className="uploadImgeboxNewArticle ">
                              <div className="position-relative uploadArticle_UploadImage">
                                <img
                                  className="w3-round uploadfileIcon "
                                  src={bannerImgUpload}
                                />
                                <button
                                  className="deleteUpload"
                                  onClick={() => removeFile(setBannerImgUpload)}
                                >
                                  <img
                                    src={Images.DeleteIcon}
                                    alt="delet image"
                                    className="img-fluid deletIcon"
                                  />
                                </button>
                              </div>
                            </div>
                          </ul>
                        </aside>
                      )}
                    </>
                  </div>
                </div>
                {draftData?.articleType !== "ai" && (
                  <>
                    <div className="searchBox">
                      <label htmlFor="email" className="input_Label">
                        Article Image
                      </label>
                      <>
                        {!articleMultiImgUpload.length ? (
                          <div className="uploadImgeboxNewArticle writeArticle_">
                            {webSelector?.loading || multiplePhotoLoader ? (
                              <div className="row">
                                <div className="col-sm-12 text-center pt-5 pb-5">
                                  <div
                                    className="spinner-grow text-success"
                                    role="status"
                                  ></div>
                                </div>
                              </div>
                            ) : (
                              <div
                                {...getRootProps2({ className: "dropzone" })}
                              >
                                <input
                                  {...getInputProps2({
                                    multiple: true,
                                    accept: "image/*",
                                  })}
                                  // onChange={(e) => {
                                  //   console.log("fie 845");
                                  //   handleFilesUpload(e.target.files);
                                  // }}
                                />
                                <img
                                  src={Images.upload}
                                  alt="uploadIcon"
                                  className="img-fluid articleupload_ mb-2"
                                />
                                <p className="uploadbtntext">
                                  Drag image here or{" "}
                                  <span className="ColoredText"> Browse </span>
                                </p>
                                <p className="uploadText mt-2">
                                  Supports JPG, PNG, SVG
                                </p>
                                <p className="uploadText mt-2">
                                  Upload Image with Resolution Min 1200 x 600
                                </p>
                              </div>
                            )}
                          </div>
                        ) : (
                          <aside>
                            <ul className="uploadedArticle position-relative">
                              {/* {articleMultiImgUpload?.map((file, index) => ( */}
                              <div
                                className="uploadImgeboxNewArticle uploadArticle_ImgBox"
                                // key={index}
                              >
                                {articleMultiImgUpload?.map((file, index) => (
                                  <div className="position-relative uploadArticle_UploadArticleImage">
                                    <img
                                      className="w3-round uploadfileIcon"
                                      src={file}
                                      alt={`Uploaded file ${index + 1}`}
                                    />
                                    <button
                                      className="deleteUpload"
                                      onClick={() => removeMultipleFiles(file)}
                                    >
                                      <img
                                        src={Images.DeleteIcon}
                                        alt="delete image"
                                        className="img-fluid deletIcon"
                                      />
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <div
                                {...getRootProps2({
                                  className:
                                    "dropzone signUpBtn addMore_ImgBtn",
                                })}
                              >
                                <input
                                  {...getInputProps2({
                                    multiple: true,
                                    accept: "image/*",
                                  })}
                                  // onChange={(e) =>
                                  //   handleFilesUpload(e.target.files)
                                  // }
                                />
                                Add More
                              </div>
                            </ul>
                          </aside>
                        )}
                      </>
                    </div>
                  </>
                )}
                <div className="searchBox">
                  <label for="email" className="input_Label">
                    Credit Image(Optional)
                  </label>
                  <input
                    className="form-control customInput"
                    placeholder="Write.."
                    value={creditImgUpload}
                    onChange={(e) => setCreditImgUpload(e.target.value)}
                  />
                </div>

                <div className="searchBox">
                  <label for="email" className="input_Label">
                    Google Drive or External Link
                  </label>

                  <div className="addOnSubBoxgooglrUrl mt-2">
                    <ul className="planTabs_">
                      <li
                        className={`planttabslist ${
                          selectedFilters.googleDriveLink === "no"
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleOptionClick("no")}
                      >
                        No
                      </li>
                      <li
                        className={`planttabslist ${
                          selectedFilters.googleDriveLink === "yes"
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleOptionClick("yes")}
                      >
                        Yes
                      </li>
                    </ul>
                  </div>
                  {/* <div className="userListStatus">
                    <h4
                      className={
                        selectedFilters.googleDriveLink === "no"
                          ? "statusHeading active"
                          : "statusHeading"
                      }
                      onClick={() =>
                        handleSelectFilters("googleDriveLink", "no")
                      }
                    >
                      No
                    </h4>
                    <h4
                      className={
                        selectedFilters.googleDriveLink === "yes"
                          ? "statusHeading active"
                          : "statusHeading"
                      }
                      onClick={() =>
                        handleSelectFilters("googleDriveLink", "yes")
                      }
                    >
                      Yes
                    </h4>
                  </div> */}
                </div>
                {selectedFilters?.googleDriveLink === "no" ? (
                  <>
                    {loader ? (
                      <div className="loaderOuter d-flex justify-content-center text-light flex-column align-items-center">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                        <p> Please wait, we are processing your file data.</p>
                      </div>
                    ) : (
                      <>
                        {/* {googleDriveUrl ? (
                          <h3 className=" articleeditor_ mt-3 position-relative justify-content-center articleUrl_show">
                            {" "}
                            Google Drive Url :-{""}
                            <span className="ps-2">{googleDriveUrl}</span>
                          </h3>
                        ) : ( */}
                        <div className=" articleeditor_ mt-3 position-relative">
                          {isLoading ? (
                            <div className="loaderOuter d-flex justify-content-center text-light flex-column align-items-center">
                              <div
                                className="spinner-grow text-light"
                                role="status"
                              ></div>
                            </div>
                          ) : (
                            <CKEditorWithRef
                              initData={ckEditorContent}
                              // ref={editorRef}
                              config={{
                                extraPlugins: ["easyimage"],
                                versionCheck: false,
                                removePlugins: [
                                  "ImageUpload",
                                  "MediaEmbed",
                                  "Table",
                                  "TableToolbar",
                                ],
                                toolbarLocation: "top",
                                removeButtons:
                                  "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
                                toolbar: [
                                  {
                                    name: "document",
                                    items: ["Undo", "Redo"],
                                  },
                                  { name: "styles", items: ["Format"] },
                                  {
                                    name: "basicstyles",
                                    items: [
                                      "Bold",
                                      "Italic",
                                      "Strike",
                                      "-",
                                      "RemoveFormat",
                                    ],
                                  },
                                  {
                                    name: "paragraph",
                                    items: ["NumberedList", "BulletedList"],
                                  },
                                  {
                                    name: "links",
                                    items: ["Link", "Unlink"],
                                  },
                                  {
                                    name: "insert",
                                    items: ["EasyImageUpload"],
                                  },
                                ],

                                cloudServices_uploadUrl:
                                  "https://33333.cke-cs.com/easyimage/upload/",
                                cloudServices_tokenUrl:
                                  "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
                                easyimage_styles: {
                                  gradient1: {
                                    group: "easyimage-gradients",
                                    attributes: {
                                      className: "easyimage-gradient-1",
                                    },
                                    label: "Blue Gradient",
                                    icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/gradient1.png",
                                    iconHiDpi:
                                      "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/gradient1.png",
                                  },
                                  gradient2: {
                                    group: "easyimage-gradients",
                                    attributes: {
                                      className: "easyimage-gradient-2",
                                    },
                                    label: "Pink Gradient",
                                    icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/gradient2.png",
                                    iconHiDpi:
                                      "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/gradient2.png",
                                  },
                                  noGradient: {
                                    group: "easyimage-gradients",
                                    attributes: {
                                      className: "easyimage-no-gradient",
                                    },
                                    label: "No Gradient",
                                    icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/nogradient.png",
                                    iconHiDpi:
                                      "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/nogradient.png",
                                  },
                                },
                                easyimage_toolbar: [
                                  "EasyImageFull",
                                  // "EasyImageSide",
                                  "EasyImageGradient1",
                                  "EasyImageGradient2",
                                  "EasyImageNoGradient",
                                  "EasyImageAlt",
                                ],
                                height: 630,
                                removeButtons: "PasteFromWord",
                                contentsCss: [
                                  "ckEditor.css",
                                  ".ckeditor-content-colour { color: #ffffff !important; font-family: sans-serif; font-size: 16px ; font-weight: 400 }",
                                ],
                                bodyClass: "ckeditor-content-colour",
                              }}
                              name="editor"
                              readOnly={false}
                              onChange={(event) => {
                                const editor = event.editor;
                                const data = editor.getData();
                                setCkEditorContent(data);
                              }}
                              type="classic"
                            />
                          )}

                          {!isLoading && (
                            <>
                              {/* <label
                                  className="articleRejectedBtn upload_Article_Btn add_Url_Btn pointCursor"
                                  // htmlFor="upload-file"
                                  onClick={() => handleOpenModal("addUrl")}
                                >
                                  Add Url
                                </label>
                                <input
                                  type="text"
                                  className="d-none"
                                  id="upload-file1"
                                  // accept=".pdf,.docx"
                                  key={Math.random()}
                                /> */}
                              <label
                                className="articleRejectedBtn upload_Article_Btn"
                                htmlFor="upload-file"
                              >
                                Upload Article
                              </label>
                              <input
                                type="file"
                                className="d-none"
                                id="upload-file"
                                accept=".pdf,.docx"
                                key={Math.random()}
                                onChange={handleFileUpload}
                              />
                            </>
                          )}
                        </div>
                        {/* )} */}
                      </>
                    )}
                  </>
                ) : (
                  <div className="searchBox mt-3 mb-3">
                    <input
                      className="form-control customInput"
                      placeholder="Add Link"
                      value={googleDriveUrl}
                      onChange={(e) => setGoogleDriveUrl(e.target.value)}
                    />
                  </div>
                )}

                {state?.type == "draft" ||
                state?.type == "schedule" ||
                state?.type == "draftupdate" ||
                state?.type == "rejected" ? (
                  <>
                    <div className="d-flex align-items-center">
                      <button
                        onClick={() => {
                          if (state?.type === "draft") {
                            navigate("/drafts");
                          } else {
                            navigate("/select-publication", {
                              state,
                            });
                          }
                        }}
                        className="signUpBtn me-3 mt-3"
                      >
                        Back
                      </button>
                      <button
                        onClick={veiwArticleDetails}
                        className="signUpBtn me-3 mt-3"
                      >
                        Next
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex align-items-center">
                      <button
                        className="signUpBtn  me-3 mt-3"
                        type="button"
                        onClick={() => {
                          if (state?.type === "draft") {
                            navigate("/drafts");
                          } else {
                            navigate("/select-publication", {
                              state,
                            });
                          }
                        }}
                      >
                        Back
                      </button>

                      <button
                        className="signUpBtn me-3 mt-3"
                        onClick={veiwArticleDetails}
                      >
                        Next
                      </button>
                    </div>
                    {/* <button
                      onClick={veiwArticleDetails}
                      className="signUpBtn mt-2"
                    >
                      Next
                    </button> */}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "addUrl" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "addUrl" ? "addUrl" : ""}
        child={
          modalDetail.flag === "addUrl" ? (
            <AddUrlModal
              close={() => handleOnCloseModal()}
              // handleArticleAddUrl={handleArticleAddUrl}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "addUrl" ? (
            <>
              <h2 className="modal_Heading">Add Url</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={Images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default WriteArticle;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckOut";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function PaymentDetail({ handleOpenModal, paymentFlag, paymentToken, allCardInfo }) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm 
        handleOpenModal={handleOpenModal} 
        paymentToken={paymentToken} 
        paymentFlag={paymentFlag} 
        allCardInfo={allCardInfo} 
      />
    </Elements>
  );
}

import React, { useEffect, useState } from "react";
import Showdown from "showdown";

export const useConvertMarkDownHtml = (markDowndata) => {
  const [htmlContent, setHtmlContent] = useState("");

  const converter = new Showdown.Converter();

  const convertMarkdownToHtml = (text) => {
    const convertedHtml = converter.makeHtml(text);
    setHtmlContent(convertedHtml);
  };

  useEffect(() => {
    convertMarkdownToHtml(markDowndata);
  }, [markDowndata]);

  return htmlContent;
};

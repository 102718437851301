import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { userSignUp } from "../../redux/slices/auth";
import { useAuthSelector } from "../../redux/selector/auth";
import * as Image from "../../utilities/images";
import { uploadImage } from "../../redux/slices/web";
import { onErrorStopLoad } from "../../redux/slices/auth";

const Signup = (props) => {
  const toastId = useRef(null);
  const location = useLocation();
  const [showPassword, setShowPassword] = useState("false");
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const { loading } = authSelector;
  const dispatch = useDispatch();
  const [image, setImage] = useState("");

  const [phone_no, setPhone_no] = useState("");
  const [dial_code, setDial_code] = useState("");
  const [isTermConditionChecked, setIsTermConditionChecked] = useState(false);
  const [photoLoader, setPhotoUploadLoader] = useState(false);
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    location: "",
    password: "",
    businessName: "",
    activeClient: "",
  });

  //onchange input
  const handleChange = (e, fieldname) => {
    const { value, name } = e.target;
    let filteredValue = value;

    // Check if the field is the first name or last name
    if (
      fieldname === "firstName" ||
      fieldname === "lastName" ||
      fieldname === "BusinessName"
    ) {
      // Filter out non-alphanumeric characters
      filteredValue = value?.replace(/[^a-zA-Z\s]/g, "");
    }
    if (fieldname === "termCondition") {
      setIsTermConditionChecked(!isTermConditionChecked);
    }

    setFormData({ ...formData, [name]: filteredValue });
  };

  // API for change the Profile Photo Upload ///////////////////////////////////
  const handleFileChange = (e) => {
    const imageFile = e.target.files[0];
    if (!imageFile && !image) {
      showToast("Please select image");
      return;
    }
    let params = {
      image: imageFile || "",
    };
    setPhotoUploadLoader(true)
    if (imageFile) {
      dispatch(
        uploadImage({
          ...params,
          cb(res) {
            if (res?.data) {
              setPhotoUploadLoader(false)
              setImage(res?.data?.data?.profile_photo);
            }
          },
        })
      );
    }
  };

  //form login
  const handleSubmit = (e) => {
    e.preventDefault();
    const reg = /^([^0-9$%]*)$/;
    if (!image) {
      showToast("Please upload image");
      return;
    } else if (!formData.firstName || formData.firstName.trim() === "") {
      showToast("Please enter first name");
      return;
    } else if (!formData.lastName) {
      showToast("Please enter last name");
      return;
    } else if (!formData.email) {
      showToast("Please enter email");
      return;
    } else if (
      formData.email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formData.email
      )
    ) {
      showToast("Please enter valid email address");
      return;
    } else if (!formData.location) {
      showToast("Please enter location");
      return;
    } else if (!formData.password) {
      showToast("Please enter password");
      return;
    } else if (
      !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
        formData.password
      )
    ) {
      showToast(
        "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
      );
      return;
    } else if (phone_no === undefined) {
      showToast("Please enter phone number");
      return;
    } else if (phone_no.length > 11 || phone_no.length < 10) {
      showToast("Please enter valid phone number");
      return;
    } else if (!formData.businessName) {
      showToast("Please enter business Name");
      return;
    } else if (!formData.activeClient) {
      showToast("Please enter number of active clients");
      return;
    } else if (!isTermConditionChecked) {
      showToast("Please agree to our terms and conditions to proceed further.");
      return;
    }
    let params = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      businessName: formData.businessName,
      noOfActiveClients: formData.activeClient,
      email: formData.email.trim(),
      password: formData.password,
      dialCode: dial_code,
      phoneNo: phone_no,
      profilePhoto: image,
      location: formData.location,
    };
    dispatch(
      userSignUp({
        ...params,
        cb(res) {
          if (res.status) {
            navigate("/verification", {
              state: {
                email: formData?.email,
                type: "verify",
                flag: location.state || "",
              },
            });
          }
        },
      })
    );
  };

  const handleOnChange = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhone_no(phoneNumber);
    setDial_code(phoneCode);
  };
  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    window.scrollTo(0, 100);
    document.title = "Sign-up";
  }, []);

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <section className="authbgSection">
        <Link to="/">
          <img
            src={Image.authlogo}
            alt="authLogo"
            className="authLogoImg img-fluid"
          />
        </Link>
        <div className="authOuterSec">
          <div className="signupSection authInnerSec">
            <h1 className="authheading"> Join us today!</h1>
            <p className="authPara mt-3">Please enter your details below</p>
            <form className="authForm" onSubmit={(e) => handleSubmit(e)}>
              <div className="signupForm">

                {photoLoader? (
                            <div className="row">
                              <div className="col-sm-12 text-center pt-5 pb-5">
                                <div
                                  className="spinner-grow text-success"
                                  role="status"
                                ></div>
                              </div>
                            </div>
                          ) : (
                            <div className="uplaodUser">
                  <figure className="uploadSec">
                    <img
                      src={image ? image : Image.defaultUser}
                      alt="defaultUser"
                      className="userImg"
                    />
                    <label className="uploadlabel">
                      <input
                        type="file"
                        id="myFile"
                        name="filename"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => handleFileChange(e)}
                      />

                      <div className="camImgBx">
                        <img
                          src={Image.cameraImg}
                          alt="cameraImg"
                          className="img-fluid"
                        />
                      </div>
                    </label>
                  </figure>
                </div>)

                }
                
                <div className="row mt-2">
                  <div className="col-lg-6 mt-3">
                    <div className="signupField">
                      {/* <label for="email" className="input_Label">
                      First Name:
                      </label> */}
                      <input
                        type="Text"
                        className="form-control nameControl"
                        placeholder="First Name"
                        onChange={(e) => handleChange(e, "firstName")}
                        maxLength={20}
                        value={formData.firstName}
                        name="firstName"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <div className="signupField">
                      {/* <label for="email" className="input_Label">
                      Last Name:
                      </label> */}
                      <input
                        type="Text"
                        className="form-control nameControl"
                        placeholder="Last Name"
                        onChange={(e) => handleChange(e, "lastName")}
                        value={formData.lastName}
                        name="lastName"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField">
                      {/* <label for="email" className="input_Label">
                      Business Email:
                      </label> */}
                      <input
                        type="email"
                        className="form-control nameControl"
                        placeholder="Enter Business Email"
                        onChange={(e) => handleChange(e)}
                        value={formData.email}
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField">
                      {/* <label for="email" className="input_Label">
                      Location:
                      </label> */}
                      <input
                        type="Text"
                        autoComplete="disable"
                        name="location"
                        className="form-control nameControl"
                        placeholder="Enter Location"
                        onChange={(e) => handleChange(e)}
                        value={formData.location}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField position-relative">
                      {/* <label for="email" className="input_Label">
                      Password:
                      </label> */}
                      <input
                        type={showPassword ? "password" : "text"}
                        className="form-control nameControl "
                        placeholder="Set Your Password"
                        onChange={(e) => handleChange(e)}
                        name="password"
                        value={formData.password}
                      />
                      <img
                        src={showPassword ? Image.hiddenEye : Image.showEye}
                        alt="showEyeImage"
                        className="img-fluid eyeIcon"
                        onClick={togglePasswordVisiblity}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    {/* <label for="email" className="input_Label">
                    Phone:
                    </label> */}
                    <div className="col-lg-12  d-flex justify-content-end">
                      <div className="phoneControl">
                        <PhoneInput
                          onChange={handleOnChange}
                          country="us"
                          className="inputTel"
                          placeholder="Enter Phone Number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField ">
                      {/* <label for="email" className="input_Label">
                       Business Name:
                      </label> */}
                      <input
                        type="Text"
                        className="form-control nameControl "
                        placeholder="Enter Business Name"
                        onChange={(e) => handleChange(e, "BusinessName")}
                        name="businessName"
                        value={formData.businessName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField ">
                      {/* <label for="email" className="input_Label">
                      Active Clients:
                      </label> */}
                      <input
                        type="number"
                        className="form-control nameControl "
                        placeholder="How many active clients you have?"
                        onChange={(e) => handleChange(e)}
                        name="activeClient"
                        maxLength={6}
                        value={formData.activeClient}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="form-group checkbx">
                      <input
                        type="checkbox"
                        id="javascript"
                        onChange={(e) => {
                          handleChange(e, "termCondition");
                        }}
                      />
                      <label for="javascript">
                        I have read and accepted the{" "}
                        <Link to="/termsConditions" className="linkText">
                          {" "}
                          Terms of Use
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy" className="linkText">
                          {" "}
                          Privacy Policy.
                        </Link>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <button type="submit" className="signUpBtn w-100">
                      Sign Up
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-2"></span>
                      )}
                    </button>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <p className="authPara mt-3">
                      Already a member?{" "}
                      <Link to="/login" className="linkSub">
                        Login Here
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deletePaymentCard } from "../../redux/slices/subscription";
import { useSubscriptionSelector } from "../../redux/selector/subscription";

const DeleteCardModal = (props) => {
  const dispatch = useDispatch();
    const subscriptionSelector = useSubscriptionSelector();
    const { loading } = subscriptionSelector;


    
    const handleDeleteCard = () => {
    let params = {
      id: props?.selectedCardId,
    };
    dispatch(
      deletePaymentCard({
        ...params,
        cb(res) {
          if (res) {
            props?.close()
            props?.getAllCardInfo();
          }
        },
      })
    );
  };

  return (
    <div className="logoutModalSec text-center">
        <button
          type="submit"
          className="signUpBtn my-3"
          onClick={() => {
            handleDeleteCard();
          }}
        >
          Yes, Delete
          {loading  && (
            <span className="spinner-border spinner-border-sm ms-2"></span>
          )}
        </button>
      </div>
  );
};

export default DeleteCardModal;

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { onErrorStopLoad, setCreateTicket } from '../../slices/ticket';
import { setContactUs, setGetAllSocialLinks, setGetHelperpagesInfo, setGetSchedule, setNewsletter, setSheduleDemoForm,setGetClientLogos } from '../../slices/homePage';

const queryString = require("query-string");

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* getClientLogos(action) {

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.homePageApiPath.GET_CLIENT_LOGOS}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetClientLogos(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* sheduleDemoFormApi(action) {
  let url = `${ApiPath.homePageApiPath.SHEDULE_DEMO_FORM}`;
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setSheduleDemoForm(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
    toast.error(e.response.data.message);
  }
}

function* getSchedule(action) {
  let data = { ...action.payload };

  let url = `${ApiPath.homePageApiPath.GET_SCHEDULE}?date=${data.date}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = data)
    );
    if (resp.status) {
      yield put(setGetSchedule(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* contactUs(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.homePageApiPath.CONTACT_US),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setContactUs(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* newsletter(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.homePageApiPath.NEWSLETTER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setNewsletter(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getHelperpagesInfo(action) {
  let data = { ...action.payload };

  let url = `${ApiPath.homePageApiPath.GET_HELPER_PAGE_INGO}?slug=${data.slug}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = data)
    );
    if (resp.status) {
      yield put(setGetHelperpagesInfo(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* getAllSocialLinks(action) {
  let data = { ...action.payload }

  let url = `${ApiPath.homePageApiPath.GET_ALL_SOCIAL_LINKS}`;
  try {
    const resp = yield call(ApiClient.get, (action.url = url), action.payload = data);
    if (resp.status) {
      yield put(setGetAllSocialLinks(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* homePageSaga() {
  yield all([
    takeLatest('home/sheduleDemoFormApi', sheduleDemoFormApi),
    takeLatest('home/getSchedule', getSchedule),
    takeLatest('home/contactUs', contactUs),
    takeLatest('home/newsletter', newsletter),
    takeLatest('home/getHelperpagesInfo', getHelperpagesInfo),
    takeLatest('home/getAllSocialLinks', getAllSocialLinks),
    takeLatest("home/getClientLogos", getClientLogos),
    
    
  ])
}

export default homePageSaga;

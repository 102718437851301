import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { uploadArticleImage } from "../../redux/slices/web";
import { toast } from "react-toastify";

const CropImageModal = (props) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [displayWidth, setDisplayWidth] = useState(0);
  const [displayHeight, setDisplayHeight] = useState(0);
  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 15,
    y: 15,
    width: 180,
    height: 90
  });

  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCropComplete = (crop) => {
    setCrop(crop);
    makeClientCrop(crop);
  };

  const handleCancle=() =>{
    props?.setCroppedImage([])
    props.close();
  }
  const makeClientCrop = async (crop) => {
    if (crop && crop?.width && crop?.height) {
      try {
  
        const fileBlobArray = props?.fileBlob;
        if (Array.isArray(fileBlobArray) && fileBlobArray.length > 0) {
          const fileBlob = fileBlobArray[0];
          const croppedImage = await getCroppedImg(
            fileBlob,
            crop,
            "cropped-image.jpg",
            displayWidth,
            displayHeight
          );
          setCroppedImageBlob(croppedImage);
        } else {
          throw new Error("File blob is empty or invalid");
        }
      } catch (error) {
        console.error("Error cropping image:", error);
      }
    } else {
      console.warn("Invalid crop object:", crop);
      setCroppedImageBlob(null);
    }
  };

  const getCroppedImg = (fileBlob, crop, fileName) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");

        // Use the same width and height for canvas to maintain the aspect ratio
        canvas.width = 1200;
        canvas.height = 600;

        const ctx = canvas.getContext("2d");
        const scaleX = image.naturalWidth / displayWidth;
        const scaleY = image.naturalHeight / displayHeight;

        // Calculate the scaled crop coordinates and dimensions
        const scaledCropX = crop.x * scaleX;
        const scaledCropY = crop.y * scaleY;
        const scaledCropWidth = crop.width * scaleX;
        const scaledCropHeight = crop.height * scaleY;

        // Draw the cropped area onto the canvas
        ctx.drawImage(
          image,
          scaledCropX,
          scaledCropY,
          scaledCropWidth,
          scaledCropHeight,
          0,
          0,
          1200,
          600
        );

        // Convert canvas to blob
        canvas.toBlob(
          (blob) => {
            blob.name = fileName;
            resolve(blob);
          },
          "image/jpeg",
          0.9
        );
      };

      if (fileBlob instanceof Blob) {
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        reader.onload = (event) => {
          image.src = event.target.result;
        };
      } else {
        reject(new Error("Invalid file blob"));
      }
    });
  };

  // API for upload article banner image
  const uploadImage = () => {
    setLoading(true);
    let imageFile = croppedImageBlob;
    if (!imageFile) {
      toast.error("Please crop image");
      setLoading(false);
      return;
    }
    let params = {
      images: imageFile,
    };
    dispatch(
      uploadArticleImage({
        ...params,
        cb(res) {
          setLoading(false);
          if (res.status) {
            props?.setCroppedImage(res?.data?.data);
            props.close();
          }
        },
      })
    );
  };

  const handleImageLoaded = (image) => {
    if (containerRef.current) {
      setDisplayWidth(containerRef.current.clientWidth);
      setDisplayHeight(containerRef.current.clientHeight);
    }
  };

  return (
    <>
      <ReactCrop
        src={props?.selectedImg[0]}
        crop={crop}
        onComplete={(crop) => onCropComplete(crop)}
        onChange={(newCrop) => setCrop(newCrop)}
        aspect={2/1}
      >
        <div className="d-flex justify-content-center align-items-center">
          <img src={props?.selectedImg[0]} onLoad={handleImageLoaded} ref={containerRef} alt="image" className="corpImage_" style={{"width":"100%"}} />
        </div>
      </ReactCrop>

      <div className="saveCropImgBtn d-flex justify-content-center align-items-center mt-4">
          <button className="submitBtn me-3" type="button" onClick={uploadImage}>
            Crop Image
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
          </button>
          <button className="submitBtn cancleBtn" type="button" onClick={() =>handleCancle()}>
            Cancel
          </button>
      </div>
    </>
  );
};

export default CropImageModal;
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  onErrorStopLoad,
  setArticleTransaction,
  setCreatePaymentCard,
  setCreateSubscription,
  setDeletePaymentCard,
  setDeleteSubscription,
  setGetAllPaymentCards,
  setGetAllSubscription,
  setGetSubscriptionInfo,
  setGetTransaction,
} from "../../slices/subscription";

function* getAllSubscription(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.subscriptionApiPath.GET_ALL_SUBSCRIPTION),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setGetAllSubscription(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getTransaction(action) {
  try {
    let mainUrl = `${ApiPath.subscriptionApiPath.GET_TRANSACTION}`;
    //?limit=${action.payload.limit}`;
    // if (action.payload.nextPage) {
    //   mainUrl += `&nextPage=${action.payload.nextPage}`;
    // }
    // if (action.payload.previousPage) {
    //   mainUrl += `&previousPage=${action.payload.previousPage}`;
    // }

    const resp = yield call(ApiClient.get, (action.url = mainUrl));
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setGetTransaction(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getArticleTransaction(action) {
  try {
    let mainUrl = `${ApiPath.subscriptionApiPath.GET_ARTICLE_TRANSACTION}`;
    const resp = yield call(ApiClient.get, (action.url = mainUrl));
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setArticleTransaction(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* createSubscription(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.subscriptionApiPath.CREATE_SUBSCRIPTION),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setCreateSubscription(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* deleteSubscription(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = ApiPath.subscriptionApiPath.DELETE_SUBSCRIPTION),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setDeleteSubscription(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getSubscriptionInfo(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.subscriptionApiPath.GET_SUBSCRIPTION_INFO),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setGetSubscriptionInfo(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* createPaymentCard(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.subscriptionApiPath.CREATE_PAYMENT_CARD),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setCreatePaymentCard(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
    toast.error(e.response.data.error);
  }
}

function* getAllPaymentCards(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.subscriptionApiPath.GET_ALL_PAYMENT_CARDS),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setGetAllPaymentCards(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* deletePaymentCard(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.subscriptionApiPath.DELETE_PAYMENT_CARD}/${action.payload.id}`)
    );

    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setDeletePaymentCard(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}


function* subscriptionSaga() {
  yield all([takeLatest("subscription/getAllSubscription", getAllSubscription),]);
  yield all([takeLatest("subscription/createSubscription", createSubscription),]);
  yield all([takeLatest("subscription/getTransaction", getTransaction)]);
  yield all([takeLatest("subscription/getArticleTransaction", getArticleTransaction)]);
  yield all([takeLatest("subscription/deleteSubscription", deleteSubscription),]);
  yield all([takeLatest("subscription/getSubscriptionInfo", getSubscriptionInfo),]);
  yield all([takeLatest("subscription/createPaymentCard", createPaymentCard)]);
  yield all([takeLatest("subscription/getAllPaymentCards", getAllPaymentCards)]);
  yield all([takeLatest("subscription/deletePaymentCard", deletePaymentCard)]);
}

export default subscriptionSaga;

import React, { useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import OtpTimer from "otp-timer";
import {
  forgetPasswodVerifyOtp,
  userOtp,
  userVerifyOtp,
} from "../../redux/slices/auth";
import * as Image from "../../utilities/images";

const Verification = () => {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authSelector = useAuthSelector();
  const { state } = location;
  const { loading } = authSelector;
  const [otp, setOtp] = useState("");
  const [key] = useState(Math.random());
  const [isLoading, setIsLoading] = useState("");

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  //form submit
  const handleSubmit = (e, flag) => {

    e.preventDefault();
    if (!otp) {
      showToast("Please enter OTP");
      return;
    }
    if (state?.type === "verify") {
      let params = {
        email: state?.email,
        otp: otp,
      };
      setIsLoading(flag);
      dispatch(
        userOtp({
          ...params,
          cb(res) {
            if (res.status) {
              navigate("/email-success",{
                state: location.state
              });
            }
          },
        })
      );
    } else if (state?.type === "forgot") {
      let params = {
        otp: otp,
      };
      dispatch(
        forgetPasswodVerifyOtp({
          ...params,
          cb(res) {
            if (res.status) {
              navigate("/reset-password", {
                state: { userId: res?.data?.data?.user_id },
              });
            } else {
            }
          },
        })
      );
    }
  };

  const resendOtp = (e, flag) => {
    setOtp("");
    // e.preventDefault();
    if (state?.type === "verify") {
      let params = {
        type: "verify",
        email: state?.email,
      };
      // setIsLoading(flag);
      dispatch(
        userVerifyOtp({
          ...params,
          cb(res) {
            if (res.status) {
            }
          },
        })
      );
    } else if (state?.type === "forgot") {
      let params = {
        type: "forgot",
        email: state?.email,
      };
      dispatch(
        userVerifyOtp({
          ...params,
          cb(res) {
            if (res.status) {
            }
          },
        })
      );
    }
  };

  return (
    <>
      <section className="authbgSection authOtherSection">
        <img
          src={Image.authlogo}
          alt="authLogo"
          className="authLogoImg img-fluid pointCursor"
          onClick={() => navigate("/login")}
        />
        <div className="authOuterSec">
          <div className="signupSection authInnerSec">
            {state?.type === "verify" ? (
              <>
                <h1 className="authheading">Verify your Email</h1>
                <p className="authPara mt-3">
                  Enter the four-digit code we sent you via email to continue.
                </p>
              </>
            ) : (
              <>
                <h1 className="authheading">Enter OTP</h1>
                <p className="authPara mt-3">
                  Enter the OTP that we just sent you on your email <br />
                  address to reset your password.
                </p>
              </>
            )}
            <form
              className="authForm"
              onSubmit={(e) => {
                handleSubmit(e, "verify");
              }}
            >
              <div className="signupForm">
                <div className="row mt-2">
                  <div className="col-lg-12 mt-3">
                    <div className="authOtpSec">
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        data-cy="pin-field"
                        isInputNum={true}
                        isInputSecure={true}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className="enterOtp"
                            type="number"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-5">
                    <button
                      disabled={loading}
                      type="submit"
                      className="signUpBtn w-100"
                    >
                      Submit
                      {loading && isLoading === "verify" && (
                        <span className="spinner-border spinner-border-sm ms-1"></span>
                      )}
                      
                    </button>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <p className="authPara ">Not Received?</p>
                      <div className="linkSub ms-1 timer_Btn ms-1">
                        <OtpTimer
                          //minutes={1}
                          seconds={60}
                          key={key}
                          //seconds={otpTimerPeriod}
                          //text="Resend OTP in:"
                          ButtonText="Resend OTP"
                          resend={resendOtp}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Verification;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotificateSelector } from "../../redux/selector/notification";
import { readNotification } from "../../redux/slices/notification";
import * as Images from "../../utilities/images";
import moment from "moment";

const NotificationModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationId, setNotiFicationId] = useState("");
  const notificationSelector = useNotificateSelector();
  const { loading } = notificationSelector;

  const readNotifyMsg = (id, userid) => {
    setNotiFicationId(id);
    let params = {
      is_read: "true",
      id: id,
    };
    dispatch(
      readNotification({
        ...params,
        cb(res) {
          if (res?.data) {
            if (res?.data?.data?.type === "article_updated")
              navigate(`/articlesDetails/${userid}`, {
                // navigate("/articlesDetails", {
                state: { flag: "notification" },
              });
            if (res?.data?.data?.type === "articleMessage")
              navigate(`/article-messages/${userid}`, {
                state: {
                  flag: "articleMessage",
                },
              });
            if (res?.data?.data?.type === "ticketMessage")
              navigate(`/ticket-details/${userid}`, {
                state: {
                  flag: "ticketMessage",
                },
              });
            else if (res?.data?.data?.type === "subscription_updated")
              navigate("/subscriptions", {
                state: { articleId: userid },
              });
            else if (res?.data?.data?.type === "ticket_updated")
              navigate(`/ticket-details/${userid}`);
            props?.close();
          }
        },
      })
    );
  };

  return (
    <>
      <div className="logoutModalSec text-end  mb-3">
        <h3
          className="seeAll_Notification pointCursor"
          onClick={() => {
            navigate("/notification");
            props?.close();
            localStorage.setItem("notificationKey", "reload");
          }}
        >
          See All
        </h3>
      </div>
      <div>
        {/* {loading && !notificationId ? (
          <>
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          </>
        ) : (
          <> */}
        {notificationSelector?.notificationData?.length > 0 ? (
          notificationSelector?.notificationData
            ?.filter((item) => !item?.is_read)
            // ?.slice(0, 5)
            .map((value, index) => (
              <>
                {loading && value?._id === notificationId ? (
                  <div className="notifyList notifyLoader">
                    <div className="loaderOuter d-flex justify-content-center text-light">
                      <div
                        className="spinner-grow text-light"
                        role="status"
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      value?.is_read === false
                        ? "notifyList active notifyModal_List pointCursor"
                        : "notifyList notifyModal_List pointCursor"
                    }
                    key={index}
                    onClick={() => {
                      readNotifyMsg(value?._id, value?.notificationTypeId);
                    }}
                  >
                    <div className="notifyMsgArea">
                      <figure className="notifyUser">
                        <img
                          src={
                            value?.user?.profilePhoto
                              ? value?.user?.profilePhoto
                              : Images.dummyProfile
                          }
                          alt="publication Logo"
                          className="img-fluid notifyUserImg"
                        />
                        <div className="bellNotify">
                          <img
                            src={Images.lightBell}
                            alt="publication Logo"
                            className="img-fluid"
                          />
                        </div>
                      </figure>
                      <div className="notifyData">
                        <p className="uploadbtntext mb-1">
                          {value?.description}
                        </p>
                        <h6 className="notifyTime">
                          {moment(value?.createdAt).fromNow()}
                        </h6>
                      </div>
                    </div>
                    <span className="unreadBtn"></span>
                  </div>
                )}
              </>
            ))
        ) : (
          <h3 className="text-center m-0 innerHeadText noNotificationsFound">
            No Notification Found!
          </h3>
        )}
        {/* </>
        )} */}
      </div>
    </>
  );
};

export default NotificationModal;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSubscriptionSelector } from "../../../redux/selector/subscription";
import { getSubscriptionInfo } from "../../../redux/slices/subscription";
import * as images from "../../../utilities/images";
import CustomModal from "../../Modal/CustomModal";
import DeleteSubscriptionModal from "../../Modal/deleteSubscriptionPlanModal ";

const Subscription = () => {
  document.title = "Subscriptions";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscriptionSelector = useSubscriptionSelector();
  const [key, setKey] = useState(Math.random());
  const [currentPlanInfo, setCurrentPlanInfo] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  /**
   * To get current subscription of user
   */
  const getCurrentSubscriptionInfo = () => {
    dispatch(
      getSubscriptionInfo({
        cb(res) {
          if (res?.data) {
            setCurrentPlanInfo(res?.data?.data);
            if (!res?.data?.data) {
              navigate("/subscription");
            }
          }
        },
      })
    );
  };

  useEffect(() => {
    getCurrentSubscriptionInfo();
  }, []);

  return (
    <>
      <div className="content-wrapper upgradeSubcription_Content">
        <div className="content-header">
          {subscriptionSelector?.loading ? (
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          ) : (
            <div className="content-header ticketHead_">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <div className="current_Plan_Parent">
                      <h1 className="mainHead32">Current Plan</h1>
                      <h3 className="subcription_Plan_Price second_big">
                        ${currentPlanInfo?.price}
                        <span className="subcription_Plan_Month medium_h">
                          /{currentPlanInfo?.planInterval}
                        </span>
                      </h3>
                      {currentPlanInfo?.planInterval !== "Free" && (
                        <p className="next_Subs_Payment fontSize18">
                          Your subscription plan ends on{" "}
                          <span className="payment_Date">
                            {currentPlanInfo?.expiryDate}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  {currentPlanInfo ? (
                    <div className="col-sm-6 d-flex justify-content-end upgradeSubcription_Btn">
                      <div className="d-flex flex-column">
                        <button
                          type="button"
                          className="col-sm-6 signUpBtn upgrade_Subcription_Btn mt-2"
                          onClick={() =>
                            navigate("/subscription", {
                              state: {
                                currentPlanInfo: currentPlanInfo,
                              },
                            })
                          }
                        >
                          Upgrade Subscription Plan
                        </button>
                        {currentPlanInfo?.planInterval === "Free" ||
                        currentPlanInfo?.nextPayment === false ||
                        currentPlanInfo?.purchasedBy === "admin" ? (
                          ""
                        ) : (
                          <button
                            type="button"
                            className="secondaryBtb_ mt-3 "
                            onClick={() => handleOpenModal("deletePlanModal")}
                          >
                            Unsubscribe Subscription
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="includes_Parent"></div>

                    <h2 className="sub_p_text subscription_Includes">
                      INCLUDES:
                    </h2>
                    <div>
                      <img src={images.planscheklist} alt="planchecklist" />

                      <span className="sub_p_text ms-3">
                        Maximum Articles{" "}
                        {currentPlanInfo?.planInterval !== "Free"
                          ? `per ${currentPlanInfo?.planInterval}`
                          : ""}{" "}
                        : {currentPlanInfo?.maxArticlesPerMonth || "----"}
                      </span>
                    </div>
                    {currentPlanInfo?.planInterval !== "Free" &&
                    <div>
                      <img src={images.planscheklist} alt="planchecklist" />
                      <span className="sub_p_text ms-3">
                      Markup : {" "} {currentPlanInfo?.markUp}%
                      </span>
                    </div>}
                    {currentPlanInfo?.features?.map((data,idx) => (
                      <div key={idx}>
                        <img src={images.planscheklist} alt="planchecklist"/>
                        <span className="sub_p_text ms-3">{data}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "deletePlanModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "deletePlanModal" ? "deletCategoryModal" : ""}
        child={
          modalDetail.flag === "deletePlanModal" ? (
            <DeleteSubscriptionModal
              close={() => handleOnCloseModal()}
              getCurrentSubscriptionInfo={() => getCurrentSubscriptionInfo()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "deletePlanModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to unsubscribe ?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Subscription;

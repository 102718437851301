import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNotificateSelector } from "../../../redux/selector/notification";
import {
  readNotification,
  updateNotification,
} from "../../../redux/slices/notification";
import { useAuthSelector } from "../../../redux/selector/auth";
import { userDetail } from "../../../redux/slices/auth";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useAuthSelector();
  const [notificationFlag, setNotificationFlag] = useState("");
  const notificationSelector = useNotificateSelector();
  const [notificationId, setNotiFicationId] = useState("");
  const [enableDisabled, setEnableDisabled] = useState("");
  const { loading } = notificationSelector;

  /**
   * to get notification from api
   * @param {*} id
   * @param {*} read
   * @returns dispatch notification
   */

  const readNotifyMsg = (id, userid) => {
    setNotiFicationId(id);
    let params = {
      is_read: "true",
      id: id,
    };
    dispatch(
      readNotification({
        ...params,
        cb(res) {
          if (res?.data) {
            if (res?.data?.data?.type === "article_updated")
              navigate(`/articlesDetails/${userid}`, {
                // navigate("/articlesDetails", {
                state: { flag: "notification" },
              });
            if (res?.data?.data?.type === "articleMessage")
              navigate(`/article-messages/${userid}`, {
                state: {
                  flag: "articleMessage",
                },
              });
            if (res?.data?.data?.type === "ticketMessage")
              navigate(`/ticket-details/${userid}`, {
                state: {
                  flag: "ticketMessage",
                },
              });
            else if (res?.data?.data?.type === "subscription_updated")
              navigate("/subscriptions", {
                state: { articleId: userid, flag: "notification" },
              });
            else if (res?.data?.data?.type === "ticket_updated")
              navigate(`/ticket-details/${userid}`);
          }
        },
      })
    );
  };

  /**
   * to get current logged in user info
   */
  const getOneUserDetail = () => {
    let params = {
      id: authSelector?.userInfo?.userInfo.id,
    };
    dispatch(
      userDetail({
        ...params,
        cb(res) {
          if (res?.data) {
            setNotificationFlag(res?.data?.data?.userInfo?.pushNotification);
            setEnableDisabled(res?.data?.data?.userInfo?.pushNotification);
          }
        },
      })
    );
  };

  /**
   * to enable notification service
   * @param {*} flag
   */
  const enableNotification = (flag) => {
    // setEnableDisabled(flag)
    let params = {
      pushNotification: flag,
    };
    dispatch(
      updateNotification({
        ...params,
        cb(res) {
          if (res?.status) {
            getOneUserDetail();
          }
        },
      })
    );
  };

  useEffect(() => {
    getOneUserDetail();
  }, []);

  return (
    <section className="content-wrapper notificationSection">
      <div className="flexBox mb-5">
        <h1 className="innerHeadText">Notifications</h1>
        <div className="notificationBtn">
          <h4 className="labelText">Push notifications:</h4>
          <div className="enableBox">
            <div className="roundCheck  mb-0">
              <label
                className="form-check-label uploadbtntext me-2"
                htmlFor="flexSwitchCheckChecked"
              >
                {enableDisabled ? "Enabled" : "Disabled"}
              </label>
              <input
                className="form-check-input m-0"
                type="checkbox"
                id="flexSwitchCheckChecked"
                checked={notificationFlag}
                onChange={(e) => {
                  enableNotification(e.target.checked);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {loading && !notificationId && notificationKey === "reload" ? (
          <>
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          </>
        ) : (
          <> */}
      {notificationSelector?.notificationData?.length > 0 ? (
        notificationSelector?.notificationData?.map((value, index) => (
          <>
            {loading && value?._id === notificationId ? (
              <div className="notifyList notifyLoader" key={index}>
                <div className="loaderOuter d-flex justify-content-center text-light">
                  <div className="spinner-grow text-light" role="status"></div>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className={
                  value?.is_read === false
                    ? "notifyList pointCursor active"
                    : "notifyList pointCursor"
                }
                onClick={() => {
                  readNotifyMsg(value?._id, value?.notificationTypeId);
                }}
              >
                <div className="notifyMsgArea">
                  <figure className="notifyUser">
                    <img
                      src={
                        value?.user?.profilePhoto
                          ? value?.user?.profilePhoto
                          : Images.dummyProfile
                      }
                      alt="publication Logo"
                      className="img-fluid notifyUserImg"
                    />
                    <div className="bellNotify">
                      <img
                        src={Images.lightBell}
                        alt="publication Logo"
                        className="img-fluid"
                      />
                    </div>
                  </figure>
                  <div className="notifyData">
                    <p className="uploadbtntext mb-1">{value?.description}</p>
                    <h6 className="notifyTime">
                      {moment(value?.createdAt).fromNow()}
                    </h6>
                  </div>
                </div>
                <span className="unreadBtn"></span>
              </div>
            )}
          </>
        ))
      ) : (
        <h3 className="text-center m-0 innerHeadText noNotificationsFound">
          No Notification Found!
        </h3>
      )}
      {/* </>
        )} */}
    </section>
  );
};

export default Notification;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as images from "../../utilities/images";

const UnderreviewModal = (props) => {
  const navigate = useNavigate();

  const handleCloseUnderreview = () => {
    props?.close();
    navigate("/articles");
  };

  useEffect(() => {
    // Close the modal and navigate to the homepage after 4 seconds
    const timer = setTimeout(() => {
      props.close();
      navigate("/articles");
    }, 4000);

    // Cleanup the timeout if the component unmounts before the 4 seconds
    return () => clearTimeout(timer);
  }, [props, navigate]);

  return (
    <div className="underreviewmodal_">
      <h2 className="modal_Heading mb-3">Your Article Is Under Review</h2>
      <p className="form_subheading ">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer laoreet
        tortor ac nunc condimentum euismod. Phasellus sodales malesuada purus eu
        suscipit.
      </p>
      <p onClick={() => handleCloseUnderreview()} className="modal_cancel">
        <img src={images.modalCross} className="ModalCance" alt="" />
      </p>
    </div>
  );
};

export default UnderreviewModal;

import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTicketSelector } from "../../../redux/selector/ticket";
import { getAllTickets } from "../../../redux/slices/ticket";
import * as images from "../../../utilities/images";

const Tickets = () => {
  document.title = "Ticket";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const ticketSelector = useTicketSelector();
  const { loading } = ticketSelector;
  const [ticketsList, setTicketList] = useState([]);
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");

  // get all users
  useEffect(() => {
    let params = {
      page: currentPage,
      limit: 10,
      status: status || undefined,
    };
    dispatch(
      getAllTickets({
        ...params,
        cb(res) {
          if (res?.data) {
            setTicketList(res?.data?.data?.data);
            setPageCount(res.data.data.total_pages);
          }
        },
      })
    );
  }, [status, currentPage, state?.flag]);

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <>
      <div className="content-wrapper ">
        <div className="content-header">
          <div className="content-header ticketHead_">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-6 d-flex align-items-center">
                  <h1 className="innerHeadText">Tickets</h1>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => navigate("/Create-Ticket")}
                    className="col-sm-6 signUpBtn mt-2 raise_Ticket_Btn"
                  >
                    Raise a Ticket
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="recentUserSec">
          <div className="userListStatus">
            <h4
              className={
                status === "" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("")}
            >
              All
            </h4>
            <h4
              className={
                status === "pending" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("pending")}
            >
              Open
            </h4>
            <h4
              className={
                status === "completed"
                  ? "statusHeading active"
                  : "statusHeading"
              }
              onClick={() => setStatus("completed")}
            >
              Closed
            </h4>
          </div>
          <div className="table-responsive recentTable ticketTable ">
            <table className="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    Ticket NO.
                  </th>
                  <th scope="col" className="recentHead">
                    ARTICLE
                  </th>
                  <th scope="col" className="recentHead">
                    TITLE
                  </th>
                  <th scope="col" className="recentHead">
                    DESCRIPTION
                  </th>
                  <th scope="col" className="recentHead">
                    DATE
                  </th>
                  <th scope="col" className="recentHead">
                    STATUS
                  </th>
                  <th scope="col" className="recentHead ">
                    ACTION
                  </th>
                </tr>
              </thead>

              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <>
                  <tbody>
                    {ticketsList?.length > 0 ? (
                      ticketsList?.map((data, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="recentSubHead pointCursor" onClick={() =>
                                  navigate(`/ticket-details/${data?._id}`, {
                                    state: {
                                      ticketInfo: data,
                                    },
                                  })
                                }>
                              <h4 className="tableSubText">
                                #{data?.ticketNo}
                              </h4>
                            </td>
                            <td className="recentSubHead pointCursor" onClick={() =>
                              navigate(`/articlesDetails/${data?.articleId }`,{
                            // navigate(`/articlesDetails`, {
                              state: {
                                // articleId: data?.articleId,
                                flag:"ticket"
                              },
                            })
                          }>
                              <div className="nameProfile" >
                                <h4 className="tableSubText">
                                  {data?.articleTitle || "-----"}
                                </h4>
                              </div>
                            </td>

                            <td className="recentSubHead pointCursor" onClick={() =>
                                  navigate(`/ticket-details/${data?._id}`, {
                                    state: {
                                      ticketInfo: data,
                                    },
                                  })
                                }>
                              <h4 className="tableSubText">
                                {data?.title?.length <= 30
                                  ? data?.title
                                  : `${data?.title?.slice(0, 30)}...`}
                              </h4>
                            </td>
                            <td className="recentSubHead">
                              <h4 className="tableSubText DescHead">
                                <span className="DescTicket">
                                  {data?.description?.length <= 30
                                    ? data?.description
                                    : `${data?.description?.slice(0, 30)}...`}
                                </span>
                              </h4>
                            </td>
                            <td className="recentSubHead">
                              <h4 className="tableSubText">
                                {moment(data?.createdAt).format("MM/DD/YYYY")}
                              </h4>
                            </td>
                            <td className="recentSubHead">
                              <h4 className="tableSubText">
                                <button
                                  type="button"
                                  className={
                                    data?.status == "pending"
                                      ? "pendingTkt"
                                      : "completeTkt"
                                  }
                                >
                                  {data?.status}
                                </button>
                              </h4>
                            </td>
                            <td className="recentSubHead text-center">
                              <button
                                className="editBtn"
                                type="button"
                                onClick={() =>
                                  navigate(`/ticket-details/${data?._id}`, {
                                    state: {
                                      ticketInfo: data,
                                    },
                                  })
                                }
                              >
                                <img
                                  src={images.eyeImg}
                                  className="img-fluid"
                                  alt="editPen image"
                                />
                                View
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={9} className="noFoundHead">
                          <h3 className="  text-center m-0 noFoundText">
                            No Tickets Found!
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
        {ticketsList && ticketsList?.length > 0 && (
          <div className=" paginateSec">
            <ReactPaginate
              className="paginationBox mb-0"
              nextLabel=" >"
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Tickets;

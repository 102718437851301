import React, { useState } from "react";

const AddUrlModal = (props) => {
  const [articleUrl, setArticleUrl] = useState("");

  const handleAddUrl = () => {
    props?.handleArticleAddUrl(articleUrl)
    props?.close()
  }

  return (
    <>
      <div>
        <label for="url" className="add_Url_Txt">
          Add URL:
        </label>
        <input
          type="url"
          id="url"
          name="url"
          className=" nameControl"
          value={articleUrl}
          onChange={(e) => {
            setArticleUrl(e.target.value);
          }}
        />
        <div className="text-center">
          <button class="signUpBtn mt-3" onClick={() => handleAddUrl()}>
            Add
          </button>
        </div>
      </div>
    </>
  );
};

export default AddUrlModal;

import React from "react";
import { useNavigate } from "react-router-dom";
import * as Image from "../../utilities/images";

const ScheduleDemoModal = (flag) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="scheduleModalSec text-center">
        <img
          src={Image.verifyImg}
          alt="verifyImg"
          className="img-fluid mt-4 mb-2 scheduleModalimg"
        />
        <h2 className="authheading my-2">Oh Yeah!</h2>
        <p className="modalPara">You have successfully schedule a demo</p>
        <button
          className="signUpBtn my-4 mt-5"
          type="button"
          onClick={() => {
            flag.close();
            navigate("/");
          }}
        >
          OK
        </button>
      </div>
    </>
  );
};

export default ScheduleDemoModal;

import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import MainNav from "../common/MainNav";
import Sidebar from "../common/Sidebar";
import MainFooter from "../common/MainFooter";

const AuthLayout = () => {
  return (
    <>
      <>
        {/* <Sidebar /> */}
        <main className="main" id="main">
          {/* <MainNav /> */}
          <Outlet />
        </main>
        {/* <MainFooter /> */}
      </>
    </>
  );
};

export default AuthLayout;

import React, { useEffect } from "react";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";

const Faq = () => {
  document.title = "FAQs";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="footerpage_faq_Parent">
        <Navbar />
        <section className=" faq_Page">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-12">
              <div className="frequently">
                <h2 className="medium_h">FAQs (Frequently Asked Questions)</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                className="accordion yloreAccordion mt-5"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is OwnBuzz?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      OwnBuzz is a subscription-based platform that provides PR
                      and marketing agencies with unparalleled access to over
                      1000 digital publications, 100 TV stations, leading print
                      magazines, and popular podcasts. We offer an automated PR
                      service with guaranteed publication, fast turnaround
                      times, and innovative AI content creation to empower
                      agencies to amplify their clients' brands.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How does the automated PR service work?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Our automated PR service streamlines the submission,
                      tracking, and publication process. Once you upload your
                      article or press release, you can track its progress
                      through real-time approval, submission, and publication
                      stages. Our system ensures a smooth, transparent journey
                      from content creation to publication, with guaranteed
                      placement and no need for traditional pitching.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What types of content can I publish with OwnBuzz?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      OwnBuzz supports the publication of articles and press
                      releases. Our platform offers flexibility in content
                      format, allowing agencies to tailor their message
                      according to their client's brand voice and campaign
                      goals.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Can OwnBuzz help improve my clients' SEO?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Yes, one of the key benefits of publishing through OwnBuzz
                      is the provision of do-follow backlinks from
                      high-authority publications. This significantly boosts
                      your clients' SEO, improving their online visibility and
                      search engine rankings.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      What is the turnaround time for publication?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Our basic package offers a fast publishing turnaround,
                      with articles going live within 24 hours. This ensures
                      timely publication of your clients' content, enabling
                      leveraging current trends and news for maximum impact.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      How does the AI content creation feature work?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      OwnBuzz’s proprietary AI software can generate
                      high-quality, human-like articles in seconds. This tool is
                      designed to assist agencies in creating compelling content
                      efficiently, suitable for campaigns that require quick
                      turnaround without compromising on quality. Agencies also
                      have the option to upload custom-written articles.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Are there any subscription plans?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Yes, OwnBuzz offers scalable subscription plans to cater
                      to the diverse needs of PR and marketing agencies. Whether
                      you're a small agency looking to grow or a large firm
                      managing multiple clients, our plans are designed to scale
                      with your needs.s.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      How do I track the performance of my campaigns?
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Subscribers have access to detailed analytics and reports
                      that provide insights into campaign performance, media
                      reach, and audience engagement. These tools help agencies
                      refine their strategies and demonstrate the value of their
                      campaigns to clients.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      What support does OwnBuzz offer to agencies?
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      OwnBuzz provides dedicated support and consultation
                      services, including access to PR and marketing experts.
                      Our team is available to assist with campaign strategy and
                      content optimization and answer any questions about using
                      the platform.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      How can I subscribe to OwnBuzz?
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Subscribing to OwnBuzz is simple. Visit our website and
                      choose the subscription plan that fits your agency's needs
                      best. If you need help selecting a plan or have any
                      questions, our support team is ready to assist you.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="sub_p_text pt-5 text-left">
            OwnBuzz is committed to empowering PR and marketing agencies to
            maximize their clients' media exposure and campaign success. If you
            have any other questions, feel free to contact our support team for
            more information.
          </p>
        </div>
      </section>
      </div>
      <Footer />
    </>
  );
};

export default Faq;

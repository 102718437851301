import React, { useRef, useState } from "react";
import * as Image from "../../../utilities/images";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  changeEmail,
  changePassword,
  editUserProfileInfo,
} from "../../../redux/slices/auth";
import { uploadImage } from "../../../redux/slices/web";
import { useAuthSelector } from "../../../redux/selector/auth";

const Profile = () => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const authSelector = useAuthSelector();
  const userInfo = authSelector?.userInfo?.userInfo;
  const [image, setImage] = useState(userInfo?.profilePhoto);
  const [activeTab, setActiveTab] = useState("profileTab");
  const [currentPasswordVisibility, setCurrentPasswordVisibility] =
    useState("false");
  const [passwordNewVisibility, setPasswordNewVisibility] = useState("false");
  const [passwordOldVisibility, setPasswordOldVisibility] = useState("false");
  const [passwordConfirmVisibility, setPasswordConfirmVisibility] =
    useState("false");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userEmail, setUserEmail] = useState(userInfo?.email);
  const [userCurrentPassword, setUserCurrentPassword] = useState("");
  const [phone_no, setPhone_no] = useState(userInfo?.phoneNo);
  const [dial_code, setDial_code] = useState(userInfo?.dialCode);
  const [formData, setFormData] = useState({
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    location: userInfo?.location,
    businessName: userInfo?.businessName,
    noOfActiveClients: userInfo?.noOfActiveClients,
  });

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const fullPhoneNumber = dial_code + phone_no;

  var specialCharacter =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
      newPassword || userCurrentPassword
    );

  // function for change the phone number///////////////////////////////////
  const handleOnChange = (value, data) => {
    // Extract dial code and phone number from the value
    const phoneCodeLength = data?.dialCode?.length;
    const newDialCode = value.slice(0, phoneCodeLength);
    const newPhoneNumber = value.slice(phoneCodeLength);

    setPhone_no(newPhoneNumber);
    setDial_code(newDialCode);
  };

  // function for change the Password Visiblity///////////////////////////////////
  const togglePasswordVisiblity = (data) => {
    if (data == "currentpass") {
      setCurrentPasswordVisibility(!currentPasswordVisibility);
    } else if (data == "oldpass") {
      setPasswordOldVisibility(!passwordOldVisibility);
    } else if (data == "newpass") {
      setPasswordNewVisibility(!passwordNewVisibility);
    } else if (data == "confirmpass") {
      setPasswordConfirmVisibility(!passwordConfirmVisibility);
    }
  };

  // API for change the Password ///////////////////////////////////
  const handleChangePassword = (e) => {
    e.preventDefault();
    if (!oldPassword) {
      showToast("Please enter old password");
      return;
    } else if (!newPassword) {
      showToast("Please enter new password");
      return;
    } else if (!specialCharacter) {
      showToast(
        "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
      );
      return;
    } else if (!confirmPassword) {
      showToast("Please enter confirm password");
      return;
    } else if (confirmPassword != newPassword) {
      showToast("Confirm password not matched");
      return;
    }
    let params = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    dispatch(
      changePassword({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setNewPassword("");
            setOldPassword("");
            setConfirmPassword("");
          }
        },
      })
    );
  };

  // API for change the Email ///////////////////////////////////
  const handleChangeEmail = (e) => {
    e.preventDefault();
    if (!userEmail) {
      showToast("Please enter email");
      return;
    } else if (
      userEmail &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userEmail
      )
    ) {
      showToast("Please enter valid email");
      return;
    } else if (!userCurrentPassword) {
      showToast("Please enter password");
      return;
    } else if (!specialCharacter) {
      showToast(
        "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
      );
      return;
    }
    let params = {
      email: userEmail,
      password: userCurrentPassword,
    };
    dispatch(
      changeEmail({
        ...params,
        cb(res) {
          if (res.status === 200) {
            // setUserEmail("");
            setUserCurrentPassword("");
          }
        },
      })
    );
  };

  // API for change the Profile Photo Upload ///////////////////////////////////
  const handleProfilePhotoUpload = (e) => {
    const imageFile = e?.target?.files[0];
    let params = {
      image: imageFile,
    };
    dispatch(
      uploadImage({
        ...params,
        cb(res) {
          if (res?.data) {
            setImage(res?.data?.data?.profile_photo);
          }
        },
      })
    );
  };

  //onchange input
  const handleChange = (e, fieldname) => {
    const { value, name } = e?.target;
    let filteredValue = value;

    if (fieldname === "noOfActiveClients") {
      // Ensure the value contains only the digits 1, 2, 3, 4, and 5 and is less than 6 characters long
      if (/^[0-9]*$/.test(filteredValue)) {
        setFormData({ ...formData, [name]: filteredValue });
      }
    } else {
      setFormData({ ...formData, [name]: filteredValue });
    }
  };

  // API for change the Profile info update ///////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!image) {
      showToast("Please select image");
      return;
    }
    if (!formData.firstName) {
      showToast("Please enter first name");
      return;
    } else if (!formData.lastName) {
      showToast("Please enter last name");
      return;
    } else if (!formData.location) {
      showToast("Please enter location");
      return;
    } else if (!dial_code) {
      showToast("Please enter dial Code");
      return;
    } else if (!phone_no) {
      showToast("Please enter Phone Number");
      return;
    } else if (phone_no.length > 11 || phone_no.length < 10) {
      showToast("Please enter valid phone number");
      return;
    } else if (!formData.businessName) {
      showToast("Please enter business name");
      return;
    } else if (!formData.noOfActiveClients) {
      showToast("Please enter active client");
      return;
    }

    let params = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      businessName: formData.businessName,
      noOfActiveClients: formData.noOfActiveClients,
      dialCode: dial_code,
      phoneNo: phone_no,
      profilePhoto: image,
      location: formData.location,
    };
    dispatch(
      editUserProfileInfo({
        ...params,
        cb(res) {
          if (res.status) {
          }
        },
      })
    );
  };

  return (
    <>
      <div className="content-wrapper profileSec">
        <h2 className="innerHeadText mt-2 profile_Heading">Profile</h2>

        <div className="row">
          <div className="col-lg-3 my-4">
            <div
              className={
                activeTab == "profileTab" ? "profileLeft active" : "profileLeft"
              }
              onClick={() => {
                setActiveTab("profileTab");
              }}
            >
              <figure className="uploadSec">
                <img
                  src={image ? image : Image?.defaultUser}
                  alt="defaultUser"
                  className="userImg"
                />
                <label className="uploadlabel">
                  <input
                    type="file"
                    id="myFile"
                    name="filename"
                    onChange={(e) => handleProfilePhotoUpload(e)}
                  />

                  <div className="camImgBx">
                    <img
                      src={Image.cameraImg}
                      alt="cameraImg"
                      className="img-fluid"
                    />
                  </div>
                </label>
              </figure>
            </div>
          </div>
          <div className="col-lg-9 my-4 ">
            <div className="profileRight ">
              <div
                className={
                  activeTab == "emailTab"
                    ? "profileInner active"
                    : "profileInner"
                }
                onClick={() => {
                  setActiveTab("emailTab");
                }}
              >
                <button className="DashArrow" type="button">
                  <img
                    src={Image.arrowRight}
                    className="img-fluid dashArrowImg"
                    alt="arrowRight image"
                  />
                </button>
                <h3 className="innerHeadText">Email</h3>
              </div>
              <div
                className={
                  activeTab == "securityTab"
                    ? "profileInner active"
                    : "profileInner"
                }
                onClick={() => {
                  setActiveTab("securityTab");
                }}
              >
                <button className="DashArrow" type="button">
                  <img
                    src={Image.arrowRight}
                    className="img-fluid dashArrowImg"
                    alt="arrowRight image"
                  />
                </button>
                <h3 className="innerHeadText">Security</h3>
              </div>
            </div>
          </div>
        </div>
        {activeTab == "emailTab" ? (
          <>
            <form onSubmit={(e) => handleChangeEmail(e)}>
              <div className="profileInfoSec">
                <h4 className="profileText">Email</h4>
                <div className="profileSubInfo">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="signupField  mt-3">
                        <input
                          type="email"
                          className="form-control nameControl"
                          placeholder="Enter Business Email"
                          name="email"
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <div className="signupField position-relative">
                        <input
                          type={currentPasswordVisibility ? "password" : "text"}
                          className="form-control nameControl "
                          placeholder="Current Password"
                          name="password"
                          value={userCurrentPassword}
                          onChange={(e) =>
                            setUserCurrentPassword(e.target.value)
                          }
                        />
                        <img
                          src={
                            currentPasswordVisibility
                              ? Image.hiddenEye
                              : Image.showEye
                          }
                          alt="showEyeImage"
                          className="img-fluid eyeIcon"
                          onClick={() => {
                            togglePasswordVisiblity("currentpass");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="signUpBtn">
                  Change Email
                </button>
              </div>
            </form>
          </>
        ) : activeTab == "securityTab" ? (
          <>
            <form onSubmit={(e) => handleChangePassword(e)}>
              <div className="profileInfoSec">
                <h4 className="profileText">Password</h4>
                <div className="profileSubInfo">
                  <div className="row">
                    <div className="col-lg-4 ">
                      <div className="signupField position-relative mt-3">
                        <input
                          type={passwordOldVisibility ? "password" : "text"}
                          className="form-control nameControl "
                          placeholder="Old Password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          name="password"
                        />
                        <img
                          src={
                            passwordOldVisibility
                              ? Image.hiddenEye
                              : Image.showEye
                          }
                          alt="showEyeImage"
                          className="img-fluid eyeIcon"
                          onClick={() => {
                            togglePasswordVisiblity("oldpass");
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mt-3">
                      <div className="signupField position-relative">
                        <input
                          type={passwordNewVisibility ? "password" : "text"}
                          className="form-control nameControl "
                          value={newPassword}
                          placeholder="New Password"
                          onChange={(e) => setNewPassword(e.target.value)}
                          name="password"
                        />
                        <img
                          src={
                            passwordNewVisibility
                              ? Image.hiddenEye
                              : Image.showEye
                          }
                          alt="showEyeImage"
                          className="img-fluid eyeIcon"
                          onClick={() => {
                            togglePasswordVisiblity("newpass");
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mt-3">
                      <div className="signupField position-relative">
                        <input
                          type={passwordConfirmVisibility ? "password" : "text"}
                          className="form-control nameControl "
                          value={confirmPassword}
                          placeholder="Confirm Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          name="password"
                        />
                        <img
                          src={
                            passwordConfirmVisibility
                              ? Image.hiddenEye
                              : Image.showEye
                          }
                          alt="showEyeImage"
                          className="img-fluid eyeIcon"
                          onClick={() => {
                            togglePasswordVisiblity("confirmpass");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="signUpBtn">
                  Change Password
                </button>
              </div>
            </form>
          </>
        ) : activeTab == "profileTab" ? (
          <>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="profileInfoSec">
                <h4 className="profileText ">Personal Information</h4>
                <div className="profileSubInfo">
                  <div className="row">
                    <div className="col-lg-6 ">
                      <div className="signupField mt-3">
                        <input
                          type="Text"
                          className="form-control nameControl"
                          placeholder="First Name"
                          maxLength={20}
                          name="firstName"
                          value={formData.firstName}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <div className="signupField">
                        <input
                          type="Text"
                          className="form-control nameControl"
                          placeholder="Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <div className="signupField">
                        <input
                          type="Text"
                          autoComplete="disable"
                          name="location"
                          className="form-control nameControl"
                          placeholder="Enter Location"
                          value={formData.location}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-3   d-flex justify-content-end">
                      <div className="phoneControl">
                        <PhoneInput
                          value={fullPhoneNumber}
                          onChange={handleOnChange}
                          country="us"
                          className="inputTel"
                          placeholder="Enter Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <div className="signupField ">
                        <input
                          type="Text"
                          className="form-control nameControl "
                          placeholder="Enter Business Name"
                          name="businessName"
                          value={formData.businessName}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <div className="signupField">
                        <input
                          type="number"
                          className="form-control nameControl"
                          placeholder="How many active clients you have?"
                          name="noOfActiveClients"
                          value={formData.noOfActiveClients}
                          onChange={(e) => handleChange(e, "noOfActiveClients")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="signUpBtn">
                  Save
                </button>
              </div>
            </form>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Profile;

import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CHILDCOLLECTIONNAME,
  PARENTCOLLECTIONNAME,
  TICKETCHILDCOLLECTIONNAME,
  TICKETCOLLECTIONNAME,
  db,
} from "../../../config/firebase-config";
import { useTicketSelector } from "../../../redux/selector/ticket";
import {
  getOneTickets,
  ticketUploadDocument,
  updateOneTickets,
} from "../../../redux/slices/ticket";
import * as images from "../../../utilities/images";
import { log } from "util";
import { getAccessToken } from "../../../redux/slices/auth";
import { sendMessageNotification } from "../../../redux/slices/notification";

const TicketDetails = () => {
  const messagesEndRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ticketDetails, setTicketDetails] = useState("");
  const ticketSelector = useTicketSelector();
  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState("");
  const [ticketNo, setTicketNo] = useState("");
  const [userInfo, setUserInfo] = useState("");
  console.log(userInfo, "userInfo123");

  const [adminInfo, setAdminInfo] = useState("");
  const { loading } = ticketSelector;
  const [imageUrl, setImgUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [getUserAccessToken, setGetUserAccessToken] = useState("");
  console.log(getUserAccessToken, "getAccessToken");

  /**
   * to change ticket status to completed
   */

  const handleStatusChange = () => {
    let params = {
      id: id,
      status: "completed",
    };
    dispatch(
      updateOneTickets({
        ...params,
        cb(res) {
          const roomDocRef = doc(
            db,
            TICKETCOLLECTIONNAME,
            TICKETCHILDCOLLECTIONNAME
          );
          setDoc(roomDocRef, {
            ticketsStatus: "completed",
            senderId: adminInfo?._id,
            ticketId: ticketNo,
          });
          getOneUserDetail();
        },
      })
    );
  };

  const handleAccessToken = () => {
    dispatch(
      getAccessToken({
        cb(res) {
          if (res?.status === 200) {
            setGetUserAccessToken(res?.data?.data?.accessToken);
          }
        },
      })
    );
  };

  /**
   * to get user details
   */

  const getOneUserDetail = () => {
    let params = {
      id: id,
    };
    dispatch(
      getOneTickets({
        ...params,
        cb(res) {
          if (res?.data) {
            console.log(res?.data, "res?.data");

            setTicketNo(res?.data?.data?.ticketNo);
            setTicketDetails(res?.data?.data);
            setUserInfo(res?.data?.data?.users);
            setAdminInfo(res?.data?.data?.adminDetails);
          }
        },
      })
    );
  };

  const handleSendNotification = () => {
    let params = {
      sender_id: userInfo?._id,
      receiver_id: adminInfo?._id,
      msg: `${msg ? msg : "sent a photo"}`,
      type: "ticketMessage",
      sender_image: userInfo?.profilePhoto,
      sender_name: `${userInfo?.firstName || ""} ${userInfo?.lastName || ""}`,
      notificationTypeId: id,
    };
    dispatch(
      sendMessageNotification({
        ...params,
        cb(res) {
          if (res?.data) {
            // handleSendWebPushNotification()
          }
        },
      })
    );
  };

  // scroll bottom
  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      messagesEndRef?.current?.scrollIntoView({
        block: "end",
        inline: "end",
        behavior: "smooth",
      });
    }
  };

  // const handleSendWebPushNotification = async () => {
  //   console.log('SEND NOTIFICATION IS CALLED');
  //   let fcmToken = userInfo?.fcmToken;

  //   try {
  //     // Prepare the notification payload
  //     const notification = {
  //       message: {
  //         token: fcmToken,
  //         notification: {
  //           title: `${userInfo?.firstName || ""} ${userInfo?.lastName || ""}`,
  //           body: `${msg || "sent a photo"}`,
  //           // profile_image: userInfo?.profilePhoto,
  //         },
  //         // data: {
  //         //   id: `${userInfo?._id}`,
  //         //   type: 'Chat',
  //         // },
  //       },
  //     };

  //     // Send the notification
  //     const response = await fetch(
  //       `https://fcm.googleapis.com/v1/projects/pr-publisher/messages:send`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${getUserAccessToken}`, // Use the access token here
  //         },
  //         body: JSON.stringify(notification),
  //       }
  //     );

  //     const responseData = await response.json();
  //     console.log('Response:', JSON.stringify(responseData));

  //     if (!response.ok) {
  //       throw new Error(responseData.message || "Failed to send notification");
  //     }

  //   } catch (error) {
  //     console.error('Error sending notification:', error.message || error);
  //   }
  // };

  //onDrop AND UPLOAD IMAGE
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      toast.error("Please choose a file");
      return;
    }

    const fileType = acceptedFiles[0].type.split("/")[1]; // Extract file extension
    const allowedTypes = [
      "pdf",
      "svg",
      "jpg",
      "jpeg",
      "png",
      "msword",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    setFileType(fileType);
    if (!allowedTypes.includes(fileType)) {
      toast.error("Please choose only mentioned supported file types");
      return;
    }

    const imageFile = acceptedFiles[0]; // Only taking the first file
    const params = {
      image: imageFile,
    };

    dispatch(
      ticketUploadDocument({
        ...params,
        cb(res) {
          if (res.status) {
            setImgUrl(res?.data?.data?.photo);
          }
        },
      })
    );
  }, []);

  // showing only images
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "application/msword": [],
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/svg": [],
    },
    multiple: false,
  });

  // Convert UTC time to local date and time without seconds

  /**
   * to convert date time format
   * @param {*} milliseconds
   * @returns formatted time
   */
  const convertTimeFormat = (milliseconds) => {
    const timestamp = new Date(milliseconds); // Create Date object from milliseconds
    const now = new Date();
    const timeDifferenceInSeconds = Math.floor((now - timestamp) / 1000);

    if (timeDifferenceInSeconds < 5) {
      return "just now";
    }

    const dateFormatOptions = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };

    const timeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const formattedDate = timestamp.toLocaleDateString(
      "en-US",
      dateFormatOptions
    );
    const formattedTime = timestamp.toLocaleTimeString(
      "en-US",
      timeFormatOptions
    );

    return `${formattedDate}, ${formattedTime}`;
  };

  // get all messages
  useEffect(() => {
    const parentCollectionChat = query(collection(db, PARENTCOLLECTIONNAME));
    const parentCollectionTicket = query(collection(db, TICKETCOLLECTIONNAME));
    setIsChatLoading(true);
    const unsubscribe = onSnapshot(parentCollectionChat, (snap) => {
      const messagesList = snap.docs.map((doc) => {
        const id = doc.id;
        return { id, ...doc.data() };
      });
      const getMyChats = messagesList?.filter((item) => {
        return item?.roomId === ticketDetails?.ticketNo;
      });
      getFireStoreData(getMyChats);
      setIsChatLoading(false);
    });

    const getTickets = onSnapshot(parentCollectionTicket, (snap) => {
      const ticketStatusList = snap.docs.map((doc) => {
        const id = doc.id;
        return { id, ...doc.data() };
      });
      const getMyTicketStatus = ticketStatusList?.find((item) => {
        return item?.ticketId === ticketDetails?.ticketNo;
      });
      if (
        getMyTicketStatus &&
        getMyTicketStatus?.ticketsStatus === "completed"
      ) {
        getOneUserDetail();
      }
    });

    return () => {
      unsubscribe();
      getTickets();
    };
  }, [ticketNo]);

  // get inner messages
  const getFireStoreData = (allChats) => {
    if (ticketNo) {
      let lastDeletedAt;
      if (
        allChats &&
        allChats.length > 0 &&
        allChats[0].deletedChatUserIds &&
        allChats[0].deletedChatUserIds.length > 0
      ) {
        let lastDeletedAts = allChats[0].deletedChatUserIds.filter(
          (item) => item.userId == userInfo?._id
        );
        if (lastDeletedAts.length > 0) {
          lastDeletedAts = lastDeletedAts?.sort(
            (a, b) => b.deletedAt - a.deletedAt
          );
          lastDeletedAt = lastDeletedAts[0].deletedAt;
        }
      }
      const allMessageQuery = query(
        collection(db, PARENTCOLLECTIONNAME, ticketNo, CHILDCOLLECTIONNAME),
        orderBy("createdAt", "asc")
      );

      onSnapshot(allMessageQuery, (snap) => {
        const messagesList = snap.docs.map((doc) => {
          const id = doc.id;
          return { id, ...doc.data() };
        });
        let filteredMessages = messagesList;
        if (messagesList && messagesList.length > 0 && lastDeletedAt) {
          filteredMessages = messagesList?.filter(
            (val) => val?.createdAt > Math.floor(lastDeletedAt)
          );
        }
        const updatedData = filteredMessages?.map((item) => {
          if (item?.imageUrl === "") {
            const { imageUrl, ...rest } = item;
            return rest;
          }
          return item;
        });
        const removeEmptyTextField = updatedData?.map((item) => {
          if (item?.text === "") {
            const { text, ...rest } = item;
            return rest;
          }
          return item;
        });
        setMessages(removeEmptyTextField);
        scrollToBottom();
      });
    }
  };

  /**
   * To update message
   * @param {*} e
   */
  const handleUpdateMessage = async (e) => {
    e.preventDefault();
    if (msg || imageUrl) {
      const roomDocRef = doc(db, PARENTCOLLECTIONNAME, ticketNo);
      const roomDocSnapshot = await getDoc(roomDocRef);
      const previousUnseenMessageCount =
        roomDocSnapshot.data()?.unseenMessageCount || 0;

      if (roomDocSnapshot.exists()) {
        const messagesCollectionRef = collection(
          roomDocRef,
          CHILDCOLLECTIONNAME
        );
        await addDoc(
          messagesCollectionRef,
          {
            createdAt: Math.floor(Date.now()),
            text: msg,
            imageUrl: imageUrl,
            senderId: userInfo?._id,
            recieverId: adminInfo?._id,
          },
          // handleSendNotification(),
          setMsg(""),
          setImgUrl(""),
          scrollToBottom()
        );
        try {
          const roomDocRef = doc(db, PARENTCOLLECTIONNAME, ticketNo);
          await updateDoc(
            roomDocRef,
            {
              clearChat: false,
              lastMessage: {
                createdAt: Math.floor(Date.now()),
                senderId: userInfo?._id,
                text: msg,
                imageUrl: imageUrl,
                recieverId: adminInfo?._id,
              },
              roomId: ticketNo,
              unseenMessageCount: previousUnseenMessageCount + 1,
              user1: {
                full_name: `${userInfo?.firstName || ""} ${
                  userInfo?.lastName || ""
                }`,
                id: userInfo?._id,
                onlineStatus: 1,
              },
              user2: {
                full_name: `${adminInfo?.userInfo?.firstName || ""} ${
                  adminInfo?.userInfo?.lastName || ""
                }`,
                id: adminInfo?._id,
                onlineStatus: 1,
              },
              users: [userInfo?._id, adminInfo?._id],
            },
            handleSendNotification(),
            setMsg(""),
            setImgUrl(""),
            scrollToBottom()
          );
        } catch (error) {
          console.error("Error creating room:", error);
        } finally {
        }
      }
    } else {
    }
  };

  // remove selected image
  const handleRemoveImage = (url) => {
    if (url === imageUrl) {
      setImgUrl("");
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, imageUrl]);

  useEffect(() => {
    getOneUserDetail();
    handleAccessToken();
  }, []);

  return (
    <>
      <div className="content-wrapper TicketDetailSec ticketChat_Panel">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="ps-0 col-sm-6">
                {/* <Link to="/tickets"> */}
                <div
                  className="userDeatilHead d-inline-flex align-items-center pointCursor"
                  onClick={() => navigate(-1)}
                >
                  <img
                    src={images.arrowMain}
                    alt="arrowMainimg"
                    className="img-fluid me-3 pointCursor"
                  />
                  <h1 className="innerHeadText">
                    Ticket: <span> #{ticketDetails?.ticketNo} </span>
                  </h1>
                </div>
                {/* </Link> */}
              </div>
              <div className="ps-0 col-sm-6">
                <div className="userDetailRight ticket_DetailRight">
                  <button
                    className={` ${
                      ticketDetails?.status == "pending"
                        ? " blockbtn pendingTicket"
                        : "completeTkt"
                    }`}
                    type="button"
                    onClick={() => handleStatusChange()}
                    disabled={loading || !(ticketDetails?.status == "pending")}
                  >
                    {ticketDetails?.status == "pending"
                      ? "Mark as Completed"
                      : "Completed"}
                    {loading && (
                      <span className="spinner-border spinner-border-sm ms-1"></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ticketInformation_ mt-3">
          <p className="tickettext_">
            Ticket No.: <span> #{ticketDetails?.ticketNo} </span>
          </p>
          <p className="tickettext_">
            Article: <span> {ticketDetails?.articles} </span>
          </p>
          <p className="tickettext_">
            Title: <span> {ticketDetails?.title} </span>
          </p>
        </div>

        <div className="ticketDetailOuter">
          <div className="chatOuter">
            <div className="chatInner_Parent ticket_ChatInner_Parent">
              {isChatLoading ? (
                <div className="loaderOuter d-flex justify-content-center h-100 align-items-center">
                  <div className="spinner-grow text-light" role="status"></div>
                </div>
              ) : (
                <>
                  {messages?.map((message, index) => {
                    let extension;
                    if (message?.imageUrl) {
                      const parts = message.imageUrl.split(".");
                      extension = parts[parts.length - 1];
                    }
                    return (
                      <>
                        <div
                          key={index}
                          className="chatInner"
                          ref={messagesEndRef}
                        >
                          <div
                            className={
                              userInfo?._id === message?.senderId
                                ? "chatRightOuter"
                                : "chatLeftOuter"
                            }
                          >
                            <div
                              className={
                                userInfo?._id === message?.senderId
                                  ? "firstPersonChat"
                                  : "secondPersonChat"
                              }
                            >
                              <figure className="secondPersonImg">
                                {userInfo?._id === message?.senderId ? (
                                  <img
                                    src={
                                      userInfo?.profilePhoto ||
                                      images.dummyProfile
                                    }
                                    alt="searchImg"
                                    className="img-fluid "
                                  />
                                ) : (
                                  <img
                                    src={
                                      adminInfo?.userInfo?.profilePhoto ||
                                      images.dummyProfile
                                    }
                                    alt="searchImg"
                                    className="img-fluid "
                                  />
                                )}
                              </figure>
                              <div className="personInfo">
                                {userInfo?._id === message?.senderId ? (
                                  <h6 className="userText mt-1">{`${
                                    userInfo?.firstName || ""
                                  } ${userInfo?.lastName || ""}`}</h6>
                                ) : (
                                  <h6 className="userText mt-1">{`${
                                    adminInfo?.userInfo?.firstName || ""
                                  } ${
                                    adminInfo?.userInfo?.lastName || ""
                                  }`}</h6>
                                )}

                                {message?.imageUrl &&
                                  extension !== "pdf" &&
                                  extension !== "doc" &&
                                  extension !== "docx" && (
                                    <div className="message-img mt-2 pointCursor">
                                      <img
                                        alt="upload-img"
                                        src={message.imageUrl}
                                        className="message_Img_Upload"
                                        onClick={() =>
                                          window.open(
                                            message.imageUrl,
                                            "_blank"
                                          )
                                        }
                                      />
                                    </div>
                                  )}

                                {message?.imageUrl && extension === "pdf" && (
                                  <a
                                    href={message.imageUrl}
                                    target="_blank"
                                    className=" pointCursor d-block"
                                  >
                                    <div className="message-img mt-2">
                                      <img
                                        alt="PDF-icon"
                                        src={images.PDFImage}
                                      />
                                    </div>
                                  </a>
                                )}
                                {message?.imageUrl &&
                                  (extension === "doc" ||
                                    extension === "docx") && (
                                    <a
                                      href={message.imageUrl}
                                      target="_blank"
                                      className=" pointCursor d-block"
                                    >
                                      <div className="message-img mt-2">
                                        <img
                                          alt="PDF-icon"
                                          src={images.DOCImage}
                                        />
                                      </div>
                                    </a>
                                  )}

                                {message?.text && (
                                  <div className="messageBox">
                                    {message.text}
                                  </div>
                                )}
                                <p className="timeText text-end m-0 pt-2">
                                  {convertTimeFormat(message?.createdAt)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
              {imageUrl && (
                <aside className="mt-auto w-100">
                  <ul className="uploadedArticle" ref={messagesEndRef}>
                    <div className="uploadImgeboxNewArticle ticket_Detail_Upload">
                      <div className="position-relative uploadArticle_UploadImage">
                        <img
                          className="w3-round uploadfileIcon "
                          src={
                            fileType === "pdf"
                              ? images.PDFImage
                              : fileType ===
                                  "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                fileType === "msword"
                              ? images.DOCImage
                              : imageUrl
                          }
                        />
                        <button
                          className="deleteUpload"
                          onClick={() => handleRemoveImage(imageUrl)}
                        >
                          <img
                            src={images.DeleteIcon}
                            alt="delet image"
                            className="img-fluid deletIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </ul>
                </aside>
              )}
            </div>

            <form onSubmit={(e) => handleUpdateMessage(e)}>
              {ticketDetails?.status == "pending" && (
                <div className="chatBottom">
                  <div className="searchSection w-100 d-flex align-items-center">
                    <input
                      className="form-control searchInput me-2"
                      type="text"
                      placeholder="Type your message here ...."
                      value={msg}
                      onChange={(e) => {
                        setMsg(e.target.value);
                      }}
                    />
                    {!imageUrl && (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img
                          src={images.attachIcon}
                          alt="searchImg"
                          className="img-fluid attachIcon"
                        />
                      </div>
                    )}
                    <button
                      type="submit"
                      className="crossBtnCategory"
                      onClick={(e) => handleUpdateMessage(e)}
                    >
                      <img
                        type="file"
                        src={images.sendIcon}
                        className="img-fluid crossBtnImg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketDetails;

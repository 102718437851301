import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { setGetAllTicket,onErrorStopLoad, setCreateTicket, setUpdateOneTicket, setGetOneTicket, setTicketUploadDocument } from '../../slices/ticket';

const queryString = require('query-string');

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* getAllTickets(action) {
    let data = { ...action.payload }
    delete data.cb
    let query = queryString.stringify(data);
    let url = `${ApiPath.ticketApiPath.GET_ALL_TICKET}?${query}`;
    try {
      const resp = yield call(ApiClient.get, (action.url = url), action.payload = action.payload);
      if (resp.status) {
        yield put(setGetAllTicket(resp.data.data));
        yield call(action.payload.cb, (action.res = resp));
      } else {
        throw resp;
      }
    } catch (e) {
      yield put(onErrorStopLoad());
      toast.error(e.response.data.message);
    }
  }

  function* createTicket(action) {
    let url = `${ApiPath.ticketApiPath.CREATE_TICKET}`;
    try {
      const resp = yield call(ApiClient.post, (action.url = url), action.payload = action.payload);
      if (resp.status) {
        yield put(setCreateTicket(resp.data.data));
        yield call(action.payload.cb, (action.res = resp));
        toast.success(action.res.data.message);
      } else {
        throw resp;
      }
    } catch (e) {
      yield put(onErrorStopLoad());
      toast.error(e.response.data.message);
    }
  }
  function* getOneTickets(action) {
    let url = `${ApiPath.ticketApiPath.GET_ONE_TICKET}/${action.payload.id}`;
    try {
      const resp = yield call(ApiClient.get, (action.url = url), action.payload = action.payload);
      if (resp.status) {
        yield put(setGetOneTicket(resp.data.data));
        yield call(action.payload.cb, (action.res = resp));
      } else {
        throw resp;
      }
    } catch (e) {
      yield put(onErrorStopLoad());
      toast.error(e.response.data.message);
    }
  }
  
  function* updateOneTickets(action) {
    let data = { ...action.payload }
    let url = `${ApiPath.ticketApiPath.UPDATE_TICKET_STATUS}/${data.id}`;
    delete data.id
    try {
      const resp = yield call(ApiClient.put, (action.url = url), action.payload = data);
      if (resp.status) {
        yield put(setUpdateOneTicket(resp.data.data));
        yield call(action.payload.cb, (action.res = resp));
      } else {
        throw resp;
      }
    } catch (e) {
      yield put(onErrorStopLoad());
      toast.error(e.response.data.message);
    }
  }
  function* ticketUploadDocument(action) {
    try {
      const resp = yield call(ApiClient.postFormData, action.url = ApiPath.ticketApiPath.UPLOAD_TICKET_IMAGE, action.payload = action.payload);
      if (resp.status) {
        yield put(setTicketUploadDocument(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
        // toast.success(action.res.data.msg);
      }
      else {
        throw resp
      }
    } catch (e) {
      yield put(setTicketUploadDocument({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.message);
    }
  }

function* ticketSaga() {
  yield all([
    takeLatest('ticket/getAllTickets', getAllTickets),
    takeLatest('ticket/createTicket', createTicket),
    takeLatest("ticket/getOneTickets", getOneTickets),
    takeLatest("ticket/updateOneTickets", updateOneTickets),
    takeLatest('ticket/ticketUploadDocument', ticketUploadDocument),
    
  ])
}

export default ticketSaga;
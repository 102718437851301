import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
  article_image_loader: false,
};

export const homePageSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    sheduleDemoFormApi: (state) => {
      state.loading = true;
    },
    setSheduleDemoForm: (state) => {
      state.loading = false;
    },
    getSchedule: (state) => {
      state.loading = true;
    },
    setGetSchedule: (state) => {
      state.loading = false;
    },
    contactUs: (state) => {
      state.loading = true;
    },
    setContactUs: (state) => {
      state.loading = false;
    },
    newsletter: (state) => {
      state.loading = true;
    },
    setNewsletter: (state) => {
      state.loading = false;
    },
    getHelperpagesInfo: (state) => {
      state.loading = true;
    },
    setGetHelperpagesInfo: (state) => {
      state.loading = false;
    },
    getAllSocialLinks: (state) => {
      state.loading = true;
    },
    setGetAllSocialLinks: (state) => {
      state.loading = false;
    },
    getClientLogos: (state) => {
      state.loading = true;
    },
    setGetClientLogos: (state) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
      state.article_image_loader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  sheduleDemoFormApi,
  setSheduleDemoForm,
  contactUs,
  setContactUs,
  getSchedule,
  setGetSchedule,
  newsletter,
  setNewsletter,
  getHelperpagesInfo,
  setGetHelperpagesInfo,
  getAllSocialLinks,
  setGetAllSocialLinks,
  getClientLogos,
  setGetClientLogos,
  onErrorStopLoad } =
  homePageSlice.actions;

export default homePageSlice.reducer;

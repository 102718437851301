import { Route, Routes } from "react-router-dom";
import * as Containers from "../containers";
import * as Layouts from "../layout";
import PrivateCheck from "../layout/PrivateCheck";
import PublicRoute from "../layout/PublicRoute";

const Router = () => {
  return (
    <>
      <Routes>
        <Route element={<Layouts.MainLayout />}>
          <Route element={<PublicRoute auth={false} />}>
            <Route path="/" element={<Containers.Home />} />
            <Route path="/schedule-demo" element={<Containers.SheduleDemo />} />
            <Route path="/Contact-us" element={<Containers.ContactUs />} />
            {/* <Route
              path="/subscription"
              element={<Containers.Subscriptions />}
            /> */}
            <Route path="/about-us" element={<Containers.AboutUs />} />
            <Route path="/faq" element={<Containers.Faq />} />
            <Route path="/privacy" element={<Containers.Privacy />} />
            <Route
              path="/termsConditions"
              element={<Containers.TermsConditions />}
            />
          </Route>
        </Route>
        <Route element={<Layouts.AuthLayout />}>
          <Route element={<PublicRoute auth={false} />}>
            <Route path="/signup" element={<Containers.Signup />} />
            <Route path="/verification" element={<Containers.Verification />} />
            <Route path="/login" element={<Containers.Login />} />
            <Route
              path="/forgot-password"
              element={<Containers.ForgotPassword />}
            />
            <Route
              path="/reset-password"
              element={<Containers.ResetPassword />}
            />
            <Route
              path="/email-success"
              element={<Containers.EmailSuccessful />}
            />
          </Route>
        </Route>
        <Route element={<Layouts.DashboardLayout />}>
          <Route element={<PrivateCheck auth={true} />}>
            <Route path="/dashboard" element={<Containers.Dashboard />} />
            <Route path="/publication" element={<Containers.Publication />} />
            <Route path="/profile" element={<Containers.Profile />} />
            <Route path="/media" element={<Containers.Media />} />
            <Route path="/drafts" element={<Containers.Draft />} />
            <Route path="/draftDetail" element={<Containers.DraftDetail />} />
            <Route path="/articles" element={<Containers.Articles />} />
            <Route
              path="/articlesCreation"
              element={<Containers.ArticleCreation />}
            />
            <Route path="/tickets" element={<Containers.Ticket />} />
            <Route
              path="/write-article"
              element={<Containers.WriteArticle />}
            />
            <Route
              path="/select-publication"
              element={<Containers.SelectPublication />}
            />
            <Route
              path="/article-details"
              element={<Containers.ArticleDetails />}
            />
            <Route
              path="/article-messages/:id"
              element={<Containers.ArticleMessage />}
            />
            <Route
              path="/articlesDetails/:id"
              element={<Containers.ArticlesDetails />}
            />
            <Route
              path="/Create-Ticket"
              element={<Containers.CreateTicket />}
            />
            <Route
              path="/ticket-details/:id"
              element={<Containers.TicketDetails />}
            />
            <Route path="/notification" element={<Containers.Notification />} />
            <Route path="/transactions" element={<Containers.Transaction />} />
            <Route
              path="/subscriptions"
              element={<Containers.Subscription />}
            />
            <Route
              path="/addOn-Article"
              element={<Containers.AddOnArticle />}
            />
             <Route
              path="/addOn-Article-Detail"
              element={<Containers.AddOnDetail/>}
            />
          </Route>
        </Route>
        <Route element={<Layouts.SubscriptionLayout />}>
          <Route
            path="/subscription"
            element={<Containers.Subscriptions />}
          ></Route>
        </Route>
      </Routes>
    </>
  );
};

export default Router;

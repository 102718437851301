import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ownbuzz from "../components/Images/ownbuzz.png";
import { useHomePageSelector } from "../redux/selector/homePage";
import { getAllSocialLinks } from "../redux/slices/homePage";
import * as images from "../utilities/images";

const Footer = () => {
  const homeSelector = useHomePageSelector();
  const { loading } = homeSelector;
  const dispatch = useDispatch();
  const [getAllSocialList, setAllSocialList] = useState([]);

  useEffect(() => {
    dispatch(
      getAllSocialLinks({
        cb(res) {
          if (res?.status === 200) {
            setAllSocialList(res?.data?.data);
          }
        },
      })
    );
  }, []);
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ownbuzz_detail">
                <img src={ownbuzz} alt="ownbuzz" />
                <p className="grey_h  pt-4">
                  Empowering Agencies to Create, Control, <br />
                  and Amplify the Buzz.
                  <br />
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="footer_nav">
                <h3 className="sub_p_text">Quick Links</h3>

                <ul className="footer_List">
                  <li>
                    <Link to="/schedule-demo" className="grey_h">
                      Schedule A Demo
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" className="grey_h">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact-us" className="grey_h">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq" className="grey_h">
                      FAQs{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" className="grey_h">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/termsConditions" className="grey_h">
                      Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="footer_sociallink">
                <h3 className="sub_p_text">Follow Us</h3>
                <div className="footer_Link">
                  <ul className="footer_List">
                    {loading ? (
                      <tr>
                        <td colSpan={9}>
                          <div className="loaderOuter d-flex justify-content-center text-light">
                            <div
                              className="spinner-grow text-light"
                              role="status"
                            ></div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {getAllSocialList.length > 0 ? (
                          getAllSocialList.map((data, idx) => {
                            return (
                              <>
                                <li key={idx}>
                                  {/* <img src="" /> */}
                                  <Link
                                    to={data?.link}
                                    className="grey_h"
                                    target="_blank"
                                  >
                                    <i className="fa fa-youtube-play text-white"></i>
                                    <img
                                      src={
                                        data?.slug === "facebook"
                                          ? images.facebook
                                          : data?.slug === "x"
                                          ? images.twitter
                                          : data?.slug === "linkedin"
                                          ? images.linkdin
                                          : data?.slug === "instagrm"
                                          ? images.instagram
                                          : data?.slug === "youtube"
                                          ? images.youtube
                                          : ""
                                      }
                                      className="footerIcon"
                                    />{" "}
                                    {data?.name}{" "}
                                  </Link>
                                </li>
                              </>
                            );
                          })
                        ) : (
                          <h5 className="text-center m-0 noFoundText">
                            No Publications Found!
                          </h5>
                        )}
                      </>
                    )}

                    {/* <li>
                      <img src="" />
                      <Link to="" className="grey_h">
                        <img src={images.facebook} className="footerIcon" />{" "}
                        Facebook{" "}
                      </Link>
                    </li>
                    <li>
                      <img src="" />
                      <Link to="" className="grey_h">
                        <img src={images.twitter} className="footerIcon" /> X
                      </Link>
                    </li>
                    <li>
                      <img src="" />
                      <Link to="" className="grey_h">
                        <img src={images.linkdin} className="footerIcon" />{" "}
                        LinkedIn
                      </Link>
                    </li>
                    <li>
                      <img src="" />
                      <Link to="" className="grey_h">
                        <img src={images.instagram} className="footerIcon" />{" "}
                        Instagram
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-light text-center ">
              <div className="copyright">
                <hr color="white" />
                <p className="grey_h text-center footerLight">
                  © OwnBuzz Portal, 2024. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/slices/auth";
import { restAllData } from "../../redux/slices/commonActions";

const LogoutModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // logout handler
  const handleLogout = () => {
    dispatch(
      logout({
        cb(res) {
          if (res.status) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("persist:root");
            dispatch(restAllData())
            navigate("/");
          }
        },
      })
    );
  };
  return (
    <>
      <div className="logoutModalSec text-center">
        <button type="submit" className="signUpBtn my-3" onClick={handleLogout}>
          Yes, Logout
        </button>
      </div>
    </>
  );
};

export default LogoutModal;

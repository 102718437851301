import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { webSlice } from "./web";
import { articleSlice } from "./articles";
import { ticketSlice } from "./ticket";
import { homePageSlice } from "./homePage";
import { notificationSlice } from "./notification";
import { subscriptionSlice } from "./subscription";


export const mainReducer = combineReducers({
    homePage:homePageSlice.reducer,
    auth: authSlice.reducer,
    web: webSlice.reducer,
    article:articleSlice.reducer,
    ticket:ticketSlice.reducer,
    notification:notificationSlice.reducer,
    subscription:subscriptionSlice.reducer
})
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createPaymentCard,
  // createSubscription,
} from "../../redux/slices/subscription";

const CheckoutForm = ({
  paymentFlag,
  handleOpenModal: handlePaymentSuccess,
  paymentToken,
  allCardInfo
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  /**
   *
   * @param {*} id
   */

  const createCardHandle = (id, confirmToken) => {
    let params = {
      token: id,
    };
    dispatch(
      createPaymentCard({
        ...params,
        cb(ress) {
          if(ress?.data?.error){
            setSubscriptionLoading(false)
          }
          else {
            setSubscriptionLoading(false);
            handlePaymentSuccess({ flags: "cardPaymentModal", token: "confirmToken" });
          }
        },
      })
    );
  };

  /**
   *
   * @param {*} event
   * @returns
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    

    if (!stripe || !elements) {
      return;
    }

    setSubscriptionLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { token, error } = await stripe.createToken(cardNumberElement);
    // const confirmToken = await stripe.createToken(cardNumberElement);

    if (error) {

      setSubscriptionLoading(false);
      setPaymentError(error.message);
    } else {
      setSubscriptionLoading(true);
      // if (confirmToken.error) {
      //   console.log("confirm token", confirmToken.error);
      // }
      if (paymentFlag === "articlePayment") {
        createCardHandle(token?.id);
      } else {
        createCardHandle(token?.id);
      }
      setPaymentError(null);
    }
  };

  const elementOptions = {
    style: {
      base: {
        iconColor: "#FFFFFF",
        color: "#fff",
        fontWeight: "500",
        // fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#FFFFFF",
        },
        "::placeholder": {
          color: "#ffffff",
        },
      },
      invalid: {
        iconColor: "#FFFFFF",
        color: "#FFFFFF",
      },
    },
    showIcon: true, // This ensures the card brand icon is shown
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-12 mt-4">
            <CardNumberElement
              options={elementOptions}
              className="cardInput_"
            />
          </div>
          <div className="col-lg-6 mt-4">
            <CardExpiryElement
              options={elementOptions}
              className="cardInput_"
            />
          </div>
          <div className="col-lg-6 mt-4">
            <CardCvcElement options={elementOptions} className="cardInput_" />
          </div>
        </div>

        <div className="text-center">
          <button
            type="submit"
            disabled={subscriptionLoading}
            className="checkout_Pay_Btn mt-3"
          >
            Add Card
            {subscriptionLoading && (
              <span className="spinner-border spinner-border-sm ms-2"></span>
            )}
          </button>
        </div>
        {paymentError && (
          <div className="fontSize18 pt-3 text-center">{paymentError}</div>
        )}
      </form>
    </>
  );
};

export default CheckoutForm;
import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
  allCardInfo: {},
  getSubscriptionInfo:{}
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
    getAllSubscription: (state) => {
      state.loading = true;
    },
    setGetAllSubscription: (state, action) => {
      state.loading = false;
    },
    getTransaction: (state) => {
      state.loading = true;
    },
    setGetTransaction: (state, action) => {
      state.loading = false;
    },
    getArticleTransaction: (state) => {
      state.loading = true;
    },
    setArticleTransaction: (state, action) => {
      state.loading = false;
    },
    createSubscription: (state) => {
      state.loading = true;
    },
    setCreateSubscription: (state, action) => {
      state.loading = false;
    },
    deleteSubscription: (state) => {
      state.loading = true;
    },
    setDeleteSubscription: (state, action) => {
      state.loading = false;
    },
    getSubscriptionInfo: (state) => {
      state.loading = true;
    },
    setGetSubscriptionInfo: (state, action) => {
      state.loading = false;
      state.getSubscriptionInfo = action.payload;
    },
    createPaymentCard: (state) => {
      state.loading = true;
    },
    setCreatePaymentCard: (state, action) => {
      state.loading = false;
    },
    getAllPaymentCards: (state) => {
      state.loading = true;
    },
    setGetAllPaymentCards: (state, action) => {
      state.loading = false;
      state.allCardInfo = action.payload;
    },
    deletePaymentCard: (state) => {
      state.loading = true;
    },
    setDeletePaymentCard: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onErrorStopLoad,
  getAllSubscription,
  setGetAllSubscription,
  createSubscription,
  setCreateSubscription,
  getTransaction,
  setGetTransaction,
  getArticleTransaction,
  setArticleTransaction,
  deleteSubscription,
  setDeleteSubscription,
  getSubscriptionInfo,
  setGetSubscriptionInfo,
  createPaymentCard,
  setCreatePaymentCard,
  getAllPaymentCards,
  setGetAllPaymentCards,
  deletePaymentCard,
  setDeletePaymentCard,
} = subscriptionSlice.actions;

import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllRegions,
  getCategories,
  getPublication,
} from "../../../../redux/slices/web";
import * as images from "../../../../utilities/images";
import CustomModal from "../../../Modal/CustomModal";
import PublicationGenreModal from "../../../Modal/PublicationGenreModal";

const SelectPublication = (
  {
    // handleAddPublication,
    // publicationIds,
  }
) => {
  const location = useLocation();
  const { state } = location;
  console.log(state, "statestatestate");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [showAllCat, setShowAllCat] = useState(false);
  const [showSubCat, setShowSubCat] = useState(false);
  const [clearCategory, setClearCategory] = useState(false);
  const [clearSubCategory, setClearSubCategory] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [publicateInfo, setPublicateInfo] = useState();
  const [region, setRegion] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    publicationType: "premium",
  });
  const [publicationIds, setPublicationIds] = useState(
    state?.selectedPublicationIds || state?.draftData?.publications || []
  );
  const [search, setSearch] = useState("");
  console.log(publicationIds, "publicationIds");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [allCategoryNames, setAllCategoryNames] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  // List of suggestions (replace with actual data)
  const suggestions = publicateInfo || [];

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    // setCurrentPage(1);
    // Filter suggestions based on input
    const filtered = suggestions?.filter(
      (item) =>
        item?.name?.toLowerCase().includes(value.toLowerCase()) ||
        item?.subCategoryName?.toLowerCase().includes(value.toLowerCase()) ||
        (item?.da && item?.da?.toString().includes(value)) ||
        (item?.region && item?.region?.toString().includes(value).toString()) ||
        (item?.websiteUrl &&
          item?.websiteUrl?.toString().includes(value).toLowerCase()) ||
        (item?.selectTime &&
          item?.selectTime?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.niche &&
          item?.niche?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.markup && item?.markup?.toString().includes(value)) ||
        (item?.amount && item?.amount?.toString().includes(value)) ||
        (item?.sponsored &&
          item?.sponsored?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.indexed &&
          item?.indexed?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.doFollow &&
          item?.doFollow?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.rating && item?.rating?.toString().includes(value))
    );
    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion) => {
    // Check if the search term matches the full name or email
    const Name = suggestion?.name;
    const subCategoryName = suggestion?.subCategoryName;
    const Da = suggestion?.da;
    const region = suggestion?.region;
    const websiteUrl = suggestion?.websiteUrl;
    const selectTime = suggestion?.selectTime;
    const niche = suggestion?.niche;
    const markup = suggestion?.markup;
    const amount = suggestion?.amount;
    const sponsored = suggestion?.sponsored;
    const indexed = suggestion?.indexed;
    const doFollow = suggestion?.doFollow;
    const rating = suggestion?.rating;

    // Set the search value based on what the user clicked
    if (Name.toLowerCase().includes(search.toLowerCase())) {
      setSearch(Name);
    } else if (subCategoryName?.toLowerCase().includes(search.toLowerCase())) {
      setSearch(subCategoryName);
    } else if (Da?.toString().includes(search)) {
      setSearch(Number(Da));
    } else if (region?.toString().includes(search)) {
      setSearch(region);
    } else if (websiteUrl?.toLowerCase().includes(search)) {
      setSearch(websiteUrl);
    } else if (selectTime?.toLowerCase().includes(search.toLowerCase())) {
      setSearch(selectTime);
    } else if (niche?.toLowerCase().includes(search)) {
      setSearch(niche);
    } else if (markup?.toString().includes(search)) {
      setSearch(markup);
    } else if (amount?.toString().includes(search)) {
      setSearch(amount);
    } else if (sponsored?.toLowerCase().includes(search)) {
      setSearch(sponsored);
    } else if (indexed?.toLowerCase().includes(search)) {
      setSearch(indexed);
    } else if (doFollow?.toLowerCase().includes(search)) {
      setSearch(doFollow);
    } else if (rating?.toString().includes(search)) {
      setSearch(rating);
    } else {
      setSearch(Name);
    }

    // Clear the suggestions after selection
    setFilteredSuggestions([]);
  };
  //open modal
  const handleOpenModal = (flag, data) => {
    setAllCategoryNames(data);
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const GetAllPublication = () => {
    let params = {
      categoryId: categoryIds,
      subCategoryId: subCategoryIds,
    };
    params = {
      ...params,
      ...selectedFilters,
      search: search || undefined,
    };
    setIsLoading(true);
    dispatch(
      getPublication({
        ...params,
        cb(res) {
          if (res.status) {
            setIsLoading(false);
            setPublicateInfo(res?.data?.data?.data);
          } else {
            setIsLoading(false);
          }
        },
      })
    );
  };

  const handleAddPublication = (val) => {
    console.log(val, "valval");

    let PublicationIds = [...publicationIds];
    console.log(PublicationIds, "PublicationIds");

    if (PublicationIds.some((item) => item._id === val?._id)) {
      PublicationIds = PublicationIds.filter((item) => item._id !== val?._id);
    } else {
      PublicationIds.push({
        _id: val?._id,
        value: val?.name,
        publicationLogo: val?.publicationLogo,
        amount: val?.amount,
      });
    }
    if (location?.state?.type === "articleAI" && PublicationIds?.length > 1) {
      toast.error(
        "You can select only one publication while creating article using AI"
      );
      return;
    }
    setPublicationIds(PublicationIds);

    toast.success(
      `${PublicationIds?.length} ${
        PublicationIds?.length > 1 ? "Items" : "Item"
      } added to your cart`
    );
  };

  const handleSelectPublication = () => {
    if (!publicationIds.length) {
      toast.error("Please select atleast 1 publication");
      return;
    }
    if (state?.type === "articleAI") {
      navigate("/articlesCreation", {
        state: {
          ...state, // Spread the current state
          selectedPublicationIds: publicationIds,
          type: "articleAI",
        },
      });
    } else {
      navigate("/write-article", {
        state: {
          ...state, // Spread the current state
          selectedPublicationIds: publicationIds,
          flag: "SelectedPublication",
        },
      });
    }
  };

  // get all regions
  useEffect(() => {
    dispatch(
      getAllRegions({
        cb(res) {
          if (res.status === 200) {
            handeRemoveDupRegions(res?.data?.data);
          }
        },
      })
    );
  }, []);

  // remove duplicate regions
  const handeRemoveDupRegions = (data) => {
    const allRegion = data?.map(
      (value) => value?.slice(0, 1)?.toUpperCase() + value?.slice(1)
    );
    setRegion([...new Set(allRegion)]);
  };

  // select filters
  const handleSelectFilters = (name, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (prevFilters[name] === value) {
        // If the value is already selected, remove the field from the object
        delete updatedFilters[name];
      } else {
        // Otherwise, update or add the field with the new value
        updatedFilters[name] = value;
      }

      return updatedFilters;
    });
  };

  // get all types and genre
  useEffect(() => {
    dispatch(
      getCategories({
        cb(res) {
          if (res.status === 200) {
            setCategory(
              res?.data?.data?.categories.filter(
                (Items) => Items?.flag === "genre"
              )
            );
            setSubCategory(
              res?.data?.data?.subCategories.filter(
                (Items) => Items?.flag === "type"
              )
            );
          }
        },
      })
    );
  }, []);

  const handlePublication = (val, flag) => {
    if (flag == "categoryFun") {
      setCategoryIds((prevIds) => {
        const index = prevIds.indexOf(val?._id);
        let newIds;
        if (index !== -1) {
          newIds = prevIds.filter((id) => id !== val?._id);
          if (newIds.length === 0) {
            setClearCategory(false);
          }
        } else {
          newIds = [...prevIds, val?._id];
          setClearCategory(true);
        }
        return newIds;
      });
    } else if (flag == "SubcategoryFun") {
      setSubCategoryIds((prevIds) => {
        const index = prevIds.indexOf(val?._id);
        let newIds;
        if (index !== -1) {
          newIds = prevIds.filter((id) => id !== val?._id);
          if (newIds.length === 0) {
            setClearSubCategory(false);
          }
        } else {
          newIds = [...prevIds, val?._id];
          setClearSubCategory(true);
        }
        return newIds;
      });
    }
  };

  const handleViewAll = (flag) => {
    if (flag == "category") {
      setShowAllCat(!showAllCat);
    } else if (flag == "subcategory") {
      setShowSubCat(!showSubCat);
    }
  };

  // reset all filters
  const handleResetAllFilters = () => {
    setSelectedFilters({
      publicationType: "free",
    });
    setCategoryIds([]);
    setSubCategoryIds([]);
  };

  useEffect(() => {
    GetAllPublication();
  }, [selectedFilters, categoryIds, subCategoryIds, search]);

  // useEffect(() => {
  //   if (publicationIds.length > 0) {
  //     toast.success(
  //       `${publicationIds?.length} ${
  //         publicationIds?.length > 1 ? "Items" : "Item"
  //       } added to your cart`
  //     );
  //   }
  // }, [publicationIds]);

  return (
    <>
      <div className="content-wrapper writeArticleSec">
        <div className="content-header">
          <div className="CartItemBox">
            Your Cart has {publicationIds?.length}{" "}
            {publicationIds?.length > 1 ? "Items" : "Item"} added
          </div>
          <div className="dashPublishSec">
            <div className="searchBox">
              <input
                className="form-control searchInput"
                type="text"
                placeholder="Search..."
                value={search || ""}
                onChange={handleSearchChange}
              />
              <img
                src={images.searchIcn}
                className="img-fluid searchIcnImg"
                alt="searchIcn image"
              />
              {search && filteredSuggestions.length > 0 && (
                <ul className="autocomplete-list pointCursor">
                  {filteredSuggestions.map((suggestion) => {
                    const Name = suggestion?.name;
                    const subCategoryName = suggestion?.subCategoryName;
                    const Da = suggestion?.da;
                    const region = suggestion?.region;
                    const websiteUrl = suggestion?.websiteUrl;
                    const selectTime = suggestion?.selectTime;
                    const niche = suggestion?.niche;
                    const markup = suggestion?.markup;
                    const amount = suggestion?.amount;
                    const sponsored = suggestion?.sponsored;
                    const indexed = suggestion?.indexed;
                    const doFollow = suggestion?.doFollow;
                    const rating = suggestion?.rating;
                    // Determine whether to show the name or email based on the search input
                    const searchValue = search.toLowerCase();
                    const displayValue = Name.toLowerCase().includes(
                      searchValue
                    )
                      ? Name
                      : subCategoryName &&
                        subCategoryName.toLowerCase().includes(searchValue)
                      ? subCategoryName
                      : Da && Da?.toString().includes(searchValue)
                      ? Da
                      : region && region?.toString().includes(searchValue)
                      ? region
                      : websiteUrl &&
                        websiteUrl?.toLowerCase().includes(searchValue)
                      ? websiteUrl
                      : selectTime &&
                        selectTime?.toLowerCase().includes(searchValue)
                      ? selectTime
                      : niche && niche?.toLowerCase().includes(searchValue)
                      ? niche
                      : markup && markup?.toString().includes(searchValue)
                      ? markup
                      : amount && amount?.toString().includes(searchValue)
                      ? amount
                      : sponsored &&
                        sponsored?.toLowerCase().includes(searchValue)
                      ? sponsored
                      : indexed && indexed?.toLowerCase().includes(searchValue)
                      ? indexed
                      : doFollow &&
                        doFollow?.toLowerCase().includes(searchValue)
                      ? doFollow
                      : rating && rating?.toString().includes(searchValue)
                      ? rating
                      : ""; // Default to full name if neither matches
                    return (
                      <li
                        key={suggestion._id}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="autocomplete-item"
                      >
                        {displayValue}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>

            <div className="categoryBox">
              <div className="row">
                {selectedFilters?.publicationType === "premium" && (
                  <div className="col-lg-6 col-md-6 col-12">
                    <h4 className="categoryText mb-2">Sort By</h4>
                    <FormControl>
                      <Select
                        className="customSelect"
                        value={selectedFilters.sortBy || ""}
                        onChange={(e) => {
                          handleSelectFilters("sortBy", e.target.value);
                        }}
                        label="Category"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          fontFamily: "Poppins",
                          ".MuiSvgIcon-root": {
                            color: "#fff",
                          },
                          fontStyle: "normal",
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              borderRadius: "12px",
                              border: "1px solid #FFFFFF4D",
                              background: "#616161",
                              color: "#ffffff",
                              fontSize: "16px",
                              fontWeight: "500",
                              marginTop: "5px",
                              " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                                {
                                  color: "#ffffff",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  fontFamily: "inherit",
                                  marginTop: "3px",
                                  "&.Mui-selected": {
                                    backgroundColor: "#757575",
                                  },
                                  "&:hover": {
                                    backgroundColor: "#757575",
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: "#757575",
                                  },
                                },
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value={""}>
                          Price (Asc)
                        </MenuItem>
                        <MenuItem value={"asc"}>{"Low To High"}</MenuItem>
                        <MenuItem value={"desc"}>{"High To Low"}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div
                  className={
                    selectedFilters?.publicationType === "premium"
                      ? "col-lg-6 col-md-6 col-12"
                      : "col-12"
                  }
                >
                  <h4 className="categoryText mb-2 mt-3 mt-md-0">
                    Select Region
                  </h4>
                  <FormControl>
                    <Select
                      className="customSelect"
                      value={selectedFilters.region || ""}
                      onChange={(e) => {
                        handleSelectFilters("region", e.target.value);
                      }}
                      label="Category"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        fontFamily: "Poppins",
                        ".MuiSvgIcon-root": {
                          color: "#fff",
                        },
                        fontStyle: "normal",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "12px",
                            border: "1px solid #FFFFFF4D",
                            background: "#616161",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                              {
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "inherit",
                                marginTop: "3px",
                                "&.Mui-selected": {
                                  backgroundColor: "#757575",
                                },
                                "&:hover": {
                                  backgroundColor: "#757575",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "#757575",
                                },
                              },
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value={""}>
                        Select Region
                      </MenuItem>
                      {region?.map((item, index) => (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {/* {selectedFilters?.publicationType === "premium" && (
                  <div className="categoryBox">
                    <div className="col-12 px-3">
                      <h4 className="categoryText">Price Range</h4>
                      <Slider
                        min={0}
                        max={10000}
                        className="custom-slider"
                        value={selectedFilters?.amount || 0}
                        onChange={(value) =>
                          handleSelectFilters("amount", value)
                        }
                      />
                      <div className="range-prices">
                        <span className="min-range-price">
                          {USDollar.format(selectedFilters?.amount || 0)}
                        </span>
                        <span className="max-range-price">
                          {USDollar.format(10000)}
                        </span>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            {category?.length > 0 && (
              <div className="categoryBox">
                <div className="flexBox">
                  <h4 className="categoryText">Filter by Genre</h4>
                  {clearCategory ? (
                    <button
                      type="button"
                      className="clearBtn"
                      onClick={() => {
                        setCategoryIds([]);
                        setClearCategory(false);
                      }}
                    >
                      {" "}
                      Clear All
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="categoryOuterBox">
                  {category
                    ?.slice(0, showAllCat ? category.length : 11)
                    .map((val, index) => {
                      return (
                        <div
                          className={`categoryBx ${
                            categoryIds.includes(val._id) ? "active" : ""
                          }`}
                          key={index}
                          onClick={() => {
                            handlePublication(val, "categoryFun");
                          }}
                        >
                          {val?.name}
                        </div>
                      );
                    })}
                  {category.length > 11 && (
                    <button
                      type="button"
                      className="clearBtn"
                      onClick={() => handleViewAll("category")}
                    >
                      {showAllCat ? "View Less" : "View All ....."}
                    </button>
                  )}
                </div>
              </div>
            )}

            {subCategory?.length > 0 && (
              <div className="categoryBox">
                <div className="flexBox">
                  <h4 className="categoryText">Filter by Type</h4>
                  {clearSubCategory ? (
                    <button
                      type="button"
                      className="clearBtn"
                      onClick={() => {
                        setSubCategoryIds([]);
                        setClearSubCategory(false);
                      }}
                    >
                      {" "}
                      Clear All
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="categoryOuterBox">
                  {subCategory
                    ?.slice(0, showSubCat ? subCategory.length : 25)
                    .map((val, index) => (
                      <div
                        className={`categoryBx ${
                          subCategoryIds.includes(val._id) ? "active" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          handlePublication(val, "SubcategoryFun");
                        }}
                      >
                        {val?.name}
                      </div>
                    ))}
                  {subCategory.length > 15 && (
                    <button
                      type="button"
                      className="clearBtn"
                      onClick={() => handleViewAll("subcategory")}
                    >
                      {showSubCat ? "View Less" : "View All ....."}
                    </button>
                  )}
                </div>
              </div>
            )}

            <div className="categoryBox">
              <h4 className="categoryText">Image</h4>
              <div className="categoryOuterBox">
                <div
                  onClick={() => handleSelectFilters("image", "yes")}
                  className={
                    selectedFilters?.image === "yes"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Yes
                </div>
                <div
                  onClick={() => handleSelectFilters("image", "maybe")}
                  className={
                    selectedFilters?.image === "maybe"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Maybe
                </div>
              </div>
            </div>

            <div className="categoryBox">
              <h4 className="categoryText">Do Follow</h4>
              <div className="categoryOuterBox">
                <div
                  onClick={() => handleSelectFilters("doFollow", "yes")}
                  className={
                    selectedFilters?.doFollow === "yes"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Yes
                </div>
                <div
                  onClick={() => handleSelectFilters("doFollow", "no")}
                  className={
                    selectedFilters?.doFollow === "no"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  No
                </div>
              </div>
            </div>

            <div className="categoryBox">
              <h4 className="categoryText">Indexed</h4>
              <div className="categoryOuterBox">
                <div
                  onClick={() => handleSelectFilters("indexed", "yes")}
                  className={
                    selectedFilters?.indexed === "yes"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Yes
                </div>
                <div
                  onClick={() => handleSelectFilters("indexed", "maybe")}
                  className={
                    selectedFilters?.indexed === "maybe"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Maybe
                </div>
                <div
                  onClick={() => handleSelectFilters("indexed", "no")}
                  className={
                    selectedFilters?.indexed === "no"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  No
                </div>
              </div>
            </div>

            <div className="categoryBox">
              <h4 className="categoryText">Filter by Sponsored</h4>
              <div className="categoryOuterBox">
                <div
                  onClick={() => handleSelectFilters("sponsored", "yes")}
                  className={
                    selectedFilters?.sponsored === "yes"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Yes
                </div>
                <div
                  onClick={() => handleSelectFilters("sponsored", "no")}
                  className={
                    selectedFilters?.sponsored === "no"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  No
                </div>
                <div
                  onClick={() => handleSelectFilters("sponsored", "discrete")}
                  className={
                    selectedFilters?.sponsored === "discrete"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Discrete
                </div>
              </div>
            </div>

            <div className="categoryBox">
              <h4 className="categoryText">Niche</h4>
              <div className="categoryOuterBox">
                <div
                  onClick={() => handleSelectFilters("niche", "erotice")}
                  className={
                    selectedFilters?.niche === "erotice"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Erotic
                </div>
                <div
                  onClick={() => handleSelectFilters("niche", "health")}
                  className={
                    selectedFilters?.niche === "health"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Health
                </div>
                <div
                  onClick={() => handleSelectFilters("niche", "crypto")}
                  className={
                    selectedFilters?.niche === "crypto"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Crypto
                </div>
                <div
                  onClick={() => handleSelectFilters("niche", "cbd")}
                  className={
                    selectedFilters?.niche === "cbd"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Cbd
                </div>
                <div
                  onClick={() => handleSelectFilters("niche", "gambling")}
                  className={
                    selectedFilters?.niche === "gambling"
                      ? "categoryBx active"
                      : "categoryBx"
                  }
                >
                  Gambling
                </div>
              </div>
            </div>

            <div className="categoryBox">
              <button
                onClick={handleResetAllFilters}
                type="button"
                className="clearBtn"
              >
                Reset All Filters
              </button>
            </div>

            <div className="priceInput mt-3 mb-3 publication_Premium">
              <div>
                <input
                  type="radio"
                  id="Free"
                  name="radio-group1"
                  checked={selectedFilters?.publicationType === "free"}
                  onChange={() =>
                    handleSelectFilters("publicationType", "free")
                  }
                />
                <label htmlFor="Free" className="premiumLabel free text-white">
                  Free
                </label>
              </div>
              <div className="premiumLabel_Parent">
                <input
                  type="radio"
                  id="Premium"
                  name="radio-group1"
                  checked={selectedFilters?.publicationType === "premium"}
                  onChange={() =>
                    handleSelectFilters("publicationType", "premium")
                  }
                />
                <label htmlFor="Premium" className="premiumLabel text-white">
                  Premium
                </label>
              </div>
            </div>
            <div className="table-responsive recentTable publishTable">
              <table className="table table-dark m-0">
                <thead>
                  <tr className="recentRow">
                    <th className="recentHead">NAME</th>
                    {selectedFilters?.publicationType === "premium" && (
                      <th className="recentHead">Price For Premium</th>
                    )}
                    <th className="recentHead">GENRE</th>
                    <th className="recentHead">TYPE</th>
                    <th className="recentHead">DA</th>
                    <th className="recentHead">REGION</th>
                    <th className="recentHead">WEBSITE URL</th>
                    <th className="recentHead">Time</th>
                    <th className="recentHead">Sponsored</th>
                    <th className="recentHead">Indexed</th>
                    <th className="recentHead">Niche</th>
                    <th className="recentHead">Do Follow</th>
                    <th className="recentHead">Image</th>
                    <th className="recentHead">Rating</th>
                    <th className="recentHead">Action</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {isLoading ? (
                    <tr>
                      <td colSpan={16}>
                        <div className="loaderOuter d-flex justify-content-center text-light">
                          <div
                            className="spinner-grow text-light"
                            role="status"
                          ></div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {publicateInfo?.length > 0 ? (
                        publicateInfo?.map((val, index) => (
                          <tr key={index}>
                            <td className="recentSubHead align-middle">
                              <div className="namePublish">
                                <figure className="publishOuter">
                                  <img
                                    src={val?.publicationLogo}
                                    className="img-fluid publishTableImg"
                                    alt="searchIcn image"
                                  />
                                </figure>
                                <h6 className="tableSubText">{val?.name}</h6>
                              </div>
                            </td>
                            {selectedFilters?.publicationType === "premium" && (
                              <td className="recentSubHead align-middle">
                                <h6
                                  className="tableSubText pointCursor"
                                  title={
                                    val?.amount?.toString().length > 5
                                      ? `Price: $${val?.amount}`
                                      : ""
                                  }
                                >{`${
                                  val?.amount?.toString().length > 5
                                    ? `$${val?.amount
                                        ?.toString()
                                        .slice(0, 5)}...`
                                    : `$${val?.amount}`
                                }`}</h6>
                              </td>
                            )}
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                                {val?.categoryIds?.length === 1 ? (
                                  <h4 className="tableSubText">
                                    {val?.categoryIds[0]?.categoryName || "---"}
                                  </h4>
                                ) : (
                                  <p className="tableSubText">
                                    {`${val?.categoryIds[0]?.categoryName}`}
                                    &nbsp;&nbsp;
                                    <span
                                      onClick={() => {
                                        handleOpenModal(
                                          "publicationGenreModal",
                                          val?.categoryIds
                                        );
                                      }}
                                      className="view-all-genres pointCursor"
                                    >
                                      +{val?.categoryIds?.length - 1}
                                    </span>
                                  </p>
                                )}
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                                {val?.subCategoryName || "-----"}
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.da}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                                {val?.region || "-----"}
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle pointCursor">
                              <h6 className="tableSubText">
                                <a
                                  className="form_subheading"
                                  href={val?.websiteUrl}
                                  target="_blank"
                                >
                                  {val?.websiteUrl
                                    ? val?.websiteUrl?.length <= 20
                                      ? val?.websiteUrl
                                      : `${val?.websiteUrl?.slice(0, 20)}...`
                                    : "-----"}
                                </a>
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                                {val?.selectTime}
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.sponsored}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.indexed}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.niche}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.doFollow}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.image}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <div className="ratingBx">
                                <img
                                  src={images.starImg}
                                  alt="starImage"
                                  className="img-fluid"
                                />
                                <h6 className="tableSubText">{val?.rating}</h6>
                              </div>
                            </td>
                            <td className="recentSubHead align-middle">
                              <button
                                className={`publication ${
                                  publicationIds?.some(
                                    (item) => item._id === val._id
                                  )
                                    ? "active"
                                    : ""
                                }`}
                                style={{
                                  cursor:
                                    state?.type === "rejected"
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onClick={() => {
                                  if (state?.type !== "rejected") {
                                    handleAddPublication(val);
                                  }
                                }}
                                disabled={state?.type === "rejected"}
                              >
                                {publicationIds.some(
                                  (item) => item._id === val._id
                                )
                                  ? "Added"
                                  : "Add"}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={20} className="noFoundHead">
                            <p className="text-center noFoundText">
                              No Publication Found
                            </p>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {/* <button
              className="signUpBtn  me-3 mt-5"
              type="button"
              onClick={() => navigate(-1)}
            >
              Back
            </button> */}

            <button
              className="signUpBtn me-3 mt-5"
              onClick={handleSelectPublication}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "publicationGenreModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "publicationGenreModal"
            ? "deletCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "publicationGenreModal" ? (
            <PublicationGenreModal
              allCategoryNames={allCategoryNames}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "publicationGenreModal" ? (
            <>
              <h2 className="modal_Heading">Publication Genres</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default SelectPublication;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as images from "../../src/utilities/images";
import CustomModal from "../components/Modal/CustomModal";
import DeleteAccount from "../components/Modal/deleteAccount";
import LogoutModal from "../components/Modal/logoutModal";

const Sidebar = (props) => {
  const location = useLocation()
  const [activeSidebar, setActiveSidebar] = useState(false);
  const pathName = location.pathname;
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  // useEffect(() => {
  //   if (userId) {
  //     getUser(userId)
  //   }
  // }, [userId])

  return (
    <>
      {/* className={activeSidebar ? "mainSidebar hide" : " mainSidebar full"} */}
      <aside
        className={
          props?.hideShowDetails ? "mainSidebar full" : "mainSidebar hide"
        }
      >
        <Link to="/dashboard" className="brandLink">
          <img
            src={activeSidebar ? images.shortLogo : images.sidebarLogo}
            className="img-fluid"
            alt="Brand Logo"
          />
        </Link>
        {/* <div
          onClick={() => setActiveSidebar((prev) => !prev)}
          className="ToggleIcon"
        >
          <img
            src={images.arrowDark}
            alt="image"
            className="img-fluid sideToggle"
          />
        </div> */}
        <div className="sidebarOuter">
          <div className="sidebarInner">
            <h4 className="sideHeading">ACTIVITY</h4>
            <ul className="sideListHead">
              <li className="sideListSub">
                <Link
                  to="/dashboard"
                  className={
                    [
                      "/dashboard",
                      "/publication",
                      "/articlesCreation",
                      "/write-article",
                      "/select-publication"
                    ].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                >
                  <figure className="sideImg">
                    <img
                      src={images.dashImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Dashboard</h6>
                </Link>
              </li>
              <li className="sideListSub">
                <Link
                  to="/articles"
                  className={
                    ["/articles", "/articlesDetails","/article-details","/addOn-Article","/addOn-Article-Detail"].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                >
                  <figure className="sideImg">
                    <img
                      src={images.articleImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Articles</h6>
                </Link>
              </li>
              <li className="sideListSub">
                <Link
                  to="/drafts"
                  className={
                    ["/drafts", "/draftDetail"].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                >
                  <figure className="sideImg">
                    <img
                      src={images.draftImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Draft</h6>
                </Link>
              </li>
              <li className="sideListSub">
                <Link
                  to="/media"
                  className={
                    ["/media"].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                >
                  <figure className="sideImg">
                    <img
                      src={images.mediaImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Media</h6>
                </Link>
              </li>
              <li className="sideListSub">
                <Link
                  to="/tickets"
                  className={
                    ["/tickets"].includes(pathName) ||
                    pathName.startsWith("/ticket-details")
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                >
                  <figure className="sideImg">
                    <img
                      src={images.ticketImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">tickets</h6>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sidebarInner">
            <h4 className="sideHeading">BILLING</h4>
            <ul className="sideListHead">
              <li className="sideListSub">
                <Link
                  to="/subscriptions"
                  className={
                    ["/subscriptions","/subscription"].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                >
                  <figure className="sideImg">
                    <img
                      src={images.subscriptImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Subscription</h6>
                </Link>
              </li>
              <li className="sideListSub">
                <Link
                  to="/transactions"
                  className={
                    ["/transactions"].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                >
                  <figure className="sideImg">
                    <img
                      src={images.transactImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Transactions</h6>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sidebarInner">
            <h4 className="sideHeading">GENEARL</h4>
            <ul className="sideListHead">
              <li className="sideListSub">
                <Link
                  to="/notification"
                  className={
                    ["/notification"].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                >
                  <figure className="sideImg">
                    <img
                      src={images.notifyLogo}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Notifications</h6>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sidebarInner">
            <ul className="sideListHead">
              <li className="sideListSub">
                <Link
                  to="#"
                  className={
                    ["#"].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                  onClick={() => {
                    handleOpenModal("logoutModal");
                  }}
                >
                  <figure className="sideImg">
                    <img
                      src={images.logoutImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Logout</h6>
                </Link>
              </li>
              <li className="sideListSub">
                <Link
                  to="#"
                  className={
                    ["#"].includes(pathName)
                      ? "sidebarLink active"
                      : "sidebarLink"
                  }
                  onClick={() => {
                    handleOpenModal("deleteModal");
                  }}
                >
                  <figure className="sideImg">
                    <img
                      src={images.deleteSideImg}
                      className="img-fluid sideLogoImg"
                      alt="dashImg image"
                    />
                  </figure>
                  <h6 className="sideLinkText">Delete Account</h6>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </aside>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "logoutModal"
            ? "deletCategoryModal"
            : modalDetail.flag === "deleteModal"
            ? "deletCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "logoutModal" ? (
            <LogoutModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "deleteModal" ? (
            <DeleteAccount close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "logoutModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to logout?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "deleteModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to Delete Account?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Sidebar;

// import React, { useState } from "react";
// import { Link, useLocation } from "react-router-dom";
// import * as images from "../../src/utilities/images";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { useAuthSelector } from "../redux/selector/auth";
// import CustomModal from "../components/Modal/CustomModal"
// import LogoutModal from "../components/Modal/logoutModal";
// import DeleteAccount from "../components/Modal/deleteAccount";
// import Drawer from '@mui/material/Drawer';
// import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import MenuIcon from '@mui/icons-material/Menu';

// const Sidebar = (props) => {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const authSelector = useAuthSelector();
//   const userInfo = authSelector?.userInfo?.userInfo;
//   const pathName = window.location.pathname;
//   const [key, setKey] = useState(Math.random());
//   const [modalDetail, setModalDetail] = useState({
//     show: false,
//     title: "",
//     flag: "",
//   });

//   const handleOnCloseModal = () => {
//     setModalDetail({
//       show: false,
//       title: "",
//       flag: "",
//     });
//     setKey(Math.random());
//   };

//   const handleOpenModal = (flag) => {
//     setModalDetail({
//       show: true,
//       flag: flag,
//       type: flag,
//     });
//     setKey(Math.random());
//   };

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   return (
//     <>
//       <IconButton
//         color="inherit"
//         aria-label="open drawer"
//         edge="start"
//         onClick={toggleDrawer(true)}
//       >
//         <MenuIcon />
//       </IconButton>
//       <Drawer
//         anchor="left"
//         open={drawerOpen}
//         onClose={toggleDrawer(false)}
//       >
//         <aside className="mainSidebar full">
//           <Link to="/dashboard" className="brandLink">
//             <img
//               src={images.sidebarLogo}
//               className="img-fluid"
//               alt="Brand Logo"
//             />
//           </Link>
//           <div className="sidebarOuter">
//             <div className="sidebarInner">
//               <h4 className="sideHeading">ACTIVITY</h4>
//               <List>
//                 <ListItem button component={Link} to="/dashboard" selected={["/dashboard", "/publication", "/articlesCreation", "/write-article"].includes(pathName)}>
//                   <ListItemIcon>
//                     <img src={images.dashImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Dashboard" />
//                 </ListItem>
//                 <ListItem button component={Link} to="/articles" selected={["/articles", "/articlesDetails"].includes(pathName)}>
//                   <ListItemIcon>
//                     <img src={images.articleImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Articles" />
//                 </ListItem>
//                 <ListItem button component={Link} to="/drafts" selected={["/drafts", "/draftDetail"].includes(pathName)}>
//                   <ListItemIcon>
//                     <img src={images.draftImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Draft" />
//                 </ListItem>
//                 <ListItem button component={Link} to="/media" selected={["/media"].includes(pathName)}>
//                   <ListItemIcon>
//                     <img src={images.mediaImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Media" />
//                 </ListItem>
//                 <ListItem button component={Link} to="/tickets" selected={["/tickets"].includes(pathName) || pathName.startsWith("/ticket-details")}>
//                   <ListItemIcon>
//                     <img src={images.ticketImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Tickets" />
//                 </ListItem>
//               </List>
//             </div>
//             <div className="sidebarInner">
//               <h4 className="sideHeading">BILLING</h4>
//               <List>
//                 <ListItem button component={Link} to="/subscriptions" selected={["/subscriptions"].includes(pathName)}>
//                   <ListItemIcon>
//                     <img src={images.subscriptImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Subscription" />
//                 </ListItem>
//                 <ListItem button component={Link} to="/transactions" selected={["/transactions"].includes(pathName)}>
//                   <ListItemIcon>
//                     <img src={images.transactImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Transactions" />
//                 </ListItem>
//               </List>
//             </div>
//             <div className="sidebarInner">
//               <List>
//                 <ListItem button onClick={() => handleOpenModal("logoutModal")}>
//                   <ListItemIcon>
//                     <img src={images.logoutImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Logout" />
//                 </ListItem>
//                 <ListItem button onClick={() => handleOpenModal("deleteModal")}>
//                   <ListItemIcon>
//                     <img src={images.deleteSideImg} className="img-fluid sideLogoImg" alt="dashImg image" />
//                   </ListItemIcon>
//                   <ListItemText primary="Delete Account" />
//                 </ListItem>
//               </List>
//             </div>
//           </div>
//         </aside>
//       </Drawer>
//       <CustomModal
//         key={key}
//         show={modalDetail.show}
//         backdrop="static"
//         showCloseBtn={false}
//         isRightSideModal={true}
//         mediumWidth={false}
//         className={
//           modalDetail.flag === "createcategory" ||
//             modalDetail.flag === "updateCategoryModal"
//             ? "commonWidth customContent"
//             : ""
//         }
//         ids={
//           modalDetail.flag === "logoutModal" ? "deletCategoryModal" : modalDetail.flag === "deleteModal" ? "deletCategoryModal" : ""
//         }
//         child={
//           modalDetail.flag === "logoutModal" ? (
//             <LogoutModal close={() => handleOnCloseModal()} />
//           ) : modalDetail.flag === "deleteModal" ? (
//             <DeleteAccount close={() => handleOnCloseModal()} />
//           ) : ""
//         }
//         header={
//           modalDetail.flag === "logoutModal" ? (
//             <>
//               <h2 className="modal_Heading">Are you sure you want to logout?</h2>
//               <p onClick={handleOnCloseModal} className="modal_cancel">
//                 <img src={images.modalCross} className="ModalCance" alt="" />
//               </p>
//             </>
//           ) : modalDetail.flag === "deleteModal" ? (
//             <>
//               <h2 className="modal_Heading">Are you sure you want to Delete Account?</h2>
//               <p onClick={handleOnCloseModal} className="modal_cancel">
//                 <img src={images.modalCross} className="ModalCance" alt="" />
//               </p>
//             </>
//           ) : ""
//         }
//         onCloseModal={() => handleOnCloseModal()}
//       />
//     </>
//   );
// };

// export default Sidebar;

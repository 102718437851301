import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSubscriptionSelector } from "../../../redux/selector/subscription";
import * as images from "../../../utilities/images";
import {
  getArticleTransaction,
  getTransaction,
} from "../../../redux/slices/subscription";
import { useNavigate } from "react-router-dom";

const Transaction = () => {
  document.title = "Transactions";
  let limit = 5;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transactSelector = useSubscriptionSelector();
  const [transList, setTransList] = useState([]);
  const { loading } = transactSelector;
  const [transactionType, setTransactionType] = useState("Publisher");
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  

// Function to merge additional data
const mergeAdditionalData = (transactions, userProfile) => {
  return transactions.map(transaction => {
    // Merge or modify transaction data with userProfile if needed
    return {
      ...transaction,
      userProfile: userProfile, // Add additional data to each transaction
    };
  });
};

 // Function to handle search input changes
 const handleSearchChange = (e) => {
  const value = e.target.value;
  setSearch(value);
};

  /**
   * to get all transctions list
   * @param {*} id
   * @param {*} flag
   */

  const allTransaction = () => {
    dispatch(
      getTransaction({
        cb(res) {
          if (res.status) {
            const transactions = res?.data?.data?.data || [];
          
          // Assume additional data needs to be merged
          const mergedData = mergeAdditionalData(transactions, res?.data?.data?.userProfile);
            setFilteredData(mergedData?.slice(0, limit));
            setTransList(mergedData);
            setTotalPage(Math.ceil(transactions?.length / limit));
          }
        },
      })
    );
  };

  const getAllArticleTransaction = () => {
    dispatch(
      getArticleTransaction({
        cb(res) {
          if (res.status) {
               const transactions = res?.data?.data?.articleTransactions|| [];
          
          // Assume additional data needs to be merged
          const mergedData = mergeAdditionalData(transactions, res?.data?.data?.user);
            setFilteredData(mergedData?.slice(0, limit));
            setTransList(mergedData);
            setTotalPage(Math.ceil(transactions?.length / limit));
          }
        },
      })
    );
  };

  /**
   * to convert time format
   * @param {*} seconds
   * @returns
   */

  const convertTimeFormat = (seconds) => {
    // Multiply by 1000 to convert seconds to milliseconds
    const milliseconds = seconds * 1000;
    const timestamp = new Date(milliseconds);

    // Define the date format options
    const dateFormatOptions = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };

    // Define the time format options without seconds
    const timeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 12-hour format
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Use the local time zone
    };

    // Format the date and time using the specified options
    const formattedDate = timestamp.toLocaleDateString(
      "en-US",
      dateFormatOptions
    );
    const formattedTime = timestamp.toLocaleTimeString(
      "en-US",
      timeFormatOptions
    );

    // Return the formatted date and time
    return `${formattedDate}, ${formattedTime}`;
  };

  /**
   * Page Change Transction list
   * @param {*} e
   * @returns
   */

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage + 1);
    
    const startIndex = selectedPage * limit;
    const endIndex = startIndex + limit;
    
    setFilteredData(transList.slice(startIndex, endIndex));
  };

  useEffect(() => {
    // Filter the data based on search term
    const lowerCaseSearch = search.toLowerCase();
  
    const filtered = transList?.filter((transaction) => {
      console.log(transaction,"transaction");
      
      const idMatch = transaction?.id.toLowerCase().includes(lowerCaseSearch);
      const nameMatch = transaction.userProfile && 
        (`${transaction?.userProfile?.userId?.userInfo?.firstName || transaction?.user?.userId?.userInfo?.firstName} ${transaction?.userProfile?.userId?.userInfo?.lastName || transaction?.user?.userId?.userInfo?.lastName}`.toLowerCase().includes(lowerCaseSearch));
      const dateMatch = transaction.created && 
      convertTimeFormat(transaction.created).includes(lowerCaseSearch);

      const emailMatch = transaction.userProfile && 
      (`${transaction?.userProfile?.userId?.email || transaction?.user?.userId?.email}`.toLowerCase().includes(lowerCaseSearch));
        const phoneMatch =
        transaction?.userInfo?.userInfo?.phoneNo &&
        (`${transaction?.userProfile?.userId?.userInfo?.phoneNo || transaction?.user?.userId?.userInfo?.phoneNo}`.toLowerCase().includes(lowerCaseSearch));
        const dialCodeMatch =
        transaction?.userInfo?.userInfo?.dialCode &&
        (`${transaction?.userProfile?.userId?.userInfo?.dialCode || transaction?.user?.userId?.userInfo?.dialCode}`.toLowerCase().includes(lowerCaseSearch));

        // Dial code + phone number match (e.g., +1-2354678958)
        const formattedPhone = `+${transaction?.userProfile?.userId?.userInfo?.dialCode || transaction?.user?.userId?.userInfo?.dialCode}-${transaction?.userProfile?.userId?.userInfo?.phoneNo || transaction?.user?.userId?.userInfo?.phoneNo}`;
        const formattedPhoneMatch = formattedPhone.includes(lowerCaseSearch);

        const amountMatch =transaction && 
        (`${transaction?.amount_paid / 100 || transaction?.amount / 100 }`.toLowerCase().includes(lowerCaseSearch));
      return idMatch || nameMatch || dateMatch || phoneMatch || dialCodeMatch || formattedPhoneMatch || emailMatch || amountMatch;
    });
  
    // Update the filtered data and pagination
    setFilteredData(filtered.slice(0, limit));
  }, [search, transList]);

  useEffect(() => {
    if (transactionType === "Publisher") {
      getAllArticleTransaction();
    } else {
      allTransaction();
    }
  }, [transactionType]);
  

  return (
    <>
      <div className="content-wrapper  draftSection">
        <h2 className="innerHeadText my-4">All Transactions</h2>

        {/* {articleData && articleData?.length === 0 && !search ?"": ( */}
        <div className="searchSection mt-3 mb-3">
          <input
            className="form-control searchInput"
            type="text"
            placeholder="Search by name,id and date"
            value={search}
            onChange={handleSearchChange}
          />
          <img
            src={images.searchIcn}
            className="img-fluid searchIcnImg"
            alt="searchIcn image"
          />
          
          {/* Display the autocomplete suggestions */}
        </div>
        {/* )} */}
        <div className="userListStatus">
          <h4
            className={
              transactionType === "Publisher"
                ? "statusHeading active"
                : "statusHeading"
            }
            onClick={() => setTransactionType("Publisher")}
          >
            Publications
          </h4>
          <h4
            className={
              transactionType === "Subscription"
                ? "statusHeading active"
                : "statusHeading"
            }
            onClick={() => setTransactionType("Subscription")}
          >
            Subscriptions
          </h4>
        </div>
        <div className="table-responsive recentTable transactTable">
          <table className="table table-dark m-0">
            <thead>
              <tr className="recentRow">
                <th className="recentHead">Index</th>
                <th className="recentHead">Name</th>
                <th className="recentHead">Email</th>
                <th className="recentHead">Phone No.</th>
                <th className="recentHead">Transaction Id</th>
                <th className="recentHead dateTimeHead">Date & Time</th>
                {transactionType === "Publisher" ? (
                  <th className="recentHead ">Publications Price</th>
                ) : (
                  <th className="recentHead ">Subscription Price</th>
                )}
              </tr>
            </thead>
            <tbody className="tableBody">
              {loading ? (
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center text-light">
                      <div
                        className="spinner-grow text-light"
                        role="status"
                      ></div>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {filteredData.length > 0 ? (
                    filteredData?.map((value, idx) => {
                      console.log(value,"value2345");
                      
                      let serialNumber = (page - 1) * limit + idx + 1;
                      return (
                        <tr key={idx}>
                          {}
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">{serialNumber}</h6>
                          </td>
                          <td
                            className="recentSubHead align-middle pointCursor"
                            onClick={() => {
                              navigate("/profile", {
                                // state: {
                                //   articleDetails: articleInfo,
                                // },
                              });
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <div className="transTable_Img me-2">
                                <img
                                  src={
                                    value?.userProfile?.userId?.userInfo
                                      ?.profilePhoto ||
                                      value?.user?.userId?.userInfo
                                      ?.profilePhoto
                                  }
                                  alt="publishImage"
                                  className="img-fluid"
                                />
                              </div>
                              <h6 className="tableSubText">
                                {value?.userProfile?.userId?.userInfo
                                  ? value?.userProfile?.userId?.userInfo
                                      ?.firstName +
                                    " " +
                                    value?.userProfile?.userId?.userInfo
                                      ?.lastName
                                  : value?.user?.userId?.userInfo
                                      ?.firstName +
                                    " " +
                                    value?.user?.userId?.userInfo?.lastName}
                              </h6>
                            </div>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText text-lowercase">
                              {value.userProfile?.userId?.email ||
                                value?.user?.userId?.email}
                            </h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">
                              +
                              {value?.userProfile?.userId?.userInfo
                                ?.dialCode ||
                                value?.user?.userId?.userInfo?.dialCode}
                              -
                              {value?.userProfile?.userId?.userInfo
                                ?.phoneNo ||
                                value?.user?.userId?.userInfo?.phoneNo}
                            </h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">{value?.id}</h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">
                              {convertTimeFormat(value?.created)}
                            </h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">
                              ${value?.amount_paid / 100 || value?.amount / 100}
                            </h6>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="noFoundHead">
                        <p className="text-center noFoundText no_Tickets_Txt">
                          No Transaction Found!
                        </p>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        {filteredData?.length > 0 && (
          <div className=" paginateSec">
            <ReactPaginate
              className="paginationBox mb-0"
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPage}
              previousLabel="< "
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Transaction;

import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
  article_image_loader: false,
  categories: {},
  publications: {},
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    getAllRegions: (state) => {
      state.loading = true;
    },
    setGetAllRegions: (state) => {
      state.loading = false;
    },
    uploadImage: (state) => {
      state.loading = true;
    },
    setUploadImage: (state, action) => {
      state.loading = false;
    },
    uploadArticleImage: (state) => {
      // state.loading = true;
      state.article_image_loader = true;
    },
    setUploadArticleImage: (state, action) => {
      // state.loading = false;
      state.article_image_loader = false;
    },
    getCategories: (state) => {
      state.loading = true;
    },
    setGetCategories: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    },
    getPublication: (state) => {
      state.loading = true;
    },
    setGetPublication: (state, action) => {
      state.loading = false;
      state.publications = action.payload;
    },
    articleCategory: (state) => {
      state.loading = true;
    },
    setArticleCategory: (state) => {
      state.loading = false;
    },
    articleSubCategory: (state) => {
      state.loading = true;
    },
    setArticleSubCategory: (state) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
      state.article_image_loader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  uploadImage,
  setUploadImage,
  getCategories,
  setGetCategories,
  setUploadArticleImage,
  uploadArticleImage,
  onErrorStopLoad,
  getPublication,
  setGetPublication,
  articleSubCategory,
  setArticleSubCategory,
  articleCategory,
  setArticleCategory,
  getAllRegions,
  setGetAllRegions,
} = webSlice.actions;

export default webSlice.reducer;

const ApiPath = {
  homePageApiPath: {
    SHEDULE_DEMO_FORM: "/scheduledDemos",
    CONTACT_US: "/scheduledDemos",
    GET_SCHEDULE: "/scheduledDemos",
    NEWSLETTER: "/newsletters",
    GET_HELPER_PAGE_INGO: "/helperpages/slug",
    GET_ALL_SOCIAL_LINKS: "/socialMedia/social-media",
    GET_CLIENT_LOGOS: "/clientLogos/client-logo",
  },
  AuthApiPath: {
    SIGNUP_USER: "/users",
    OTP_USER: "/users/verify",
    OTP_VERIFY_USER: "/users/resend",
    LOGIN_USER: "/users/login",
    FORGET_PASSWORD_USER: "/users/forgot-password",
    FORGET_PASSWORD_VERIFY: "/users/reset-password-otp-verify",
    RESET_PASSWORD: "/users/reset-password",
    CHANGE_PASSWORD: "/users/change-password",
    CHANGE_EMAIL: "/users/update-email",
    USER_PROFILE_INFO: "/users",
    EDIT_PROFILE: "/users",
    DELETE_ACCOUNT: "/users",
    USER_DETAIL: "/users",
    ACCESS_TOKEN:"/users/access-token"
  },
  webApiPath: {
    IMAGE_UPLOAD: "/users/upload-profile-image",
    ARTICLE_IMAGE: "/articles/upload-article-images",
    GET_CATEGORIES: "/categories/cat-subcat",
    GET_PUBLICATION: "/publications",
    ARTICLES_CATEGORIES: "/categories",
    GET_ALL_REGIONS: "/publications/regions",
  },
  articleApiPath: {
    CREATE_ARTICLE: "/articles",
    GET_ALL_ARTICLES: "/articles",
    UPDATE_DRAFT: "/articles",
    DELETE_DRAFT: "/articles",
    GET_ARTICLE_INFO: "/articles",
  },
  ticketApiPath: {
    GET_ALL_TICKET: "/tickets",
    CREATE_TICKET: "/tickets",
    GET_ALL_SEARCH_ARTICLE: "https://api.openai.com/v1/chat/completions",
    GET_ONE_TICKET: "/tickets",
    UPDATE_TICKET_STATUS: "/tickets/status",
    UPLOAD_TICKET_IMAGE: "/tickets/upload",
  },
  notificationApiPath: {
    ARTICLE_NOTIFICATION: "/notifications",
    UPDATE_NOTIFICATION: "/users/update-notification",
    READ_NOTIFICATION: "/notifications",
  },
  subscriptionApiPath: {
    GET_TRANSACTION: "/subscriptions/transactions",
    GET_ARTICLE_TRANSACTION: "/articles/article-transactions",
    GET_ALL_SUBSCRIPTION: "/plans/get-plans",
    CREATE_SUBSCRIPTION: "/subscriptions",
    DELETE_SUBSCRIPTION: "/subscriptions",
    GET_SUBSCRIPTION_INFO: "/subscriptions",
    CREATE_PAYMENT_CARD: "/subscriptions/create-card",
    GET_ALL_PAYMENT_CARDS: "/subscriptions/get-cards",
    DELETE_PAYMENT_CARD: "/subscriptions/delete-card",
  },
};

export default ApiPath;

import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCategories,
  getPublication,
  getAllRegions,
} from "../../../redux/slices/web/index";
import * as images from "../../../utilities/images";
import CustomModal from "../../Modal/CustomModal";
import SearchArticleModal from "../../Modal/searchArticleModal";

const Dashboard = () => {
  document.title = "Home";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [showAllCat, setShowAllCat] = useState(false);
  const [showSubCat, setShowSubCat] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [publicateInfo, setPublicateInfo] = useState();
  const [key, setKey] = useState(Math.random());
  const [clearCategory, setClearCategory] = useState(false);
  const [clearSubCategory, setClearSubCategory] = useState(false);
  const [region, setRegion] = useState([]);
  const [search, setSearch] = useState("");

  const [selectedFilters, setSelectedFilters] = useState({
    publicationType: "premium",
  });

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  // List of suggestions (replace with actual data)
  const suggestions = publicateInfo || [];

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    // setCurrentPage(1);
    // Filter suggestions based on input
    const filtered = suggestions?.filter(
      (item) =>
        item?.name?.toLowerCase().includes(value.toLowerCase()) ||
        item?.subCategoryName?.toLowerCase().includes(value.toLowerCase()) ||
        (item?.da && item?.da?.toString().includes(value)) ||
        (item?.region && item?.region?.toString().includes(value).toString()) ||
        (item?.websiteUrl &&
          item?.websiteUrl?.toString().includes(value).toLowerCase()) ||
        (item?.selectTime &&
          item?.selectTime?.toLowerCase().includes(value.toLowerCase())) ||
        (item?.niche &&
          item?.niche?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.markup && item?.markup?.toString().includes(value)) ||
        (item?.amount && item?.amount?.toString().includes(value)) ||
        (item?.sponsored &&
          item?.sponsored?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.indexed &&
          item?.indexed?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.doFollow &&
          item?.doFollow?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.rating && item?.rating?.toString().includes(value))
    );
    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion) => {
    // Check if the search term matches the full name or email
    const Name = suggestion?.name;
    const subCategoryName = suggestion?.subCategoryName;
    const Da = suggestion?.da;
    const region = suggestion?.region;
    const websiteUrl = suggestion?.websiteUrl;
    const selectTime = suggestion?.selectTime;
    const niche = suggestion?.niche;
    const markup = suggestion?.markup;
    const amount = suggestion?.amount;
    const sponsored = suggestion?.sponsored;
    const indexed = suggestion?.indexed;
    const doFollow = suggestion?.doFollow;
    const rating = suggestion?.rating;

    // Set the search value based on what the user clicked
    if (Name.toLowerCase().includes(search.toLowerCase())) {
      setSearch(Name);
    } else if (subCategoryName?.toLowerCase().includes(search.toLowerCase())) {
      setSearch(subCategoryName);
    } else if (Da?.toString().includes(search)) {
      setSearch(Number(Da));
    } else if (region?.toString().includes(search)) {
      setSearch(region);
    } else if (websiteUrl?.toLowerCase().includes(search)) {
      setSearch(websiteUrl);
    } else if (selectTime?.toLowerCase().includes(search.toLowerCase())) {
      setSearch(selectTime);
    } else if (niche?.toLowerCase().includes(search)) {
      setSearch(niche);
    } else if (markup?.toString().includes(search)) {
      setSearch(markup);
    } else if (amount?.toString().includes(search)) {
      setSearch(amount);
    } else if (sponsored?.toLowerCase().includes(search)) {
      setSearch(sponsored);
    } else if (indexed?.toLowerCase().includes(search)) {
      setSearch(indexed);
    } else if (doFollow?.toLowerCase().includes(search)) {
      setSearch(doFollow);
    } else if (rating?.toString().includes(search)) {
      setSearch(rating);
    } else {
      // Fallback to the name if no match was found
      setSearch(Name);
    }

    // Clear the suggestions after selection
    setFilteredSuggestions([]);
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  /**
   *
   * @param {*} flag
   */

  const handleViewAll = (flag) => {
    if (flag == "category") {
      setShowAllCat(!showAllCat);
    } else if (flag == "subcategory") {
      setShowSubCat(!showSubCat);
    }
  };

  /**
   * to get all categories
   */

  const getAllCategories = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res.status === 200) {
            setCategory(
              res?.data?.data?.categories.filter(
                (Items) => Items?.flag === "genre"
              )
            );
            setSubCategory(
              res?.data?.data?.subCategories.filter(
                (Items) => Items?.flag === "type"
              )
            );
          }
        },
      })
    );
  };

  /**
   *
   * @param {*} val
   * @param {*} flag
   */

  const handlePublication = (val, flag) => {
    if (flag == "categoryFun") {
      setCategoryIds((prevIds) => {
        const index = prevIds.indexOf(val?._id);
        let newIds;
        if (index !== -1) {
          newIds = prevIds.filter((id) => id !== val?._id);
          if (newIds.length === 0) {
            setClearCategory(false);
          }
        } else {
          newIds = [...prevIds, val?._id];
          setClearCategory(true);
        }
        return newIds;
      });
    } else if (flag == "SubcategoryFun") {
      setSubCategoryIds((prevIds) => {
        const index = prevIds.indexOf(val?._id);
        let newIds;
        if (index !== -1) {
          newIds = prevIds.filter((id) => id !== val?._id);
          if (newIds.length === 0) {
            setClearSubCategory(false);
          }
        } else {
          newIds = [...prevIds, val?._id];
          setClearSubCategory(true);
        }
        return newIds;
      });
    }
  };

  const getAllPublication = () => {
    let params = {
      categoryId: categoryIds,
      subCategoryId: subCategoryIds,
    };
    params = {
      ...params,
      ...selectedFilters,
      search: search || undefined,
    };
    dispatch(
      getPublication({
        ...params,
        cb(res) {
          if (res.status) {
            setPublicateInfo(res?.data?.data?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    getAllPublication();
  }, [categoryIds, subCategoryIds, selectedFilters, search]);

  useEffect(() => {
    getAllCategories();
  }, [categoryIds]);

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // select filters
  const handleSelectFilters = (name, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (prevFilters[name] === value) {
        // If the value is already selected, remove the field from the object
        delete updatedFilters[name];
      } else {
        // Otherwise, update or add the field with the new value
        updatedFilters[name] = value;
      }

      return updatedFilters;
    });
  };

  // get all regions
  useEffect(() => {
    dispatch(
      getAllRegions({
        cb(res) {
          if (res.status === 200) {
            handeRemoveDupRegions(res?.data?.data);
          }
        },
      })
    );
  }, []);

  // remove duplicate regions
  const handeRemoveDupRegions = (data) => {
    const allRegion = data?.map(
      (value) => value?.slice(0, 1)?.toUpperCase() + value?.slice(1)
    );
    setRegion([...new Set(allRegion)]);
  };

  // reset all filters
  const handleResetAllFilters = () => {
    setSelectedFilters({
      publicationType: "free",
    });
    setCategoryIds([]);
    setSubCategoryIds([]);
  };

  return (
    <>
      <section className="content-wrapper dashboardSection dashboard_Section">
        <div className="dashArticle">
          <div className="row">
            <div className="col-lg-6 col-md-12 mt-2">
              <div
                className="dashArticleSub pointCursor"
                // onClick={() => navigate("/write-article")}
                onClick={() => navigate("/select-publication")}
              >
                <h3 className="uploadHeading">Upload Article</h3>
                <button className="DashArrow" type="button">
                  <img
                    src={images.arrowRight}
                    className="img-fluid"
                    alt="arrowRight image"
                  />
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-2">
              <div
                className="dashArticleSub pointCursor"
                onClick={() =>
                  navigate("/select-publication", {
                    state: { type: "articleAI" },
                  })
                }
              >
                <h3 className="uploadHeading">Write Article Using AI</h3>
                <button className="DashArrow" type="button">
                  <img
                    src={images.arrowRight}
                    className="img-fluid"
                    alt="arrowRight image"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="searchSection categoryBox">
          <input
            className="form-control searchInput"
            type="text"
            placeholder="Search..."
            value={search || ""}
            // onChange={(e) => {
            //   handleSelectFilters("search", e.target.value);
            // }}
            onChange={handleSearchChange}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIconDashboard"
          />
          {search && filteredSuggestions.length > 0 && (
            <ul className="autocomplete-list pointCursor">
              {filteredSuggestions.map((suggestion) => {
                const Name = suggestion?.name;
                const subCategoryName = suggestion?.subCategoryName;
                const Da = suggestion?.da;
                const region = suggestion?.region;
                const websiteUrl = suggestion?.websiteUrl;
                const selectTime = suggestion?.selectTime;
                const niche = suggestion?.niche;
                const markup = suggestion?.markup;
                const amount = suggestion?.amount;
                const sponsored = suggestion?.sponsored;
                const indexed = suggestion?.indexed;
                const doFollow = suggestion?.doFollow;
                const rating = suggestion?.rating;
                // Determine whether to show the name or email based on the search input
                const searchValue = search.toLowerCase();
                const displayValue = Name.toLowerCase().includes(searchValue)
                  ? Name
                  : subCategoryName &&
                    subCategoryName.toLowerCase().includes(searchValue)
                  ? subCategoryName
                  : Da && Da?.toString().includes(searchValue)
                  ? Da
                  : region && region?.toString().includes(searchValue)
                  ? region
                  : websiteUrl &&
                    websiteUrl?.toLowerCase().includes(searchValue)
                  ? websiteUrl
                  : selectTime &&
                    selectTime?.toLowerCase().includes(searchValue)
                  ? selectTime
                  : niche && niche?.toLowerCase().includes(searchValue)
                  ? niche
                  : markup && markup?.toString().includes(searchValue)
                  ? markup
                  : amount && amount?.toString().includes(searchValue)
                  ? amount
                  : sponsored && sponsored?.toLowerCase().includes(searchValue)
                  ? sponsored
                  : indexed && indexed?.toLowerCase().includes(searchValue)
                  ? indexed
                  : doFollow && doFollow?.toLowerCase().includes(searchValue)
                  ? doFollow
                  : rating && rating?.toString().includes(searchValue)
                  ? rating
                  : Name; // Default to full name if neither matches
                return (
                  <li
                    key={suggestion._id}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="autocomplete-item"
                  >
                    {displayValue}
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className="categoryBox">
          <div className="row">
            {selectedFilters?.publicationType === "premium" && (
              <div className="col-lg-6 col-md-6 col-12">
                <h4 className="categoryText mb-2">Sort By</h4>
                <FormControl>
                  <Select
                    className="customSelect"
                    value={selectedFilters.sortBy || ""}
                    onChange={(e) => {
                      handleSelectFilters("sortBy", e.target.value);
                    }}
                    label="Category"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      fontFamily: "Poppins",
                      ".MuiSvgIcon-root": {
                        color: "#fff",
                      },
                      fontStyle: "normal",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px",
                          border: "1px solid #FFFFFF4D",
                          background: "#616161",
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginTop: "5px",
                          " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                            {
                              color: "#ffffff",
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: "inherit",
                              marginTop: "3px",
                              "&.Mui-selected": {
                                backgroundColor: "#757575",
                              },
                              "&:hover": {
                                backgroundColor: "#757575",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "#757575",
                              },
                            },
                        },
                      },
                    }}
                  >
                    <MenuItem disabled value={""}>
                      Price (Asc)
                    </MenuItem>
                    <MenuItem value={"asc"}>{"Low To High"}</MenuItem>
                    <MenuItem value={"desc"}>{"High To Low"}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            <div
              className={
                selectedFilters?.publicationType === "premium"
                  ? "col-lg-6 col-md-6 col-12"
                  : "col-12"
              }
            >
              <h4 className="categoryText dashSel_Region mb-2 mt-3 mt-md-0">
                Select Region
              </h4>
              <FormControl>
                <Select
                  className="customSelect"
                  value={selectedFilters.region || ""}
                  onChange={(e) => {
                    handleSelectFilters("region", e.target.value);
                  }}
                  label="Category"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    fontFamily: "Poppins",
                    ".MuiSvgIcon-root": {
                      color: "#fff",
                    },
                    fontStyle: "normal",
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "12px",
                        border: "1px solid #FFFFFF4D",
                        background: "#616161",
                        color: "#ffffff",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "5px",
                        " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "500",
                          fontFamily: "inherit",
                          marginTop: "3px",
                          "&.Mui-selected": {
                            backgroundColor: "#757575",
                          },
                          "&:hover": {
                            backgroundColor: "#757575",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#757575",
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem disabled value={""}>
                    Select Region
                  </MenuItem>
                  {region?.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* {selectedFilters?.publicationType === "premium" && (
              <div className="categoryBox">
                <div className="col-12">
                  <h4 className="categoryText">Price Range</h4>
                  <Slider
                    min={0}
                    max={10000}
                    className="custom-slider"
                    value={selectedFilters?.amount || 0}
                    onChange={(value) => handleSelectFilters("amount", value)}
                  />
                  <div className="range-prices">
                    <span className="min-range-price">
                      {USDollar.format(selectedFilters?.amount || 0)}
                    </span>
                    <span className="max-range-price">
                      {USDollar.format(10000)}
                    </span>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>

        {category?.length > 0 && (
          <div className="categoryBox">
            <div className="flexBox">
              <h4 className="categoryText">Filter by Genre</h4>
              {clearCategory ? (
                <button
                  type="button"
                  className="clearBtn"
                  onClick={() => {
                    setCategoryIds([]);
                    setClearCategory(false);
                  }}
                >
                  {" "}
                  Clear All
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="categoryOuterBox">
              {category
                ?.slice(0, showAllCat ? category.length : 11)
                .map((val, index) => {
                  return (
                    <div
                      className={`categoryBx ${
                        categoryIds.includes(val._id) ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => {
                        handlePublication(val, "categoryFun");
                      }}
                    >
                      {val?.name}
                    </div>
                  );
                })}
              {category.length > 11 && (
                <button
                  type="button"
                  className="clearBtn"
                  onClick={() => handleViewAll("category")}
                >
                  {showAllCat ? "View Less" : "View All ....."}
                </button>
              )}
            </div>
          </div>
        )}

        {subCategory?.length > 0 && (
          <div className="categoryBox">
            <div className="flexBox">
              <h4 className="categoryText">Filter by Type</h4>
              {clearSubCategory ? (
                <button
                  type="button"
                  className="clearBtn"
                  onClick={() => {
                    setSubCategoryIds([]);
                    setClearSubCategory(false);
                  }}
                >
                  {" "}
                  Clear All
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="categoryOuterBox">
              {subCategory
                ?.slice(0, showSubCat ? subCategory.length : 25)
                .map((val, index) => (
                  <div
                    className={`categoryBx ${
                      subCategoryIds.includes(val._id) ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => {
                      handlePublication(val, "SubcategoryFun");
                    }}
                  >
                    {val?.name}
                  </div>
                ))}
              {subCategory.length > 15 && (
                <button
                  type="button"
                  className="clearBtn"
                  onClick={() => handleViewAll("subcategory")}
                >
                  {showSubCat ? "View Less" : "View All ....."}
                </button>
              )}
            </div>
          </div>
        )}

        <div className="categoryBox">
          <h4 className="categoryText">Image</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("image", "yes")}
              className={
                selectedFilters?.image === "yes"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Yes
            </div>
            <div
              onClick={() => handleSelectFilters("image", "maybe")}
              className={
                selectedFilters?.image === "maybe"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Maybe
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Do Follow</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("doFollow", "yes")}
              className={
                selectedFilters?.doFollow === "yes"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Yes
            </div>
            <div
              onClick={() => handleSelectFilters("doFollow", "no")}
              className={
                selectedFilters?.doFollow === "no"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              No
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Indexed</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("indexed", "yes")}
              className={
                selectedFilters?.indexed === "yes"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Yes
            </div>
            <div
              onClick={() => handleSelectFilters("indexed", "maybe")}
              className={
                selectedFilters?.indexed === "maybe"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Maybe
            </div>
            <div
              onClick={() => handleSelectFilters("indexed", "no")}
              className={
                selectedFilters?.indexed === "no"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              No
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Filter by Sponsored</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("sponsored", "yes")}
              className={
                selectedFilters?.sponsored === "yes"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Yes
            </div>
            <div
              onClick={() => handleSelectFilters("sponsored", "no")}
              className={
                selectedFilters?.sponsored === "no"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              No
            </div>
            <div
              onClick={() => handleSelectFilters("sponsored", "discrete")}
              className={
                selectedFilters?.sponsored === "discrete"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Discrete
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Niche</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("niche", "erotice")}
              className={
                selectedFilters?.niche === "erotice"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Erotic
            </div>
            <div
              onClick={() => handleSelectFilters("niche", "health")}
              className={
                selectedFilters?.niche === "health"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Health
            </div>
            <div
              onClick={() => handleSelectFilters("niche", "crypto")}
              className={
                selectedFilters?.niche === "crypto"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Crypto
            </div>
            <div
              onClick={() => handleSelectFilters("niche", "cbd")}
              className={
                selectedFilters?.niche === "cbd"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Cbd
            </div>
            <div
              onClick={() => handleSelectFilters("niche", "gambling")}
              className={
                selectedFilters?.niche === "gambling"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Gambling
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <button
            onClick={handleResetAllFilters}
            type="button"
            className="clearBtn"
          >
            Reset All Filters
          </button>
        </div>

        <div className="dashPublishSec">
          <div className="flexBox">
            <h4 className="publishText">Top Publications</h4>
            <button
              type="button"
              className="clearBtn"
              onClick={() => {
                navigate("/publication");
              }}
            >
              See All
              <img
                src={images.arrowDark}
                alt="publishImage"
                className="img-fluid ms-1"
              />
            </button>
          </div>

          <div className="userListStatus dashboard_Userlist">
            <h4
              className={
                selectedFilters.publicationType === "free"
                  ? "statusHeading active"
                  : "statusHeading"
              }
              onClick={() => handleSelectFilters("publicationType", "free")}
            >
              Free
            </h4>
            <h4
              className={
                selectedFilters.publicationType === "premium"
                  ? "statusHeading active"
                  : "statusHeading"
              }
              onClick={() => handleSelectFilters("publicationType", "premium")}
            >
              Premium
            </h4>
          </div>
          <div className="row mt-md-3">
            {publicateInfo?.length > 0 ? (
              publicateInfo?.slice(0, 6)?.map((val, index) => {
                return (
                  <div className="col-xl-6 col-lg-12 mt-2 " key={index}>
                    <div className="publicationInfo">
                      <div className="dashBoard_Img_Parent">
                        <img
                          src={val?.publicationLogo}
                          alt="publishImage"
                          className="publishImg img-fluid"
                        />
                      </div>
                      <div className="publishInner">
                        <div className="flexBox">
                          <h4 className="stateText">{val?.name}</h4>
                          <div className="ratingBx">
                            <img
                              src={images.starImg}
                              alt="starImage"
                              className="img-fluid"
                            />
                            <h6 className="ratingText">{val?.rating}</h6>
                          </div>
                        </div>
                        <div className="publishSubInner mt-2">
                          <div className="publishLeft">
                            <div className="publishFlex">
                              <h6 className="publishLight">Price:</h6>
                              <h6
                                className="ratingText pointCursor"
                                title={val?.amount?.toString().length > 5 ? `Price: $${val?.amount}` : ''}
                              >
                                {val?.publicationType === "free"
                                  ? "Free"
                                  : `${
                                      val?.amount?.toString().length > 5
                                        ? `$${val?.amount
                                            ?.toString()
                                            .slice(0, 5)}...`
                                        : `$${val?.amount}`
                                    }`}
                              </h6>
                            </div>
                            <div className="publishFlex">
                              <h6 className="publishLight">DA: </h6>
                              <h6 className="ratingText">{val?.da}</h6>
                            </div>
                            <div className="publishFlex">
                              <h6 className="publishLight">Sponsored: </h6>
                              <h6 className="ratingText">
                                {val?.sponsored === "yes" ? "Yes" : "No"}
                              </h6>
                            </div>
                          </div>
                          <div className="publishLeft publishRight">
                            <div className="publishFlex">
                              <h6 className="publishLight">Niche:</h6>
                              <h6 className="ratingText">{val?.niche}</h6>
                            </div>
                            <div className="publishFlex">
                              <h6 className="publishLight">Type: </h6>
                              <h6 className="ratingText">
                                {val?.subCategoryName || "---"}
                              </h6>
                            </div>
                            <div className="publishFlex">
                              <h6 className="publishLight">Image: </h6>
                              <h6 className="ratingText">
                                {val?.image === "yes" ? "Yes" : "No"}
                              </h6>
                            </div>
                          </div>
                          <div className="publishRight">
                            <div className="publishFlex">
                              <h6 className="publishLight">Time: </h6>
                              <h6 className="ratingText"> {val?.selectTime}</h6>
                            </div>
                            <div className="publishFlex">
                              <h6 className="publishLight">Indexed: </h6>
                              <h6 className="ratingText">
                                {val?.indexed === "yes" ? "Yes" : "No"}
                              </h6>
                            </div>
                            <div className="publishFlex">
                              <h6 className="publishLight">Do Follow: </h6>
                              <h6 className="ratingText">
                                {" "}
                                {val?.doFollow === "yes" ? "Yes" : "No"}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h3 className="text-center m-0 noFoundText">
                No Publications Found!
              </h3>
            )}
          </div>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "searchArticle" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "searchArticle" ? "searchArticleModal" : ""}
        child={
          modalDetail.flag === "searchArticle" ? (
            <SearchArticleModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "searchArticle" ? (
            <>
              <h2 className="modal_Heading">Search Article</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Dashboard;

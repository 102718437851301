import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getMessaging, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCDiq6fFAm3zUruZXxk-sQU2I-lbOeiIQs",
//   authDomain: "pr-publisher-98feb.firebaseapp.com",
//   projectId: "pr-publisher-98feb",
//   storageBucket: "pr-publisher-98feb.appspot.com",
//   messagingSenderId: "622922565333",
//   appId: "1:622922565333:web:1936b163ae2985aac1045c",
//   measurementId: "G-C0NQYMY5YD"
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// Feature detection to check if the browser supports notifications and service workers

const app = initializeApp(firebaseConfig);

let messaging = null;
if ("Notification" in window && "serviceWorker" in navigator) {
  messaging = getMessaging(app);
}
const db = getFirestore(app);
const storage = getStorage(app);
const PARENTCOLLECTIONNAME = "chats";
const USERPARENTCOLLECTION = "users";
const CHILDCOLLECTIONNAME = "messages";
const TICKETCOLLECTIONNAME = "tickets";
const TICKETCHILDCOLLECTIONNAME = "ticket_status";
const ARTICLEPARENTCOLLECTIONNAME = "articlechats";
const ARTICLECHILDCOLLECTIONNAME = "articlemessages";
const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY
export {
  db,
  storage,
  app,
  messaging,
  PARENTCOLLECTIONNAME,
  USERPARENTCOLLECTION,
  CHILDCOLLECTIONNAME,
  TICKETCOLLECTIONNAME,
  TICKETCHILDCOLLECTIONNAME,
  ARTICLEPARENTCOLLECTIONNAME,
  ARTICLECHILDCOLLECTIONNAME,
  VAPID_KEY,
};

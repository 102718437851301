if (process.env.NODE_ENV === "production") {
    module.exports = {
      OPEN_AI_DEVELOPER_KEY: "sk-proj-vD1q1K6c2wlGjeiwkdOsT3BlbkFJbulJFzxMrefPcdRVPIER"
    };
  
  } else {
  
     module.exports = {
      OPEN_AI_DEVELOPER_KEY: "sk-proj-vD1q1K6c2wlGjeiwkdOsT3BlbkFJbulJFzxMrefPcdRVPIER"
    };
  
  }
  
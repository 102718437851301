import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import {
  getClientLogos,
  getHelperpagesInfo,
} from "../../redux/slices/homePage";
import * as Image from "../../utilities/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const AboutUs = () => {
  document.title = "About Us";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [aboutUs, setAboutUs] = useState("");
  const [clientLogos, setClientLogo] = useState([]);
  /**
   *
   * @param {} e
   */

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "red" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextArrow />,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
    ],
  };

  const testimonial = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const aboutUsInfo = (e) => {
    let params = {
      slug: "term_condition",
    };
    dispatch(
      getHelperpagesInfo({
        ...params,
        cb(res) {
          if (res.status) {
            setAboutUs(res?.data?.data);
          }
        },
      })
    );
  };
  useEffect(() => {
    dispatch(
      getClientLogos({
        cb(res) {
          if (res.status === 200) {
            setClientLogo(res?.data?.data);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    aboutUsInfo();
  }, []);

  return (
    <>
      <div className="nav_section_bg">
        <Navbar />

        <section className="aboutBanner_ about_Us_Banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="aboutHeading_">
                  <h2 className="aboutHead_">About Us</h2>
                  <h3 className="aboutPara_">Who We Are</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="aboutLeftcontent_ mt-5">
                  <p className="sub_p_text">
                    Welcome to OwnBuzz, the SaaS platform designed to empower PR
                    and marketing agencies to amplify their clients' brands,
                    control their narratives, and ensure their stories make
                    headlines across the globe. At OwnBuzz, we believe in giving
                    you the tools to create a buzz that resonates, positioning
                    your clients at the forefront of their industry.
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="aboutRightimage_ mt-4">
                  <img className="aboutrightimg_" src={Image.aboutrightimg_} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="articleRules_">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10">
              <h2 className="subheading mb-4">Our Mission</h2>
              <p className="sub_p_text">
                Our mission is simple: to revolutionize how PR and marketing
                agencies manage media exposure for their clients. We strive to
                provide a seamless, automated solution that guarantees
                publication, enhances SEO, and delivers real results without the
                traditional hurdles of pitching and negotiation. OwnBuzz is here
                to ensure that your clients' voices are not just heard but
                impossible to ignore.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="mainRules_ mt-5">
                <img
                  className="aboutruleimg_ img-fluid"
                  src={Image.rulesImage}
                />
                <h3 className="head24_ mt-3 mb-3">Produce</h3>
                <p className="sub_p_text">
                  Lorem ipsum dolor sit amet,con <br /> sectetur adipiscing
                  elit.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mainRules_">
                <img
                  className="aboutruleimg_ img-fluid"
                  src={Image.rulesImage}
                />
                <h3 className="head24_ mt-3 mb-3">Publish</h3>
                <p className="sub_p_text">
                  Lorem ipsum dolor sit amet,con <br /> sectetur adipiscing
                  elit.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mainRules_">
                <img
                  className="aboutruleimg_ img-fluid"
                  src={Image.rulesImage}
                />
                <h3 className="head24_ mt-3 mb-3">Permote</h3>
                <p className="sub_p_text">
                  Lorem ipsum dolor sit amet,con <br /> sectetur adipiscing
                  elit.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyChoose_ why_Choose about_OurValues">
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <h2 className="subheading mb-4">Our Values</h2>
              <p className="sub_p_text">
                At the heart of OwnBuzz are values that guide everything we do:
              </p>
            </div>
          </div>
          <div className="row mt-md-5 mt-3 why_Choose_Row">
            <div className="col-lg-3">
              <div className="chooseOption_">
                <h3 className="authSubText mb-4">Innovation</h3>
                <p className="sub_p_text">
                  We continuously evolve our platform to incorporate the latest
                  in AI and automation, ensuring our users can access
                  cutting-edge tools.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="chooseOption_">
                <h3 className="authSubText mb-4">Transparency</h3>
                <p className="sub_p_text">
                  We believe in complete transparency, and we provide users with
                  real-time tracking of their content from submission to
                  publication.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="chooseOption_">
                <h3 className="authSubText mb-4">Client Success:</h3>
                <p className="sub_p_text">
                  Your clients’ success is our success. We are dedicated to
                  helping you deliver outstanding results that exceed
                  expectations.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="chooseOption_">
                <h3 className="authSubText mb-4">Empowerment</h3>
                <p className="sub_p_text">
                  We empower agencies to take control of their clients'
                  narratives, ensuring their stories are told how they want.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ourvision_Section overVision_Parent">
        <div className="overvisionSection_Child">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 vision_Divider">
                <div>
                  <h2 className="subheading mb-sm-4 mb-3 textCenter">
                    Our Vision
                  </h2>
                  <p className="ourVision_Para sub_p_text textCenter">
                    We envision a future where every brand, no matter its size,
                    has the power <br /> to control its narrative and achieve
                    maximum media exposure. <br /> OwnBuzz is committed to being
                    the catalyst for this change, continuously <br />
                    innovating our platform to meet the evolving needs of PR and
                    marketing <br /> professionals worldwide.
                  </p>
                  <div className="ourVision_Btn textCenter">
                    <button
                      type="button"
                      className="secondry_btn getIntouch_Btn"
                      onClick={() => {
                        navigate("/schedule-demo");
                      }}
                    >
                      Schedule A Demo
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-5 vision_SecondDivider">
                <div className="visionImg_Parent textCenter">
                  <img
                    src={Image.visionImg}
                    alt="visionImg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" ourvision_Section pt-0">
        <div className="ourStory_Section">
          <div className="container">
            <div className="row justify-content-center textCenter">
              <div className="col-md-6">
                <div className="visionImg_Parent pt-4">
                  <img
                    src={Image.storyImg}
                    alt="storyImg"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="ourStory_Box mt-4 text-left">
                  <h2 className="ourStory_Head subheading mb-4 ">Our Story</h2>
                  <img
                    src={Image.upQuates}
                    alt="upQuates"
                    className="aboutStory_Quates"
                  />
                  <p className="ourStory_Para sub_p_text">
                    OwnBuzz was born from a simple idea: to make media outreach
                    more accessible, efficient, and impactful for PR and
                    marketing agencies. Our founders recognized the challenges
                    agencies face in securing media coverage—challenges like
                    time-consuming pitching processes, the uncertainty of
                    publication, and the constant need to prove ROI to clients.
                    With OwnBuzz, we set out to eliminate these pain points by
                    creating a platform that guarantees publication, automates
                    the PR process, and provides tangible results that speak for
                    themselves.
                  </p>
                  <div className="text-end">
                    <img
                      src={Image.downQuates}
                      alt="downQuates"
                      className="aboutStory_Quates"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="articleRules_ ourvision_Section aboutUs_Commitment">
        {/* <div className="theTeam_Container team_Section"> */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <h2 className="subheading mb-sm-4 mb-2 textCenter">The Team</h2>
                <p className="sub_p_text textCenter">
                  Our team is a diverse group of professionals with deep
                  expertise in PR, marketing, AI technology, and customer
                  service. Led by a visionary leadership team, we are passionate
                  about helping agencies achieve their goals and deliver
                  unmatched value to their clients.
                </p>
              </div>
            </div>
          </div>

          <div className="slider-container teamSider">
            <Slider {...settings}>
              <div className="p-2">
                <img
                  src={Image.teamImg1}
                  alt="teamImages"
                  className="teamImages"
                />
              </div>
              <div className="p-2">
                <img
                  src={Image.teamImg2}
                  alt="teamImages"
                  className="teamImages"
                />
              </div>
              <div className="p-2">
                <img
                  src={Image.teamImg3}
                  alt="teamImages"
                  className="teamImages"
                />
              </div>
              <div className="p-2">
                <img
                  src={Image.teamImg4}
                  alt="teamImages"
                  className="teamImages"
                />
              </div>
            </Slider>
          </div>
        {/* </div> */}
      </section>
      <div className="aboutUs_teamParent pt-0">
        <section className="whyChoose_ why_Choose">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <h2 className="subheading mb-4">What We Offer</h2>
                <p className="sub_p_text">
                  OwnBuzz is more than just a SaaS platform; it's your partner
                  in creating media success stories. Our platform offers:
                </p>
              </div>
            </div>
            <div className="row mt-5 why_Choose_Row">
              <div className="col-lg-3">
                <div className="chooseOption_">
                  <h3 className="authSubText mb-4">Guaranteed Publication:</h3>
                  <p className="sub_p_text">
                    Say goodbye to the uncertainty of pitching. With OwnBuzz,
                    your clients' content is published, no questions asked.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="chooseOption_">
                  <h3 className="authSubText mb-4">SEO Boosting Backlinks:</h3>
                  <p className="sub_p_text">
                    Enhance your clients’ digital footprint with do-follow
                    backlinks from high-authority publications.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="chooseOption_">
                  <h3 className="authSubText mb-4">Fast Turnaround:</h3>
                  <p className="sub_p_text">
                    Get your clients’ stories out there when it matters most,
                    with content going live within 24 hours.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="chooseOption_">
                  <h3 className="authSubText mb-4">AI-Generated Content:</h3>
                  <p className="sub_p_text">
                    Leverage our cutting-edge AI to create human-like articles
                    in seconds or easily upload your own content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="aboutUs_teamParent pt-0">
        <section className="aboutUs_Commitment">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-7 vision_Divider">
                <h2 className="about_OurCommitHead subheading mb-sm-4 mb-2 textCenter">
                  Our Commitment to Innovation
                </h2>
                <p className="ourVision_Para sub_p_text textCenter">
                  Innovation is in our DNA. We’re continuously developing new
                  features and <br /> improving our platform to ensure OwnBuzz
                  remains at the forefront of PR <br /> and marketing
                  technology. Our proprietary AI content creation tool and{" "}
                  <br /> automated PR workflow are just the beginning—expect
                  even more exciting <br /> developments as we push the
                  boundaries of what’s possible..
                </p>
              </div>
              <div className="col-md-5 vision_SecondDivider">
                <div className="textCenter">
                  <img
                    src={Image.communityImg}
                    alt="communityImg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="growth_sc aboutgrowth experience_count">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="count_Para medium_h text-center">
                We take pride in the positive impact our solutions have had on
                our clients, and are committed to providing exceptional customer
                support and service. Our clients can count on us to be a trusted
                partner, always there to help them achieve their goals and reach
                new heights.
              </p>
            </div>
          </div>
          <div className="row count_Container">
            <div className="col-lg-4">
              <div className="time_Saved">
                <div className="time-saved-txt">
                  <h3 className="subhead48">100+</h3>
                  <span className="passionate_People">Passionate People</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="growth_box">
                <div className="time_saved_txt">
                  <h3 className="subhead48">180+</h3>
                  <span className="passionate_People">Passionate People</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="growth_box">
                <div className="time_saved_txt">
                  <h3 className="subhead48">10+</h3>
                  <span className="passionate_People">Years of Experience</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="slider-container">
                <Slider {...settings}>
                  {clientLogos?.map((logo, index) => (
                    <div className="p-5">
                      {console.log(logo, "logo")}
                      <a
                        key={index}
                        href={logo?.link}
                        className="marquee_Links"
                        target="_blank"
                      >
                        <div className="clientLogoOuter">
                          <img
                            src={logo?.logo}
                            alt="slider_img"
                            className="img-fluid w-100 h-100 home_slider_img"
                          />
                        </div>
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="aboutUs_teamParent">
        <section className=" ourvision_Section ourStory_Section joinOur_Team aboutUs_Commitment">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6">
                <div className="visionImg_Parent">
                  <img
                    src={Image.ourTeam}
                    alt="ourteam"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="joinTeam_Right">
                  <h2 className="subheading mb-4 textCenter">Join Our Team</h2>
                  <p className="sub_p_text textCenter">
                    We’re always on the lookout for passionate, talented
                    individuals who share our vision of transforming the PR and
                    marketing landscape. If you’re driven by innovation and
                    eager to make a difference, explore career opportunities
                    with OwnBuzz and join a team shaping the future of media
                    outreach.
                  </p>

                  <div className="ourVision_Btn textCenter">
                    <button
                      type="button"
                      className="secondry_btn getIntouch_Btn"
                      onClick={() => {
                        navigate("/signup");
                      }}
                    >
                     Join Our Team
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="aboutUs_teamParent pt-0">
        <section className="about_GetIntouch text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="getIn_Touch_Parent">
                  <h2 className="subheading gettouch_Txt">Get in touch</h2>

                  <p className="sub_p_text pt-3">
                    Have questions or want to learn more about how OwnBuzz can
                    elevate your agency's success? We’d love to hear from you.
                    Contact Us today, or connect with us on social media to stay
                    updated on the latest from OwnBuzz.
                  </p>
                  <button
                    type="button"
                    className="secondry_btn getIntouch_Btn"
                    onClick={() => {
                      navigate("/Contact-us");
                    }}
                  >
                    Get In Touch
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "../../redux/slices/auth";
import { useAuthSelector } from "../../redux/selector/auth";
import { toast } from "react-toastify";

const DeleteAccount = () => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const userId = authSelector?.userInfo?.userInfo?.id;
  const [title, setTitle] = useState("");
  console.log(title,"title");
  

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  //Delete Account
  const handleDelete = () => {
    if (!title) {
      showToast("Please type Delete for account delete");
      return
    } else if (title.toLowerCase() !== "delete") {
      showToast("Enter only Delete for account delete");
      return
    }
    let params = {
      id: userId,
    };
    dispatch(
      deleteAccount({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/");
          }
        },
      })
    );
  };

  return (
    <>
      <div className="deleteBox p-3">
        <label for="title" className="input_Label">
          Delete Account
        </label>
        <input
          className="form-control deleteInput"
          placeholder="Please enter Delete"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="logoutModalSec text-center">
        <button type="submit" className="signUpBtn my-3" onClick={handleDelete}>
          Yes, Delete
        </button>
      </div>
    </>
  );
};

export default DeleteAccount;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import { userForgetPassword } from "../../redux/slices/auth";
import * as Image from "../../utilities/images";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const { loading } = authSelector;
  const [formData, setFormData] = useState({
    email: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email) {
      toast.error("Please enter your email");
      return;
    }
    let params = {
      email: formData.email.trim(),
    };
    dispatch(
      userForgetPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/verification", {
              state: {
                email: formData?.email,
                type: "forgot",
              },
            });
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    document.title = "forgot Password";
  });

  return (
    <>
      <section className="authbgSection authOtherSection">
        <img
          src={Image.authlogo}
          alt="authLogo"
          className="authLogoImg img-fluid pointCursor"
          onClick={() => navigate("/login")}
        />
        <div className="authOuterSec">
          <div className="signupSection authInnerSec">
            <h1 className="authheading"> Forgot Password</h1>
            <p className="authPara mt-3">
              Enter registered email address to reset your password.
            </p>
            <form
              className="authForm"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="signupForm">
                <div className="row mt-3">
                  <div className="col-lg-12 mt-4">
                    <div className="signupField">
                    {/* <label for="email" className="input_Label">Email:</label> */}
                      <input
                        type="email"
                        className="form-control nameControl"
                        placeholder="Enter Email Address"
                        name="email"
                        value={formData.email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <button type="submit" className="signUpBtn w-100">
                      Request OTP
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-2"></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;

import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../../config";
import ApiPath from "../../constants/apiPath";
import * as images from "../../utilities/images";

const SearchArticleModal = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (search?.length > 0) {
      setLoading(true);
      try {
        const url = ApiPath?.ticketApiPath?.GET_ALL_SEARCH_ARTICLE; // Replace with your API endpoint URL
        const data = {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: search }],
        };

        const headers = {
          "Content-Type": "application/json", // Set the Content-Type header to JSON
          Authorization: `Bearer ${config?.OPEN_AI_DEVELOPER_KEY}`, // Replace 'your_access_token' with your actual token
        };
        const response = await axios.post(url, data, { headers });
        setContent(response.data?.choices[0]?.message?.content);
        setLoading(false);
        // Log the response data
      } catch (error) {
        toast.error(error); // Log any errors
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="searchArticleBx">
        <div className="searchBox">
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <input
              className="form-control searchInput"
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <img
              src={images.searchIcn}
              className="img-fluid searchIcnImg"
              alt="searchIcn image"
            />
          </form>
        </div>
        <div className="artcleContent">
          {/* content */}
          {loading && "Loading..."}
          {content}
        </div>
        <div className="articleBtn">
          <button type="button" className="cancelBtn">
            Cancel
          </button>
          <button
            type="button"
            className="signUpBtn"
            onClick={() => {
              content?.length > 0
                ? navigate("/write-article", {
                    state: {
                      contentData: content,
                    },
                  })
                : void 0;
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default SearchArticleModal;

import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useConvertMarkDownHtml } from "../../../redux/customHooks/useConvertMarkDownHtml";
import { useArticleSelector } from "../../../redux/selector/article";
import { getAllArticles } from "../../../redux/slices/articles";
import * as images from "../../../utilities/images";
import Showdown from "showdown";

const Articles = () => {
  document.title = "Articles";
  const articleSelector = useArticleSelector();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [articleData, setArticleData] = useState([]);
  console.log(articleData, "articleData");

  const [expandedIds, setExpandedIds] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState("");
  const [totalRecord, setTotalRecord] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  // List of suggestions (replace with actual data)
  const suggestions = articleData || [];

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setCurrentPage(1);

    // Filter suggestions based on input
    const filtered = suggestions.filter((item) =>
      item?.title?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearch(suggestion?.title);
    setFilteredSuggestions([]);
  };

  // const handleToggleExpand = (e, id) => {
  //   setExpandedIds((prevExpandedIds) => ({
  //     ...prevExpandedIds,
  //     [id]: !prevExpandedIds[id],
  //   }));
  // };

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  /**
   * To get all articles
   */
  const allArticles = () => {
    let params = {
      page: currentPage,
      limit: 10,
      search: search || undefined,
    };
    dispatch(
      getAllArticles({
        ...params,
        cb(res) {
          if (res.status) {
            setArticleData(res?.data?.data?.data);
            setPageCount(res.data.data.total_pages);
            setTotalRecord(res.data.data.total);
          }
        },
      })
    );
  };

  useEffect(() => {
    allArticles();
  }, [search, currentPage]);

  return (
    <div className="dashPublishSec allarticle__ content-wrapper">
      <div className="flexBox">
        <h4 className="innerHeadText">All Articles</h4>
        <div>
          <button
            type="button"
            className="signUpBtn raise_Ticket_Btn me-3"
            onClick={() =>
              navigate("/select-publication", {
                state: {
                  type: "createArticle",
                },
              })
            }
          >
            Upload Article
          </button>
          <button
            type="button"
            className="signUpBtn raise_Ticket_Btn"
            onClick={() =>
              navigate("/select-publication", {
                state: {
                  type: "articleAI",
                },
              })
            }
          >
            Write Article Using AI
          </button>
        </div>
      </div>
      {articleData && articleData?.length === 0 && !search ? (
        ""
      ) : (
        <div className="searchSection mt-3 mb-3">
          <input
            className="form-control searchInput"
            type="text"
            placeholder="Search by name"
            value={search}
            onChange={handleSearchChange}
            // onChange={(e) => {
            //   setSearch(e.target.value);
            //   setCurrentPage(1);
            // }}
          />
          <img
            src={images.searchIcn}
            className="img-fluid searchIcnImg"
            alt="searchIcn image"
          />
          {/* Display the autocomplete suggestions */}
          {search && filteredSuggestions.length > 0 && (
            <ul className="autocomplete-list pointCursor">
              {filteredSuggestions.map((suggestion) => (
                <li
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="autocomplete-item"
                >
                  {suggestion?.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {articleSelector?.loading ? (
        <>
          <div className="loaderOuter d-flex justify-content-center text-light mt-5">
            <div className="spinner-grow text-light" role="status"></div>
          </div>
        </>
      ) : (
        <div class="boxes_new_section">
          <div className="row mt-3">
            {articleData?.length > 0 ? (
              articleData?.map((val, index) => {
                // console.log(val,"hhhhhhhhhhhhhhhh");

                // // Create a temporary element to parse the HTML content
                // const tempElement = document.createElement("div");
                // tempElement.innerHTML = val?.description;

                // // Extract text data by removing HTML tags
                // // const extractedText = useConvertMarkDownHtml(
                // //   tempElement.textContent || tempElement.innerText
                // // );

                // console.log('extractedText', tempElement )

                const converter = new Showdown.Converter();
                const convertedHtml = converter.makeHtml(val?.description);

                return (
                  // <div className="col-xl-6 col-lg-12 mt-3 " key={index}>
                  //   <div
                  //     className="articleInfo_"
                  //     onClick={() => {
                  //       navigate("/articlesDetails", {
                  //         state: {
                  //           articleInfo: val,
                  //         },
                  //       });
                  //     }}
                  //   >
                  //     <img
                  //       src={val?.photos}
                  //       alt="articleimageinfo_"
                  //       className="articleimageinfo_ img-fluid me-4"
                  //     />
                  //     <div className="publishInner position-relative">
                  //       <div className="flexBox article_Flexbox align-items-baseline">
                  //         <h4
                  //           className="stateText state_Txt"
                  //           data-toggle="tooltip"
                  //           data-placement="up"
                  //           title={val?.title}
                  //         >
                  //           {val?.title?.length < 50
                  //             ? val?.title
                  //             : `${val?.title?.slice(0, 50)}...`}
                  //         </h4>
                  //         <button
                  //           type="button"
                  //           className={
                  //             val?.status === "under_review"
                  //               ? "articlependingTkt"
                  //               : val?.status === "rejected"
                  //               ? "rejectTkt"
                  //               : "completeTkt"
                  //           }
                  //         >
                  //           {val?.status === "under_review"
                  //             ? "Under review"
                  //             : val?.status}
                  //         </button>
                  //       </div>
                  //       <p className="stateTextpara mt-2">
                  //         <span className="DescTicket">
                  //           {markDowntoText(
                  //             isExpanded || extractedText?.length <= 120
                  //               ? extractedText
                  //               : `${extractedText?.slice(0, 120)}...`
                  //           )}
                  //         </span>
                  //         {/* {extractedText?.length > 50 ? (
                  //           <span
                  //             to="#"
                  //             className="readBtn ms-2"
                  //             onClick={(e) => handleToggleExpand(e, val?._id)}
                  //           >
                  //             {isExpanded ? " Read Less" : "Read More"}
                  //           </span>
                  //         ) : null} */}
                  //       </p>
                  //       <h6 className="tableSubText articleDetail_Date">
                  //         {moment(val?.createdAt).format("MM/DD/YYYY")}
                  //       </h6>
                  //     </div>
                  //   </div>
                  //
                  // </div>

                  <div class="col-xl-6">
                    <div
                      class="row main_box_artical"
                      onClick={() => {
                        navigate(`/articlesDetails/${val?._id}`);
                        // navigate("/articlesDetails", {
                        //   state: {
                        //     articleInfo: val,
                        //   },
                        // });
                      }}
                    >
                      <div class="col-xxl-2 col-xl-3 col-md-2 col-sm-3  ps-0 articleImgBox_Parent">
                        <div class="box_img">
                          <img
                            src={val?.photos}
                            alt="articleimageinfo_"
                            className=""
                          />
                        </div>
                      </div>
                      <div class="col-xxl-10 col-xl-9 col-md-10 col-sm-9  position-relative pe-0">
                        <div class="box_content_area">
                          <div class="box_heading_button">
                            <h2 class="artical_heading">{val?.title}</h2>
                            <button
                              className={
                                val?.status === "under_review"
                                  ? "articlependingTkt"
                                  : val?.status === "rejected"
                                  ? "rejectTkt"
                                  : "completeTkt"
                              }
                              title={
                                val?.submittedForPublication && !val?.published
                                  ? "Submitted for Publication"
                                  : ""
                              }
                            >
                              {val?.status === "under_review"
                                ? "Under review"
                                : val?.submittedForPublication &&
                                  !val?.published
                                ? "Submitted..."
                                : val?.submittedForPublication && val?.published
                                ? "Published"
                                : val?.status}
                            </button>
                          </div>
                          <p
                            class="box_para"
                            dangerouslySetInnerHTML={{ __html: convertedHtml }}
                          ></p>
                          <span class="box_date">
                            {moment(val?.createdAt).format("MM/DD/YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div colSpan={8} className="noFoundHead">
                <p className="text-center noFoundText noNotificationsFound">
                  No Articles Found!
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {totalRecord && articleData && totalRecord > 10 && (
        <div className=" paginateSec">
          <ReactPaginate
            className="paginationBox mb-0"
            nextLabel=" >"
            onPageChange={handlePageChange}
            // curr
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< "
            renderOnZeroPageCount={null}
            forcePage={currentPage - 1}
          />
        </div>
      )}
    </div>
  );
};

export default Articles;

import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useDispatch } from "react-redux";
import TimezoneSelect from "react-timezone-select";
import { toast } from "react-toastify";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import { getSchedule } from "../../redux/slices/homePage";
import * as Image from "../../utilities/images";
import SheduleDemoForm from "./sheduleDemoForm";

const SheduleDemo = () => {
  document.title = "Schedule A Demo";
  const [value, setValue] = useState(new Date());
  const [slotList, setSlotList] = useState([]);
  const apiTimes = slotList.map((entry) => entry.time);
  const currentTime = new Date(); // Get the current time
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  const dispatch = useDispatch();
  const dateValue = moment(value).format("DD/MMM/YYYY");
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [flag, setFlag] = useState("");
  
  const handleContinue = (flag) => {
    setFlag(flag);
  };

  // Function to handle time selection
  const handleTimeSelection = (time) => {
    setSelectedTime(time);
  };

  // Function to format time as "HH:MM"
  const formatTime = (hour, minute) => {
    const paddedHour = hour.toString().padStart(2, "0");
    const paddedMinute = minute.toString().padStart(2, "0");
    return `${paddedHour}:${paddedMinute}`;
  };
  const todayCurrentTime = moment().format("HH:mm");
  // Set the cutoff time to 17:30
  const cutoffTime = "17:30";

  // Determine if the current time is past the cutoff time
  const isPastCutoff = todayCurrentTime > cutoffTime;

  // Generate time slots starting from current time to 17:30 with 30-minute gap for the current date
  // For the next date, generate time slots from 00:00 to 17:30
  const timeSlots = [];

  let hour = currentHour;
  let minute = Math.ceil(currentMinute / 30) * 30; // Adjust to the next 30-minute increment

  if (minute === 60) {
    minute = 0;
    hour++;
  }

  // If it's the current date, start generating time slots from the current time
  if (moment(value).isSame(moment(), "day")) {
    while (!(hour === 17 && minute > 30) && hour <= 17) {
      timeSlots.push(formatTime(hour, minute));
      minute += 30;
      if (minute >= 60) {
        minute = 0;
        hour++;
      }
    }
  } else {
    // For the next date, start generating time slots from 00:00
    hour = 0;
    minute = 0;
    while (!(hour === 17 && minute > 30) && hour <= 17) {
      timeSlots.push(formatTime(hour, minute));
      minute += 30;
      if (minute >= 60) {
        minute = 0;
        hour++;
      }
    }
  }

  const getScheduleSlotInfo = () => {
    let params = {
      date: dateValue,
    };
    dispatch(
      getSchedule({
        ...params,
        cb(res) {
          if (res.status) {
            setSlotList(res?.data?.data);
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    getScheduleSlotInfo();
  }, [dateValue]);
  // State to store the selected time
  const [selectedTime, setSelectedTime] = useState("");

  useEffect(() => {
    if (isPastCutoff) {
      setValue(moment().add(1, "days").toDate());
    }
  }, [isPastCutoff]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="nav_section_bg">
        <Navbar />
        <section className="scheduleDemoSection">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="headingGray32 text-uppercase mb-3">
                  Get In Touch
                </h3>
                <h2 className="heading48 mb-3">
                  Don’t wait to build a better approach to
                  <br /> PR and storytelling
                </h2>
              </div>
              <div><div className="row">
                <div className="col-lg-5 col-md-12">
                  <div className="storytellingLeft">
                    <h6 className="heading16 mb-4">
                      Sign up for a demo today and see how Memo can help you:
                    </h6>
                    <ul className="storytellingList">
                      <li>
                        Measure exactly how many people read your media coverage
                      </li>
                      <li>Get clear ROI numbers for your PR efforts</li>
                      <li>
                        Uncover data-driven insights to drive future PR
                        performance and strategy
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="timeSelector">
                    {flag === "form" ? (
                      <SheduleDemoForm date={value} time={selectedTime} />
                    ) : (
                      <div>
                        <div className="position-relative mb-4">
                          <img
                            src={Image.arrowdown}
                            className="timeZoneArrow pointHand"
                          />
                          <TimezoneSelect
                            value={selectedTimezone}
                            onChange={setSelectedTimezone}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                backgroundColor: "rgb(73, 73, 73)",
                                color: "#fff",
                                borderRadius: '12px', // Adjust the border radius as needed

                              }),
                              menuList: (base) => ({
                                ...base,
                                "::-webkit-scrollbar-track": {
                                  borderRadius: "50px"
                                },
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: state.isFocused || state.isSelected ? "#fff" : "white", // Change color for focused or selected items
                                background: state.isFocused || state.isSelected ? "#757575" : "", // Change background for focused or selected items
                                '&:hover': { backgroundColor: state.isSelected ? '#757575' : '' },
                                '&:hover': { backgroundColor: state.isFocused ? '#757575' : '' },
                              }),
                            }}
                          />
                        </div>
                        <div className="calanderOuter mb-4">
                          <Calendar
                            onChange={setValue}
                            value={value}
                            minDate={new Date()}
                          />

                          <ul className="timeZoneSelect">
                            {timeSlots.map((time, index) => {
                              // Check if the selected date is the current date
                              const currentDate =
                                moment().format("DD/MMM/YYYY");
                              const selectedDate =
                                moment(value).format("DD/MMM/YYYY");
                              const isCurrentDate =
                                selectedDate === currentDate;
                              // Check if the current time slot exists in the API response times for the current date
                              const isDisabledCurrentDate =
                                isCurrentDate && apiTimes.includes(time);

                              // Check if the current time slot exists in the API response times for the next date
                              const isNextDay = moment(value)
                                .add(1, "day")
                                .format("DD/MMM/YYYY");
                              const isDisabledNextDay =
                                !isCurrentDate && apiTimes.includes(time);

                              return (
                                <li
                                  key={index}
                                  className={`${selectedTime === time ? "active" : ""
                                    } ${isDisabledCurrentDate || isDisabledNextDay
                                      ? "disabledSlots"
                                      : ""
                                    }`}
                                  // Conditionally disable onClick if the time slot is disabled
                                  onClick={
                                    isDisabledCurrentDate || isDisabledNextDay
                                      ? null
                                      : () => handleTimeSelection(time)
                                  }
                                >
                                  {time}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="subscription_Continue_Btn">
                        <button
                          type="button"
                          className="secondry_btn"
                          onClick={() => {
                            // Check if a time slot is selected
                            if (selectedTime === "") {
                              // If no time slot is selected, show an error message
                              toast.error("Please select a time slot");
                            } else {
                              // If a time slot is selected, handle continue action
                              handleContinue("form");
                            }
                          }}
                        >
                          Continue
                        </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div></div>
              
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SheduleDemo;

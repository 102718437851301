import React, { useState } from "react";
import MainNav from "../common/MainNav";
import { Outlet } from "react-router-dom";
import { useAuthSelector } from "../redux/selector/auth";
import Sidebar from "../common/Sidebar";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const SubscriptionLayout = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const authSelector = useAuthSelector();
  const [hideShowDetails, sethideShowDetails] = useState("");

  // function for add active className in dashboard for expended screen
  const hideShow = (flag) => {
    sethideShowDetails(flag);
  };

  const isAuthenticated = Boolean(localStorage.getItem("authToken"));
  return (
    <>
      {/* {isAuthenticated ?<Sidebar/>:""} */}
      
        {isAuthenticated ? (
          <>
          <div className="mainBox subcription_Main">
            <Sidebar hideShowDetails={hideShowDetails} />
            <main
              className={
                hideShowDetails ? "dashboardMain full subcriptionLayout" : "dashboardMain hide  subcriptionLayout"
              }
            >
              <MainNav hideDetails={(flag) => hideShow(flag)} />
              <Elements stripe={stripePromise}>
                <Outlet />
              </Elements>
            </main>
            </div>
          </>
        ) : (
          <Elements stripe={stripePromise}>
          <Outlet />
        </Elements>
        )}
        
      
    </>
  );
};

export default SubscriptionLayout;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Image from "../../utilities/images";
const PaymentSuccessModal = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Close the modal and navigate to the homepage after 5 seconds
    const timer = setTimeout(() => {
      props.close();
      navigate("/subscriptions");
    }, 5000);

    // Cleanup the timeout if the component unmounts before the 5 seconds
    return () => clearTimeout(timer);
  }, [props, navigate]);
  
  return (
    <>
      <div className="paymentModalSec text-center payModal">
        <img
          src={Image.verifyImg}
          alt="verifyImg"
          className="img-fluid mt-4 mb-3 scheduleModalimg"
        />
        <p className="modalPara mb-5">Your payment is successful</p>
      </div>
    </>
  );
};

export default PaymentSuccessModal;

import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import { getClientLogos, newsletter } from "../../redux/slices/homePage";
import * as images from "../../utilities/images";
import dashboard from "../Images/Dashboard.png";
import ellipse from "../Images/Ellipse.png";
import clockIcon from "../Images/clockIcon.png";
import growthIcon from "../Images/growthIcon.png";
import searchLine from "../Images/search-line.png";
import setIcon from "../Images/setIcon.png";
import turn from "../Images/turn.png";
import videobanner from "../Images/videobanner.png";

const LandingPage = () => {
  document.title = "Home";
  const recaptcha = useRef(null);
  const navigate = useNavigate();
  const [recaptchaSize, setRecaptchaSize] = useState("normal");
  const [email, setEmail] = useState();
  const [clientLogos, setClientLogos] = useState([]);
  const [expandedIds, setExpandedIds] = useState({});
  const [termsCheck, setTermsCheck] = useState(false);
  const dispatch = useDispatch();

  const handleReadMoreClick = (id) => {
    setExpandedIds((prevExpandedIds) => ({
      ...prevExpandedIds,
      [id]: !prevExpandedIds[id],
    }));
  };

  const SubscribingContent = [
    {
      id: "0",
      contant: " Comprehensive Media Access",
      desctiption1:
        "Access to an unparalleled network of over 1000 digital publications, 100 TV stations, leading print magazines, and popular podcasts.",
      desctiption2:
        "Open doors for clients across various industries to make their mark in targeted and general audiences.",
    },
    {
      id: "1",
      contant: "Automated PR Campaign Management",
      desctiption1:
        "A fully automated platform that streamlines the entire PR process from submission to publication, saving agencies time and resources.",
      desctiption2:
        "Real-time tracking of article approval, submission, and publication stages, ensuring transparency and efficiency.",
    },
    {
      id: "2",
      contant: "Guaranteed Publication Without Pitching",
      desctiption1:
        "Eliminate the uncertainty of pitching with guaranteed publication slots, making planning and executing PR strategies easier.",
      desctiption2:
        "Secure placements for your clients' content, ensuring their message reaches its intended audience.",
    },
    {
      id: "3",
      contant: "SEO Enhancement",
      desctiption1:
        "Benefit from do-follow backlinks in high-authority publications, significantly boosting clients' SEO and online visibility.",
      desctiption2:
        "Enhance your clients' digital footprint and improve their search engine rankings.",
    },
    {
      id: "4",
      contant: "Fast Turnaround Times",
      desctiption1:
        "With articles going live within 24 hours in our basic package, capitalize on timely topics and trends.",
      desctiption2:
        "Provide your clients with the agility to respond to current events and stay relevant in their industry.",
    },
    {
      id: "5",
      contant: "Cutting-edge AI Content Creation",
      desctiption1:
        "Utilize OwnBuzz’s proprietary AI software to generate human-like articles in seconds, offering a scalable solution for content creation.",
      desctiption2:
        "Ensure content quality and consistency while managing multiple clients' campaigns.",
    },
    {
      id: "6",
      contant: "Customizable Content Formats",
      desctiption1:
        "Flexibility to choose between article or press release formats, tailoring the message to fit each client's unique brand voice and objectives.",
      desctiption2:
        "Craft narratives that resonate with the intended audience and align with strategic goals.",
    },
    {
      id: "7",
      contant: "Exclusive Agency Tools and Reports",
      desctiption1:
        "Access to detailed analytics and reports, providing insights into campaign performance and media reach.",
      desctiption2:
        "Use these tools to refine strategies, demonstrate value to clients, and make data-driven decisions.",
    },
    {
      id: "8",
      contant: "Scalable Subscription Plans",
      desctiption1:
        "Choose from a variety of subscription plans designed to scale with your agency's growth and the diverse needs of your clients.",
      desctiption2:
        "Enjoy the flexibility to upgrade or modify your plan as your agency takes on more clients or expands its services.",
    },
    {
      id: "9",
      contant: "Dedicated Support and Consultation",
      desctiption1:
        "Receive dedicated support from the OwnBuzz team, including access to PR and marketing experts who can assist with campaign strategy and optimization.",
      desctiption2:
        "Leverage professional insights to maximize your clients' media exposure and campaign success.",
    },
  ];

  function onChange(value) {
    const captchaValue = recaptcha.current.getValue();
    setTermsCheck(!!captchaValue); // Ensure termsCheck is a boolean
  }

  //form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter email");
      return;
    } else if (
      email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Please enter valid email address");
      return;
    } else if (!termsCheck) {
      toast.error("Please verify the recaptcha before submitting the form");
      return;
    }
    let params = {
      email: email.trim(),
    };

    dispatch(
      newsletter({
        ...params,
        cb(res) {
          if (res.status) {
            navigate("/");
            toast.success("Successfully subscribed");
            setTermsCheck(false);
            setEmail("");
            if (recaptcha.current) {
              recaptcha.current.reset();
            }
          } else {
            toast.error("Subscription failed");
          }
        },
      })
    );
  };

  const updateRecaptchaSize = () => {
    if (window.innerWidth <= 420) {
      setRecaptchaSize("compact");
    } else {
      setRecaptchaSize("normal");
    }
  };

  const getAllClientLogos = () => {
    dispatch(
      getClientLogos({
        cb(res) {
          if (res.status === 200) {
            setClientLogos(res?.data?.data);
          }
        },
      })
    );
  };

  const testimonial = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "red" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextArrow />,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2
        }
      },
    ]
  };

  useEffect(() => {
    // Update size on initial render
    updateRecaptchaSize();

    // Update size on window resize
    window.addEventListener("resize", updateRecaptchaSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateRecaptchaSize);
    };
  }, []);

  useEffect(() => {
    getAllClientLogos();
  }, []);

  return (
    <>
      <Navbar />
      <section className="banner home_Banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-7 col-md-12 banner_col_one">
              <div className="getseen_txt">
                <h2 className="subheading mt-4">Welcome to OwnBuzz</h2>
                <h3 className="medium_h mt-4">
                  Where Agencies Empower Brands to Shine!
                </h3>
                <p className="sub_p_text mt-4">
                  {" "}
                  OwnBuzz is redefining the media exposure game, offering
                  agencies a powerful tool to amplify their clients' brands
                  across a vast network of media platforms. With our
                  subscription service, agencies gain access to an unparalleled
                  array of over 1000 digital publications, 100 TV stations,
                  leading print magazines, and engaging podcasts. Our platform
                  is designed to enable agencies to craft and control the
                  narrative for their clients, ensuring their stories resonate
                  and create a buzz across the globe.
                </p>

                {/* <div className="searchinput_box text-start">
                  <div className="search_bar">
                    <input className="text-light" placeholder="Search Offers" />
                    <img src={searchLine} alt="logo" className="search_icon" />
                    <button type="Search" className="primary_btn">
                      Search
                    </button>
                  </div>
                </div> */}

                <div className="group_btn mt-5">
                  {/* <button
                    className="secondry_btn me-4"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    Get Started Now
                  </button>
                  <button
                    className="primary_btn"
                    onClick={() => {
                      navigate("/schedule-demo");
                    }}
                  >
                    Schedule A Demo 
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5 col-md-12 pe-0 banner_col_two">
              <div className="bannervideo">
                <img
                  src={videobanner}
                  alt="video"
                  className="video_img img-fluid"
                />
                <img
                  src={images.aboutrightimg_}
                  alt="vedio"
                  className="home_Page_Vedio"
                />
                <div className="vedioIcon_Parent">
                  <img src={images.vedioIcon} alt="" className="vedioIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slidebar">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              {/* <div className="slider-container">
                {clientLogos && clientLogos?.length > 0 && (
                  <marquee
                    scrollamount="10"
                    direction="left"
                    behavior="scroll"
                    loop="infinite"
                    className="p-2"
                  >
                    <div className="d-flex gap-5 align-items-center">
                      {clientLogos?.map((logo, index) => (
                        <a
                          key={index}
                          href={logo?.link}
                          className="marquee_Links"
                          target="_blank"
                        >
                          <div className="clientLogoOuter">
                            <img
                              src={logo?.logo}
                              alt="slider_img"
                              className="img-fluid w-100 h-100 home_slider_img"
                            />
                          </div>
                        </a>
                      ))}
                    </div>
                   </marquee>
                )}
              </div> */}
              <div className="slider-container">
                <Slider {...settings}>
                  {clientLogos?.map((logo, index) => (
                    <div className="p-2">
                      <a
                          key={index}
                          href={logo?.link}
                          className="marquee_Links"
                          target="_blank"
                        >
                          <div className="clientLogoOuter">
                            <img
                              src={logo?.logo}
                              alt="slider_img"
                              className="img-fluid w-100 h-100 home_slider_img"
                            />
                          </div>
                        </a>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* PR_Section */}
      <section className="PR_sec">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="pr_img text-center p-4">
                <img
                  src={dashboard}
                  alt="dashboard_img"
                  className="dashboard_img img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="PR_details text-start">
                <h2 className="second_big">
                  Experience the Future of <br />
                  PR – Fully Automated,
                  <br /> Effortlessly Efficient
                </h2>
                <p className="sub_p_text mt-4">
                  OwnBuzz introduces a revolutionary, fully automated PR service
                  tailored for agencies looking to streamline their clients'
                  media outreach. Experience seamless end-to-end order tracking,
                  from submission to publication, with guaranteed publication
                  and no need for pitching. With OwnBuzz, agencies can
                  efficiently manage their clients' campaigns, ensuring their
                  stories are always in the spotlight.
                </p>
              </div>
            </div>
          </div>

          {/* Start Strategic note section  */}
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="about_box">
                <div className="about_main">
                  <div className="about_h mt-3">
                    <h3 className="second_big text-center strategyText">
                      Strategic Publication in Top Media Outlets: Publish <br />{" "}
                      with Ease, Impact with Precision
                    </h3>
                  </div>
                  <div className="about_p mb-3">
                    <p className="sub_p_text text-center">
                      Navigate our extensive selection of media outlets easily,
                      choosing the perfect platforms to publish your clients'
                      articles. OwnBuzz is not just about spreading a message;
                      it's about creating a strategic movement. Benefit from
                      do-follow backlinks to bolster your clients' SEO,
                      positioning their brands where they belong: at the top.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Strategic note section  */}

          {/* Start Turnaround row start */}
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="turn_txt text-start">
                <h2 className="second_big">
                  Turnaround Time That <br /> Sets a New Standard
                </h2>
                <p className="turnaround_Txt sub_p_text mt-4">
                  In today's fast-moving world, timeliness is crucial. OwnBuzz
                  guarantees a swift publishing turnaround of 24 hours in our
                  basic package, allowing agencies to capitalize on timely
                  narratives and ensuring their clients' voices are heard at the
                  right moment.
                </p>
                <button
                  className="secondry_btn mt-5"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Let’s Start
                </button>
              </div>
            </div>
            <div className="col-md-6 pe-0">
              <div className="turn text-end">
                <img src={turn} alt="turn" className="turn_img img-fluid" />
              </div>
            </div>
          </div>
          {/* End Turnaround row start */}
        </div>
      </section>

      {/*PR END Article Section */}
      <section className="growth_sc home_Growth">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="time_Saved">
                <img
                  src={clockIcon}
                  alt="clockIcon"
                  className="public_icon mt-3"
                />
                <div className="time-saved-txt">
                  <p className="sub_p_text mb-0">
                    Time Saved with Our No-
                    <br /> Pitch Solution:
                  </p>
                  <span className="colored_txt">Average 6 Hours</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="growth_box">
                <img src={setIcon} alt="setIcon" className="public_icon" />
                <div className="time_saved_txt">
                  <p className="sub_p_text mb-0">
                    Increase in Work Efficiency:
                  </p>
                  <span className="colored_txt">Up to *96%</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="growth_box">
                <img
                  src={growthIcon}
                  alt="growthIcon"
                  className="public_icon"
                />
                <div className="time_saved_txt">
                  <p className="sub_p_text mb-0">
                    Time to See Initial Results:
                  </p>
                  <span className="colored_txt">As fast as 24 Hours</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Start Innovative section */}
      <section className="Innovative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="Innovative_Box">
                <img
                  src={images.innovationBg}
                  alt="Innovative"
                  className="InnovativeImg img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="Innovative_details">
                <div className="Innovative_txt">
                  <h2 className="second_big">
                    Innovative AI-Powered Content Creation for Clients
                  </h2>
                  <p className="sub_p_text mt-4">
                    Harness the power of AI with OwnBuzz’s proprietary software,
                    designed to generate compelling, human-like articles in
                    seconds. This tool enables agencies to produce high-quality
                    content for their clients that resonates with audiences
                    while passing as 100% human-crafted. Alternatively, agencies
                    can upload custom-written articles to maintain a personal
                    touch{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-start ">
            <div className="col-md-6">
              <div className="Customization_box mt-5">
                <h2 className="second_big">
                  Customization at Its Core; Flexibility to Match Your Message
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Customization_box">
                <p className="sub_p_text">
                  OwnBuzz values the unique story each brand has to tell.
                  Agencies can choose between article or press release formats,
                  customizing the message to fit their clients' specific goals
                  and brand voice. Our platform offers the flexibility and tools
                  needed to tailor content that aligns with strategic
                  objectives, ensuring impactful narratives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="subscribingSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="subscribingSection_txt">
                <h1 className="second_big text-center">
                  Perks of Subscribing to OwnBuzz for PR
                  <br /> and Marketing Agencies
                </h1>
                <p className="sub_p_text text-center mt-4">
                  Subscribing to OwnBuzz offers PR and marketing agencies a
                  competitive edge in the fast-paced brand promotion and
                  <br /> media outreach world. Here are the key benefits that
                  agencies can leverage to maximize their clients' success:
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <Slider {...testimonial}>
              {SubscribingContent?.map((data,idx) => {
                const isExpanded = expandedIds[data?.id];
                return (
                  <div className="col-lg-4 col-md-12 col-sm-12" key={idx}>
                    <div className="slider_box change">
                      <div className="img_box">
                        <h3 className="slider_Heading medium_h">
                          {data?.contant}
                        </h3>
                      </div>

                      <div className="slider_txt">
                        <p className="small-p">{data?.desctiption1}</p>
                        {isExpanded && (
                          <p className="small-p">{data?.desctiption2}</p>
                        )}

                        <button
                          className="read_btn mt-3"
                          onClick={() => handleReadMoreClick(data?.id)}
                        >
                          {isExpanded ? "Show Less" : "Read More"}
                          <img
                            className="d-inline-block ms-3"
                            src={images.readIcon}
                            alt="Read More Icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>

            <p className="sub_p_text text-center mt-5">
              By subscribing to OwnBuzz, PR and marketing agencies gain access
              to a suite of powerful tools and services and partner with a
              platform that is committed to redefining the landscape of digital
              and traditional media outreach. OwnBuzz empowers agencies to
              deliver exceptional results, ensuring their clients' brands are
              seen and heard.
            </p>
          </div>
        </div>
      </section>

      {/* section Testimonial */}
      <section className="testimonial">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-8 m-auto">
              <div className="popular_offer ">
                <h3 className="second_big text-center">
                  Our Clients Get Real Results
                </h3>
              </div>
            </div>
          </div>

          <div className="row">
            <Slider {...testimonial}>
              <div className="col-md-4">
                <div className="slider_box">
                  <div className="img_box">
                    <img src={ellipse} alt="ellipse" className="circle_img" />
                    <h3 className="ronand_Txt">Ronald Richards</h3>
                  </div>
                  <div className="pin_location">
                    <div>
                      <img src={images.map} alt="ellipse" className="pin" />
                    </div>
                    <span>US</span>
                  </div>
                  <div className="slider_txt">
                    <p className="sub_p_text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum gravida, ligula nec commodo aliquet, nulla erat
                      semper leo, in pellentesque enim metus at massa.
                    </p>
                    <img
                      src={images.quotesUp}
                      alt="quotesUp"
                      className="slider_Quotes_Up"
                    />
                    <img
                      src={images.quotesDown}
                      alt="quotesDown"
                      className="slider_Quotes_Down"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="slider_box">
                  <div className="img_box">
                    <img
                      src={images.profileImg}
                      alt="ellipse"
                      className="circle_img"
                    />
                    <h3 className="ronand_Txt">Ronald Richards</h3>
                  </div>
                  <div className="pin_location">
                    <img src={images.map} alt="ellipse" className="pin" />
                    <span>US</span>
                  </div>
                  <div className="slider_txt">
                    <p className="sub_p_text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum gravida, ligula nec commodo aliquet, nulla erat
                      semper leo, in pellentesque enim metus at massa.
                    </p>
                    <img
                      src={images.quotesUp}
                      alt="quotesUp"
                      className="slider_Quotes_Up"
                    />
                    <img
                      src={images.quotesDown}
                      alt="quotesDown"
                      className="slider_Quotes_Down"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="slider_box">
                  <div className="img_box">
                    <img
                      src={images.ourclient_SliderImg}
                      alt="ellipse"
                      className="circle_img"
                    />
                    <h3 className="ronand_Txt">Ronald Richards</h3>
                  </div>
                  <div className="pin_location">
                    <img src={images.map} alt="ellipse" className="pin" />
                    <span>US</span>
                  </div>
                  <div className="slider_txt">
                    <p className="sub_p_text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum gravida, ligula nec commodo aliquet, nulla erat
                      semper leo, in pellentesque enim metus at massa.
                    </p>
                    <img
                      src={images.quotesUp}
                      alt="quotesUp"
                      className="slider_Quotes_Up"
                    />
                    <img
                      src={images.quotesDown}
                      alt="quotesDown"
                      className="slider_Quotes_Down"
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="faq home_Faq">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-12">
              <div className="frequently">
                <h2 className="medium_h">FAQs (Frequently Asked Questions)</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                className="accordion yloreAccordion mt-5"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is OwnBuzz?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      OwnBuzz is a subscription-based platform that provides PR
                      and marketing agencies with unparalleled access to over
                      1000 digital publications, 100 TV stations, leading print
                      magazines, and popular podcasts. We offer an automated PR
                      service with guaranteed publication, fast turnaround
                      times, and innovative AI content creation to empower
                      agencies to amplify their clients' brands.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How does the automated PR service work?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Our automated PR service streamlines the submission,
                      tracking, and publication process. Once you upload your
                      article or press release, you can track its progress
                      through real-time approval, submission, and publication
                      stages. Our system ensures a smooth, transparent journey
                      from content creation to publication, with guaranteed
                      placement and no need for traditional pitching.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What types of content can I publish with OwnBuzz?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      OwnBuzz supports the publication of articles and press
                      releases. Our platform offers flexibility in content
                      format, allowing agencies to tailor their message
                      according to their client's brand voice and campaign
                      goals.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Can OwnBuzz help improve my clients' SEO?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Yes, one of the key benefits of publishing through OwnBuzz
                      is the provision of do-follow backlinks from
                      high-authority publications. This significantly boosts
                      your clients' SEO, improving their online visibility and
                      search engine rankings.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      What is the turnaround time for publication?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Our basic package offers a fast publishing turnaround,
                      with articles going live within 24 hours. This ensures
                      timely publication of your clients' content, enabling
                      leveraging current trends and news for maximum impact.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      How does the AI content creation feature work?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      OwnBuzz’s proprietary AI software can generate
                      high-quality, human-like articles in seconds. This tool is
                      designed to assist agencies in creating compelling content
                      efficiently, suitable for campaigns that require quick
                      turnaround without compromising on quality. Agencies also
                      have the option to upload custom-written articles.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Are there any subscription plans?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Yes, OwnBuzz offers scalable subscription plans to cater
                      to the diverse needs of PR and marketing agencies. Whether
                      you're a small agency looking to grow or a large firm
                      managing multiple clients, our plans are designed to scale
                      with your needs.s.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      How do I track the performance of my campaigns?
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Subscribers have access to detailed analytics and reports
                      that provide insights into campaign performance, media
                      reach, and audience engagement. These tools help agencies
                      refine their strategies and demonstrate the value of their
                      campaigns to clients.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      What support does OwnBuzz offer to agencies?
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      OwnBuzz provides dedicated support and consultation
                      services, including access to PR and marketing experts.
                      Our team is available to assist with campaign strategy and
                      content optimization and answer any questions about using
                      the platform.
                    </div>
                  </div>
                </div>

                <div className="accordion-item mt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      How can I subscribe to OwnBuzz?
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Subscribing to OwnBuzz is simple. Visit our website and
                      choose the subscription plan that fits your agency's needs
                      best. If you need help selecting a plan or have any
                      questions, our support team is ready to assist you.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="sub_p_text pt-5 text-left">
            OwnBuzz is committed to empowering PR and marketing agencies to
            maximize their clients' media exposure and campaign success. If you
            have any other questions, feel free to contact our support team for
            more information.
          </p>
        </div>
      </section>
      {/* End Faq  */}
      {/* Section Connect With US */}
      <section className="connect_US homeConnetUS_Margin">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="connect_parent">
                <h2 className="medium_h second_big">Join OwnBuzz Today</h2>
                <p className="sub_p_text mt-4">
                  Empower your clients with OwnBuzz and navigate the media
                  landscape with unparalleled ease and efficiency. Unlock
                  extensive media exposure, enhanced SEO, and a dominant brand
                  presence that distinguishes your clients in the competitive
                  digital age. Subscribe now and start your journey towards
                  creating lasting buzz for your clients.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="sign_up">
                  <h3 className="newsletter ">Newsletter Sign Up</h3>
                  <p className="big_story text-center sub_p_text">
                    Our biggest stories, delivered to your inbox everyday
                  </p>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                  <div className="captchaBox">
                    <ReCAPTCHA
                      size={recaptchaSize}
                      ref={recaptcha}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                      onChange={onChange}
                    />
                  </div>
                  <div className="sign_Submit">
                    <button className="submit sub_p_text" type="submit">
                      Submit
                    </button>
                  </div>
                  <p className="agreement mt-3">
                    By signing up you agree to our User Agreement, our
                    <Link className="otherPageLink" to="/privacy">
                      {" "}
                      Privacy Policy{" "}
                    </Link>{" "}
                    &{" "}
                    <Link className="otherPageLink" to="/termsConditions">
                      {" "}
                      Terms and Conditions{" "}
                    </Link>{" "}
                    and to receive marketing and account-related emails from
                    NewsAnchored. You can subscribe at any time.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LandingPage;

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { onErrorStopLoad, setCreateArticle, setDeleteDraft, setGetAllArticles, setGetArticleInfo, setUpdateArticleDraft, setUpdateDraft } from '../../slices/articles';

const queryString = require('query-string');

// Worker saga will be fired on USER_FETCH_REQUESTED actions


function* CreateArticle(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.articleApiPath.CREATE_ARTICLE, action.payload = action.payload);
    if (resp.status) {
      yield put(setCreateArticle(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.message);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCreateArticle({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.message);
  }
}

function* GetAllArticles(action) {
  let data = {...action.payload}
  delete data.cb
  let query = queryString.stringify(data);
  let url = `${ApiPath.articleApiPath.GET_ALL_ARTICLES}?${query}`;
  try {
    const resp = yield call(ApiClient.get, (action.url = url), action.payload = action.payload);
    if (resp.status) {
      yield put(setGetAllArticles(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetAllArticles({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.message);
  }
}
function* updateDrafts(action) {
  try {
     let data = {...action.payload}
  delete data.id
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.articleApiPath.UPDATE_DRAFT}/${action.payload.id}`),
      action.payload = data
    );
    if (resp.status) {
      yield put(setUpdateArticleDraft(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    }
    else {
      throw resp;
    }
  }
  catch (e) {
    yield put(setUpdateArticleDraft({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getArticleInfo(action) {
  try {
     let data = {...action.payload}
  delete data.id
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.articleApiPath.GET_ARTICLE_INFO}/${action.payload.id}`),
      action.payload = data
    );
    if (resp.status) {
      yield put(setGetArticleInfo(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    }
    else {
      throw resp;
    }
  }
  catch (e) {
    yield put(setUpdateArticleDraft({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* deleteDraft(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.articleApiPath.DELETE_DRAFT}/${action.payload.id}`)
    );
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.msg);
  } catch (e) {
    yield put(setDeleteDraft({}));
  }
}

function* articlesSaga() {
  yield all([
    takeLatest('article/createArticle', CreateArticle),
    takeLatest('article/getAllArticles', GetAllArticles),
    takeLatest('article/updateArticleDraft', updateDrafts),
    takeLatest('article/deleteDraft', deleteDraft),
    takeLatest('article/getArticleInfo', getArticleInfo),
    

    
  ])
}

export default articlesSaga;
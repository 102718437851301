import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  userInfo: {},
  verifyOtp: {},
  userSignUp: {},
  userForgetPassword: {},
  forgetPasswodVerifyOtp: {},
  resetPassword: {},
  isLoggedIn: false,
  loading: false,
  signup_loader: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    userLogin: (state) => {
      state.loading = true;
    },
    setUserLogin: (state, action) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.userInfo = action.payload;
    },
    userSignUp: (state) => {
      state.loading = true;
    },
    setUserSignUp: (state, action) => {
      state.loading = false;
      state.userSignUp = action.payload;
      state.userInfo = action.payload;
    },
    userOtp: (state) => {
      state.loading = true;
    },
    setUserOtp: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    },
    userVerifyOtp: (state) => {
      state.loading = true;
    },
    setUserVerifyOtp: (state, action) => {
      state.loading = false;
      state.verifyOtp = action.payload;
      // state.userStep = action.payload
    },
    userForgetPassword: (state) => {
      state.loading = true;
    },
    setUserForgetPassword: (state, action) => {
      state.loading = false;
      state.userForgetPassword = action.payload;
    },
    forgetPasswodVerifyOtp: (state) => {
      state.loading = true;
    },
    setForgetPasswodVerifyOtp: (state, action) => {
      state.loading = false;
      // state.isLoggedIn = true
      state.forgetPasswodVerifyOtp = action.payload;
    },
    resetPassword: (state) => {
      state.loading = true;
    },
    setResetPassword: (state, action) => {
      state.loading = false;
      state.resetPassword = action.payload;
    },
    logout: (state) => {
      state.loading = true;
    },
    setLogout: (state, action) => {
      state.loading = false;
      state.userInfo = {};
      state.isLoggedIn = false;
    },
    deleteAccount: (state, action)=>{
      state.loading = true;
    },
    setDeleteAccount: (state, action)=>{
      state.loading = false;
      state.userInfo = {};
    },
    changePassword: (state) => {
      state.loading = false;
    },
    setChangePassword: (state, action) => {
      state.loading = true;
    },
    changeEmail: (state) => {
      state.loading = false;
    },
    setChangeEmail: (state, action) => {;
      state.loading = true;
      state.userInfo = action.payload;
    },
    userProfileInfo: (state) => {
      state.loading = false;
    },
    setUserProfileInfo: (state, action) => {
      state.loading = true;
      state.userInfo = action.payload;
    },
    editUserProfileInfo: (state) => {
      state.loading = false;
    },
    setEditUserProfileInfo: (state, action) => {
      state.loading = true;
      state.userInfo = action.payload;
    },
    userDetail: (state) => {
      state.loading = true;
    },
    setuserDetail: (state) => {
      state.loading = false;
    },
    getAccessToken: (state) => {
      state.loading = true;
    },
    setGetAccessToken: (state) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
      state.signup_loader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userSignUp,
  setUserSignUp,
  userOtp,
  setUserOtp,
  userVerifyOtp,
  setUserVerifyOtp,
  userLogin,
  setUserLogin,
  userForgetPassword,
  setUserForgetPassword,
  forgetPasswodVerifyOtp,
  setForgetPasswodVerifyOtp,
  resetPassword,
  setResetPassword,
  logout,
  setLogout,
  changePassword,
  setChangePassword,
  userProfileInfo,
  setUserProfileInfo,
  onErrorStopLoad,
  editUserProfileInfo,
  setEditUserProfileInfo,
  deleteAccount,
  setDeleteAccount,
  changeEmail,
  setChangeEmail,
  userDetail,
  setuserDetail,
  getAccessToken,
  setGetAccessToken,
} = authSlice.actions;

export default authSlice.reducer;

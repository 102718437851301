import React, { useState } from "react";
import Sidebar from "../common/Sidebar";
import MainNav from "../common/MainNav";
import MainFooter from "../common/MainFooter";
import { Outlet, useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);
const DashboardLayout = () => {

  const [hideShowDetails, setHideShowDetails] = useState("");

  // function for add active className in dashboard for expended screen
  const hideShow = (flag) => {
    setHideShowDetails(flag);
  };
  
  return (
    <>
      <div className="mainBox">
        <Sidebar hideShowDetails={hideShowDetails}/>
        <main  className={hideShowDetails ? "dashboardMain full" :"dashboardMain hide"}>

          <MainNav hideDetails={hideShow} />
          <Elements stripe={stripePromise}>
          <Outlet />
          </Elements>
        </main>
      </div>
    </>
  );
};

export default DashboardLayout;

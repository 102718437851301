import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../common/Footer";
import Navbar from "../../../common/Navbar";
import { useHomePageSelector } from "../../../redux/selector/homePage";
import { contactUs } from "../../../redux/slices/homePage";

const ContactUs = () => {
  document.title = "Contact Us";
  const homeSelector = useHomePageSelector("");
  const { loading } = homeSelector;
  const [phone_no, setPhone_no] = useState("");
  const [dial_code, setDial_code] = useState("");
  const toastId = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    companyName: "",
    textArea: "",
  });

  /**
   * form on change
   * @param {*} value
   * @param {*} data
   */
  const handleOnChange = (value, data) => {
    setPhone_no(value);
    setDial_code(data.dialCode);
  };
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  //onchange input
  const handleChange = (e, fieldname) => {
    const { value, name } = e.target;
    let filteredValue = value;

    // Check if the field is the first name or last name
    if (fieldname === "fullName" || fieldname === "company") {
      // Filter out non-alphanumeric characters
      filteredValue = value?.replace(/[^a-zA-Z\s]/g, "");
    }

    setFormData({ ...formData, [name]: filteredValue });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.fullName || formData.fullName.trim() === "") {
      showToast("Please enter full name");
      return;
    } else if (!formData.email) {
      showToast("Please enter email");
      return;
    } else if (
      formData.email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formData.email
      )
    ) {
      showToast("Please enter valid email address");
      return;
    } else if (phone_no === undefined) {
      showToast("Please enter phone number");
      return;
    } else if (phone_no.length < 11) {
      showToast("Please enter valid phone number");
      return;
    } else if (!formData.companyName) {
      showToast("Please enter company name");
      return;
    } else if (!formData.textArea) {
      showToast("Please enter description");
      return;
    }

    let params = {
      submissionType: "contactUs",
      fullName: formData.fullName,
      email: formData.email.trim(),
      dialCode: dial_code,
      phoneNo: phone_no,
      companyName: formData.companyName,
      message: formData.textArea,
    };
    dispatch(
      contactUs({
        ...params,
        cb(res) {
          if (res.status) {
            navigate("/");
            toast.success("Contact Us Form Submitted Successfully");
          }
        },
      })
    );
  };
  return (
    <>
      <div className="contactPage nav_section_bg">
        <Navbar />
        <section className="contactUsSec mt-2">
          <div className="contactBox">
            <h1 className="authheading">Contact Us</h1>
            <p className="authPara mt-3">
              Please fill the following information
            </p>
            <form className="authForm" onSubmit={(e) => handleSubmit(e)}>
              <div className="signupForm">
                <div className="row mt-2">
                  <div className="col-lg-12 mt-3">
                    <div className="signupField">
                      <label for="email" className="input_Label">
                        Name:
                      </label>
                      <input
                        type="Text"
                        className="form-control nameControl"
                        placeholder="Enter Full Name"
                        onChange={(e) => handleChange(e, "fullName")}
                        maxLength={50}
                        value={formData.fullName}
                        name="fullName"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mt-3">
                    <div className="signupField">
                      <label for="email" className="input_Label">
                        Email:
                      </label>
                      <input
                        type="email"
                        className="form-control nameControl"
                        placeholder="Enter Email Address"
                        onChange={(e) => handleChange(e)}
                        value={formData.email}
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <label for="email" className="input_Label">
                      Phone:
                    </label>
                    <div className="col-lg-12   d-flex justify-content-end">
                      <div className="phoneControl">
                        <PhoneInput
                          onChange={handleOnChange}
                          country="us"
                          className="inputTel"
                          placeholder="Enter Phone Number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField ">
                      <label for="email" className="input_Label">
                        Company Name:
                      </label>
                      <input
                        type="Text"
                        className="form-control nameControl "
                        placeholder="Company Name"
                        onChange={(e) => handleChange(e, "company")}
                        name="companyName"
                        value={formData.companyName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField ">
                      <label for="email" className="input_Label">
                       Description:
                      </label>
                      <textarea
                        className="form-control nameControl"
                        placeholder="Write your message here..."
                        rows={5}
                        onChange={(e) => handleChange(e)}
                        value={formData.textArea}
                        name="textArea"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-4">
                    <button type="submit" className="signUpBtn w-100">
                      Submit
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-2"></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;

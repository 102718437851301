import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  onErrorStopLoad,
  setChangePassword,
  setEditUserProfileInfo,
  setForgetPasswodVerifyOtp,
  setLogout,
  setResetPassword,
  setUserForgetPassword,
  setUserLogin,
  setUserOtp,
  setUserProfileInfo,
  setUserSignUp,
  setUserVerifyOtp,
  setDeleteAccount,
  setChangeEmail,
  setuserDetail,
  setGetAccessToken,
} from "../../slices/auth";

function* userSignUp(action) {
  console.log(action,"usersingupAction")
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.SIGNUP_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUserSignUp(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* userOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.OTP_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      localStorage.setItem(
        "authToken",
        resp.data?.data?.token ? resp?.data?.data?.token : ""
      );
      yield put(setUserOtp(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action?.res?.data?.message);
      // toast.success("OTP Verified Sucessfullly! ")
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUserOtp({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* verifyOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.OTP_VERIFY_USER),
      (action.payload = action.payload)
    );
    if (resp.status == 200) {
      // localStorage.setItem("authToken", resp?.data?.payload?.token ? resp.data.payload.token : "")
      yield put(setUserVerifyOtp(resp?.data?.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action?.res?.data?.message);
      // toast.success("OTP sent successfully.")
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUserVerifyOtp({}));
    yield put(onErrorStopLoad());
    // toast.error(e?.response?.data?.message);
  }
}
// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userLogin(action) {
  try {
    // const resp = yield call(ApiClient.post, action.url = `${ApiPath.CREATE_USER}/name=${action.payload.name}`);
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.LOGIN_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      localStorage.setItem(
        "authToken",
        resp.data?.data?.token ? resp?.data?.data?.token : ""
      );
      yield put(setUserLogin(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e?.response?.data)
    yield put(onErrorStopLoad());
    toast.dismiss()
    toast.error(e?.response?.data?.message);
  }
}

function* userForgetPasswords(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.FORGET_PASSWORD_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUserForgetPassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    console.log(e,"errorrrrrrrr");
    
    yield put(setUserForgetPassword({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* forgetPassswordVerifyOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.FORGET_PASSWORD_VERIFY),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setForgetPasswodVerifyOtp(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
      // toast.success("OTP verified")
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setForgetPasswodVerifyOtp({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* resetPassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RESET_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setResetPassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setResetPassword({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* userLogout(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.LOGOUT_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      localStorage.removeItem("authToken");
      localStorage.removeItem("persist:root");
      yield put(setLogout());
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.msg);
  }
}

function* ChangePassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.CHANGE_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setChangePassword(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setChangePassword({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* UserProfileInfo(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.AuthApiPath.USER_PROFILE_INFO}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setUserProfileInfo(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUserProfileInfo({}));
    yield put(onErrorStopLoad());
    // toast.error(e?.response?.data?.msg);
  }
}

function* EditUserProfileInfo(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.AuthApiPath.EDIT_PROFILE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setEditUserProfileInfo(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setEditUserProfileInfo({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* changeEmail(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.AuthApiPath.CHANGE_EMAIL),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setChangeEmail(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setChangeEmail({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}
function* deleteAccount(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.AuthApiPath.DELETE_ACCOUNT}/${action.payload.id}`),
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      localStorage.clear();
      toast.success(action?.res?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setDeleteAccount({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* userDetail(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.AuthApiPath.USER_DETAIL}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setuserDetail(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setuserDetail({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* getAccessToken(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.ACCESS_TOKEN),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetAccessToken(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetAccessToken({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* authSaga() {
  yield all([
    takeLatest("auth/userSignUp", userSignUp),
    takeLatest("auth/userOtp", userOtp),
    takeLatest("auth/userVerifyOtp", verifyOtp),
    takeLatest("auth/userLogin", userLogin),
    takeLatest("auth/userForgetPassword", userForgetPasswords),
    takeLatest("auth/forgetPasswodVerifyOtp", forgetPassswordVerifyOtp),
    takeLatest("auth/resetPassword", resetPassword),
    takeLatest("auth/logout", userLogout),
    takeLatest("auth/changePassword", ChangePassword),
    takeLatest("auth/userProfileInfo", UserProfileInfo),
    takeLatest("auth/editUserProfileInfo", EditUserProfileInfo),
    takeLatest("auth/deleteAccount", deleteAccount),
    takeLatest("auth/changeEmail", changeEmail),
    takeLatest("auth/userDetail", userDetail),
    takeLatest("auth/getAccessToken", getAccessToken),
  ]);
}

export default authSaga;

import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWebSelector } from "../../../redux/selector/web";
import { getPublication } from "../../../redux/slices/web";
import * as images from "../../../utilities/images";
import PublicationGenreModal from "../../Modal/PublicationGenreModal";
import CustomModal from "../../Modal/CustomModal";

const Publication = () => {
  const dispatch = useDispatch();
  const paginateRef = useRef(null);
  const navigate = useNavigate();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [publicateInfo, setPublicateInfo] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [allCategoryNames, setAllCategoryNames] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [publicationPriceType, setPublicationPriceType] = useState("premium");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const PublicationsType = (flag) => {
    setPublicationPriceType(flag);
    setPageCount(0);
    setPage(1);
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  /**
   * To get all publications
   */

  const getAllPublication = () => {
    let params = {
      limit: 10,
      page: page,
      publicationType: publicationPriceType,
    };
    if (search) {
      params.search = search;
    }
    dispatch(
      getPublication({
        ...params,
        cb(res) {
          if (res.status) {
            setPublicateInfo(res?.data?.data?.data);
            setPageCount(res?.data?.data?.total_pages);
          }
        },
      })
    );
  };

  useEffect(() => {
    getAllPublication();
  }, [page, search, publicationPriceType]);

  //open modal
  const handleOpenModal = (flag, data) => {
    setAllCategoryNames(data);
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  return (
    <>
      <section className="content-wrapper publicationSec">
        <div className="innerHead">
          <img
            src={images.arrowLink}
            className="img-fluid pointCursor"
            alt="arrowLink image"
            onClick={() => {
              navigate(-1);
            }}
          />
          <h2 className="innerHeadText">All Publications</h2>
        </div>
        <div className="searchBox">
          <input
            className="form-control searchInput"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <img
            src={images.searchIcn}
            className="img-fluid searchIcnImg"
            alt="searchIcn image"
          />
        </div>
        <div className="priceInput mt-3 mb-3 publication_Premium">
          <div>
            <input
              type="radio"
              id="Free"
              name="radio-group"
              checked={publicationPriceType === "free"}
              onClick={() => PublicationsType("free")}
            />
            <label htmlFor="Free" className="premiumLabel free text-white">
              Free
            </label>
          </div>
          <div className="premiumLabel_Parent">
            <input
              type="radio"
              id="Premium"
              name="radio-group"
              checked={publicationPriceType === "premium"}
              onClick={() => PublicationsType("premium")}
            />
            <label htmlFor="Premium" className="premiumLabel text-white">
              Premium
            </label>
          </div>
        </div>
        <div className="table-responsive recentTable publishTable">
          <table className="table table-dark m-0">
            <thead>
              <tr className="recentRow">
                <th className="recentHead">NAME</th>
                {publicationPriceType === "premium" && (
                  <th className="recentHead">Price For Premium</th>
                )}
                <th className="recentHead">GENRE</th>
                <th className="recentHead">TYPE</th>
                {/* <th className="recentHead">Price For Premium</th> */}
                <th className="recentHead">DA</th>
                <th className="recentHead">REGION</th>
                <th className="recentHead">WEBSITE URL</th>
                <th className="recentHead">Time</th>
                <th className="recentHead">Sponsored</th>
                <th className="recentHead">Indexed</th>
                <th className="recentHead">NICHE</th>
                <th className="recentHead">FOLLOW</th>
                <th className="recentHead">IMAGE</th>
                <th className="recentHead">Rating</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {loading ? (
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center text-light">
                      <div
                        className="spinner-grow text-light"
                        role="status"
                      ></div>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {publicateInfo?.length > 0 ? (
                    publicateInfo?.map((val, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className="recentSubHead align-middle">
                              <div className="namePublish">
                                <figure className="publishOuter">
                                  <img
                                    src={val?.publicationLogo}
                                    className="img-fluid publishTableImg"
                                    alt="searchIcn image"
                                  />
                                </figure>
                                <h6 className="tableSubText">{val?.name}</h6>
                              </div>
                            </td>
                            {publicationPriceType === "premium" && (
                              <td className="recentSubHead align-middle" >
                                <h6 className="tableSubText pointCursor"  title={val?.amount?.toString().length > 5 ? `Price: $${val?.amount}` : ''}>
                               { `${
                                      val?.amount?.toString().length > 5
                                        ? `$${val?.amount
                                            ?.toString()
                                            .slice(0, 5)}...`
                                        : `$${val?.amount}`
                                    }`}
                                </h6>
                              </td>
                            )}


                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                                {val?.categoryIds?.length === 1 ? (
                                  <h4 className="tableSubText">
                                    {val?.categoryIds[0]?.categoryName || "---"}
                                  </h4>
                                ) : (
                                  <p className="tableSubText">
                                    {`${val?.categoryIds[0]?.categoryName}`}
                                    &nbsp;&nbsp;
                                    <span
                                      onClick={() => {
                                        handleOpenModal(
                                          "publicationGenreModal",
                                          val?.categoryIds
                                        );
                                      }}
                                      className="view-all-genres pointCursor"
                                    >
                                      +{val?.categoryIds?.length - 1}
                                    </span>
                                  </p>
                                )}
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                                {val?.subCategoryName || "-----"}
                              </h6>
                            </td>

                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.da}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.region}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                              <a
                                  className="form_subheading"
                                  href={val?.websiteUrl}
                                  target="_blank"
                                >
                                  {val?.websiteUrl
                                  ? val?.websiteUrl?.length <= 20
                                    ? val?.websiteUrl
                                    : `${val?.websiteUrl?.slice(0, 20)}...`
                                  : "-----"}
                                </a>
                                {" "}
                                
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">
                                {val?.selectTime}
                              </h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.sponsored}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.indexed}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.niche}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.doFollow}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h6 className="tableSubText">{val?.image}</h6>
                            </td>
                            <td className="recentSubHead align-middle">
                              <div className="ratingBx">
                                <img
                                  src={images.starImg}
                                  alt="starImage"
                                  className="img-fluid"
                                />
                                <h6 className="tableSubText">{val?.rating}</h6>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <td colSpan={20} className="noFoundHead">
                      <p className="text-center noFoundText ">
                        No Publications Found!
                      </p>
                    </td>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        {publicateInfo?.length > 0 && (
          <div className=" paginateSec">
            <ReactPaginate
              className="paginationBox mb-0"
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              forcePage={page - 1}
            />
          </div>
        )}
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "publicationGenreModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "publicationGenreModal"
            ? "deletCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "publicationGenreModal" ? (
            <PublicationGenreModal
              allCategoryNames={allCategoryNames}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "publicationGenreModal" ? (
            <>
              <h2 className="modal_Heading">Publication Genres</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Publication;

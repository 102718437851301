import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import { onErrorStopLoad, userLogin } from "../../redux/slices/auth";
import * as Image from "../../utilities/images";

const Login = () => {
  const toastId = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const { loading } = authSelector;
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState("false");
  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };
  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // validation of input fields
  const validateForm = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!formData.email) {
      showToast("Please enter your email.");
      return;
    } else if (!pattern.test(formData.email.trim())) {
      showToast("Please enter valid email");
      return;
    } else if (!formData.password) {
      showToast("Please enter your password.");
      return;
    }
    return validateForm;
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = {
        email: formData.email.trim(),
        password: formData.password,
      };
      dispatch(
        userLogin({
          ...params,
          cb(ress) {
            if (ress.status === 200) {
              localStorage.setItem("authToken", ress.data.data.token);
              if (location.state === "Plan") {
                navigate("/subscription");
              } else {
                navigate("/dashboard");
              }
            } else if (ress?.data && ress?.data?.isverified === false) {
              navigate("/verification", {
                state: {
                  email: formData?.email,
                  type: "verify",
                },
              });
            }
          },
        })
      );
    }
  };

  // stop loader on page load
  useEffect(() => {
    document.title = "Login";
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <section className="authbgSection authOtherSection authLoginSec">
        <Link to="/">
          <img
            src={Image.authlogo}
            alt="authLogo"
            className="authLogoImg img-fluid"
          />
        </Link>
        <div className="authOuterSec">
          <div className="signupSection authInnerSec">
            <h1 className="authheading"> Welcome Back!</h1>
            <p className="authPara mt-3">
              Please enter your email address & password for continue.
            </p>
            <form
              className="authForm"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="signupForm">
                <div className="row mt-2">
                  <div className="col-lg-12 mt-3">
                    <div className="signupField">
                      {/* <label for="email" className="input_Label">Email:</label> */}
                      <input
                        type="email"
                        className="form-control nameControl"
                        placeholder="Enter Email Address"
                        value={formData.email}
                        name="email"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="signupField position-relative">
                      {/* <label for="password" className="input_Label">Password:</label> */}
                      <input
                        type={showPassword ? "password" : "text"}
                        className="form-control nameControl "
                        placeholder="Enter Your Password"
                        name="password"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formData.password}
                      />
                      <img
                        src={showPassword ? Image.hiddenEye : Image.showEye}
                        alt="showEyeImage"
                        className="img-fluid eyeIcon"
                        onClick={togglePasswordVisiblity}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-2 text-end">
                    <a href="/forgot-password" className="linkSub">
                      Forgot Password?
                    </a>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <button type="submit" className="signUpBtn w-100">
                      Login
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-2"></span>
                      )}
                    </button>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <p className="authPara mt-3">
                      Not a member?{" "}
                      <Link to="/signup" className="linkSub">
                        Signup Here
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;

import "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../redux/selector/auth";
import { useNotificateSelector } from "../redux/selector/notification";
import { onErrorStopLoad } from "../redux/slices/auth";
import { getNotification } from "../redux/slices/notification";
import * as images from "../utilities/images";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CustomModal from "../components/Modal/CustomModal";
import DeleteAccount from "../components/Modal/deleteAccount";
import LogoutModal from "../components/Modal/logoutModal";
import NotificationModal from "../components/Modal/NotificationModal";

const MainNav = ({ hideDetails }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useNotificateSelector();
  const authSelector = useAuthSelector();
  const pathName = window.location.pathname;
  const userInfo = authSelector?.userInfo?.userInfo;
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = useState(Math.random());
  const [Shown, setShown] = useState(false);
  const [notificationRead, setNotificationRead] = useState(false);
  const [notifyList, setNotifyList] = useState([]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const fetchNotification = () => {
    dispatch(
      getNotification({
        cb(res) {
          if (res?.status === 200) {
            setNotifyList(res?.data?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    const findRead = notifyList?.some((item) => item?.is_read == false);
    setNotificationRead(findRead ? true : false);
  }, [notifyList]);

  useEffect(() => {
    fetchNotification();

    const interval = setInterval(() => {
      fetchNotification();
    }, 10000); // 1 minute interval

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [notification?.success]);

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  /*mobile sidebar*/

  // Higher-order function to toggle the drawer state
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Update hideDetails after Shown changes
  useEffect(() => {
    hideDetails(Shown);
  }, [Shown, hideDetails]);

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
      <>
        <aside className="mainSidebar">
          <Link
            to="/dashboard"
            className="brandLink"
            onClick={toggleDrawer(false)}
          >
            <img
              src={images.sidebarLogo}
              className="img-fluid"
              alt="Brand Logo"
            />
          </Link>
          <div className="sidebarOuter">
            <div className="sidebarInner">
              <h4 className="sideHeading">ACTIVITY</h4>
              <ul className="sideListHead">
                <li className="sideListSub">
                  <Link
                    to="/dashboard"
                    className={
                      [
                        "/dashboard",
                        "/publication",
                        "/articlesCreation",
                        "/write-article",
                      ].includes(pathName)
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.dashImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">Dashboard</h6>
                  </Link>
                </li>
                <li className="sideListSub">
                  <Link
                    to="/articles"
                    className={
                      ["/articles", "/articlesDetails"].includes(pathName)
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.articleImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">Articles</h6>
                  </Link>
                </li>
                <li className="sideListSub">
                  <Link
                    to="/drafts"
                    className={
                      ["/drafts", "/draftDetail"].includes(pathName)
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.draftImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">Draft</h6>
                  </Link>
                </li>
                <li className="sideListSub">
                  <Link
                    to="/media"
                    className={
                      ["/media"].includes(pathName)
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.mediaImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">Media</h6>
                  </Link>
                </li>
                <li className="sideListSub">
                  <Link
                    to="/tickets"
                    className={
                      ["/tickets"].includes(pathName) ||
                      pathName.startsWith("/ticket-details")
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.ticketImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">tickets</h6>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebarInner">
              <h4 className="sideHeading">BILLING</h4>
              <ul className="sideListHead">
                <li className="sideListSub">
                  <Link
                    to="/subscriptions"
                    className={
                      ["/subscriptions"].includes(pathName)
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.subscriptImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">Subscription</h6>
                  </Link>
                </li>
                <li className="sideListSub">
                  <Link
                    to="/transactions"
                    className={
                      ["/transactions"].includes(pathName)
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.transactImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">Transactions</h6>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebarInner">
              <ul className="sideListHead">
                <li className="sideListSub">
                  <Link
                    to="#"
                    className={
                      ["#"].includes(pathName)
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={() => {
                      handleOpenModal("logoutModal");
                    }}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.logoutImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">Logout</h6>
                  </Link>
                </li>
                <li className="sideListSub">
                  <Link
                    to="#"
                    className={
                      ["#"].includes(pathName)
                        ? "sidebarLink active"
                        : "sidebarLink"
                    }
                    onClick={() => {
                      handleOpenModal("deleteModal");
                    }}
                  >
                    <figure className="sideImg">
                      <img
                        src={images.deleteSideImg}
                        className="img-fluid sideLogoImg"
                        alt="dashImg image"
                      />
                    </figure>
                    <h6 className="sideLinkText">Delete Account</h6>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      </>
    </Box>
  );

  return (
    <>
      <section className="mainNavbar">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-9 col-7">
            <div className="navLeft">
              {/* {!["/subscription"].includes(pathName) && ( */}
              <div
                className="nav-link dashBar_  webBar"
                data-widget="pushmenu"
                to="#"
                role="button"
                onClick={() =>
                  Shown == false ? setShown(true) : setShown(false)
                }
              >
                <i className="fas text-white fa-bars"></i>
              </div>
              {/* )} */}
              <Link
                className="nav-link dashBar_ mobileBar"
                onClick={toggleDrawer(true)}
              >
                <i className="fas text-white fa-bars"></i>
              </Link>
              <Drawer
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                  zIndex: 1055,
                  // "& .MuiDrawer-root.MuiDrawer-modal.MuiModal-root.css-1cmmxwc-MuiModal-root-MuiDrawer-root": {
                  //   zIndex: 1057
                  // }
                }}
              >
                {DrawerList}
              </Drawer>

              <img
                src={images.handImg}
                className="img-fluid handImg"
                alt="handImg Logo"
              />
              <h2 className="profileText profile_Txt">
                Welcome back , {userInfo?.firstName} {userInfo?.lastName}
              </h2>
            </div>
          </div>
          <div className="col-lg-5 col-md-3 col-5">
            <div className="navRight">
              <button
                type="button"
                className="bellBtn position-relative"
                onClick={() => {
                  handleOpenModal("notificationModal");
                  // navigate("/notification");
                }}
              >
                <img
                  src={images.notifyImg}
                  alt="notification image"
                  className="img-fluid"
                />
                {notificationRead ? <span className="countValue"></span> : ""}
              </button>
              <button
                className="userProfileImg"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <img
                  src={userInfo?.profilePhoto}
                  alt="notification image"
                  className="userImage"
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "logoutModal"
            ? "deletCategoryModal"
            : modalDetail.flag === "deleteModal"
            ? "deletCategoryModal"
            : "notificationModal"
        }
        child={
          modalDetail.flag === "logoutModal" ? (
            <LogoutModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "deleteModal" ? (
            <DeleteAccount close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "notificationModal" ? (
            <NotificationModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "logoutModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to logout?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "deleteModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to Delete Account?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "notificationModal" ? (
            <>
              <h2 className="modal_Heading">Notifications</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default MainNav;

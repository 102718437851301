import React from "react";
import { Link } from "react-router-dom";
import * as images from "../utilities/images";

const Navbar = () => {
  const pathName = window.location.pathname;
  return (
    <>
      <div className="header">
        <div className="container ">
          <nav className="navbar navbar-expand-lg navbar-light m-0 navbarMain">
            <div className="logo_box me-auto">
              <Link to="/">
                <img src={images.ownbuzz} alt="logo" className="logo" />
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <div className="navbar">
                <ul className="navbar-nav ms-auto">
                  <li>
                    <Link
                      to="/"
                      className={
                        ["/"].includes(pathName)
                          ? "navbarLink active"
                          : "navbarLink"
                      }
                    >
                      {" "}
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/schedule-demo"
                      className={
                        ["/schedule-demo"].includes(pathName)
                          ? "navbarLink active"
                          : "navbarLink"
                      }
                    >
                      {" "}
                      Schedule A Demo 
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to="/subscription"
                      className={
                        ["/subscription"].includes(pathName)
                          ? "navbarLink active"
                          : "navbarLink"
                      }
                    >
                      {" "}
                      Pricing
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to="/about-us"
                      className={
                        ["/about-us"].includes(pathName)
                          ? "navbarLink active"
                          : "navbarLink"
                      }
                    >
                      {" "}
                      About Us
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to="/Contact-us"
                      className={
                        ["/Contact-us"].includes(pathName)
                          ? "navbarLink active"
                          : "navbarLink"
                      }
                    >
                      {" "}
                      Contact Us
                    </Link>{" "}
                  </li>
                  {/* <li>
                    <Link to="/login" className="navbarLink"> Login</Link>{" "}
                  </li> */}
                  <li className="nav_btn">
                    <Link to="/login">
                      {" "}
                      <button className="primary_btn me-2">Login</button>
                    </Link>
                    <Link to="/signup">
                      {" "}
                      <button className="secondry_btn">Sign Up</button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Navbar;

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CHILDCOLLECTIONNAME,
  PARENTCOLLECTIONNAME,
  db,
} from "../../../config/firebase-config";
import { getAllArticles } from "../../../redux/slices/articles";
import { createTicket } from "../../../redux/slices/ticket";
import * as Images from "../../../utilities/images";
import { sendMessageNotification } from "../../../redux/slices/notification";
import { useTicketSelector } from "../../../redux/selector/ticket";

const CreateTicket = () => {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ticketSelecter = useTicketSelector();
  const { loading } = ticketSelecter;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [articleData, setArticleData] = useState([]);
  const [selectedarticle, setSelectedArticleData] = useState("");

  // FUNCTION FOR CHNAGE CATEGORY AND UPDATE CATEGORY
  const handleCategoryChange = (id) => {
    setSelectedArticleData(id);
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  /***
   * to get all articles
   */

  const allArticles = () => {
    dispatch(
      getAllArticles({
        cb(res) {
          if (res.status) {
            setArticleData(res?.data?.data?.data);
          }
        },
      })
    );
  };

  /**
   *
   * to create ticket
   */
  const handleCreateTicket = () => {
    if (!selectedarticle) {
      showToast("Please select article");
      return;
    } else if (!title) {
      showToast("Please enter title");
      return;
    } else if (!description) {
      showToast("Please enter description");
      return;
    }

    const params = {
      articleId: selectedarticle,
      title: title,
      description: description,
    };
    dispatch(
      createTicket({
        ...params,
        cb(res) {
          if (res.status) {
            handleSendInitialMessage(res?.data?.data);
            navigate("/tickets", {
              state: {
                flag: "RaiseTicket",
              },
            });
          }
        },
      })
    );
  };

  const handleSendNotification = (data) => {
    let params = {
      sender_id: data?.users?._id,
      receiver_id: data?.adminInfo?._id,
      msg: `${description ? description : "sent a photo"}`,
      type: "ticketMessage",
      sender_image: data?.users?.profilePhoto,
      sender_name: `${data?.users?.firstName || ""} ${
        data?.users?.lastName || ""
      }`,
      notificationTypeId: selectedarticle,
    };
    dispatch(
      sendMessageNotification({
        ...params,
        cb(res) {
          if (res?.data) {
          }
        },
      })
    );
  };

  // send initial message
  const handleSendInitialMessage = async (data) => {
    try {
      const roomDocRef = doc(db, PARENTCOLLECTIONNAME, data?.ticketNo);
      const messagesCollectionRef = collection(roomDocRef, CHILDCOLLECTIONNAME);
      await setDoc(roomDocRef, {
        clearChat: false,
        deletedChatUserIds: [],
        lastMessage: {
          createdAt: Math.floor(Date.now()),
          senderId: data?.users?._id,
          text: data?.description,
          recieverId: data?.adminInfo?._id,
        },
        roomId: data?.ticketNo,
        unseenMessageCount: 1,
        user1: {
          full_name: `${data?.users?.firstName || ""} ${
            data?.users?.lastName || ""
          }`,
          id: data?.users?._id,
          onlineStatus: 1,
        },
        user2: {
          full_name: `${data?.adminInfo?.userInfo?.firstName || ""} ${
            data?.adminInfo?.userInfo?.lastName || ""
          }`,
          id: data?.adminInfo?._id,
          onlineStatus: 1,
        },
        users: [data?.users?._id, data?.adminInfo?._id],
      });

      await addDoc(messagesCollectionRef, {
        createdAt: Math.floor(Date.now()),
        text: data?.description,
        senderId: data?.users?._id,
        recieverId: data?.adminInfo?._id,
      });

      handleSendNotification(data);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    allArticles();
  }, []);

  return (
    <div className="content-wrapper ">
      <div className="content-header">
        <div className="innerHead">
          <img
            src={Images.arrowLink}
            className="img-fluid pointCursor"
            alt="arrowLink image"
            onClick={() => {
              navigate("/tickets");
            }}
          />
          <h2 className="innerHeadText">Raise a Ticket</h2>
        </div>

        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="addPublishLeft">
                <div className="row">
                  <div className="col-md-6">
                    <div className="searchBox">
                      <label for="email" className="input_Label">
                        Select Article
                      </label>
                      <FormControl>
                        <Select
                          className="customSelect"
                          value={selectedarticle}
                          onChange={(e) => handleCategoryChange(e.target.value)}
                          label="Category"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            fontFamily: "Poppins",
                            ".MuiSvgIcon-root": {
                              color: "#fff", // Change this to the desired color
                            },
                            fontStyle: "normal",
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                borderRadius: "12px",
                                border: "1px solid #FFFFFF4D",
                                background: "#616161",
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                marginTop: "5px",
                                " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                                  {
                                    color: "#ffffff",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    fontFamily: "inherit",
                                    marginTop: "3px",
                                    "&.Mui-selected": {
                                      backgroundColor: "#757575",
                                    },
                                    "&:hover": {
                                      backgroundColor: "#757575",
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor: "#757575",
                                    },
                                  },
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Article
                          </MenuItem>

                          {articleData?.map((category, idx) => (
                            <MenuItem value={category?._id} key={idx}>
                              {category.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="searchBox ">
                      <label for="email" className="input_Label">
                        Title
                      </label>
                      <input
                        className="form-control customInput"
                        type="text"
                        placeholder="Title here"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="searchBox textarea_ m-0 mb-3">
                  <label for="email" className="input_Label">
                    Description
                  </label>
                  <textarea
                    className="form-control customInput"
                    type="text"
                    placeholder="Write Description here.."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="raiseTicket_Btn_Parent">
                  <button
                    type="button"
                    onClick={() => navigate("/tickets")}
                    className="secondaryBtb_ mt-2 me-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => handleCreateTicket()}
                    className="signUpBtn mt-2"
                    disabled={loading}
                  >
                    Raise a Ticket
                    {loading && (
                      <span className="spinner-border spinner-border-sm ms-2"></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTicket;

import React, { useEffect, useRef, useState } from "react";
import * as images from "../../../utilities/images";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadArticleImage } from "../../../redux/slices/web";
import { useDispatch } from "react-redux";
import {
  createArticle,
  updateArticleDraft,
} from "../../../redux/slices/articles";
import { toast } from "react-toastify";

const AddOnArticle = () => {
  const toastId = useRef(null);
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedPublication } = location?.state || "";
  console.log(
    selectedPublication?.draftData?.newAddOns?.professional,
    "location123"
  );
  const { draftData } = location?.state?.selectedPublication || "";
  const { userInfo } = location?.state || "";
  const { htmlContent } = location?.state || "";
  const { selectedOptions } = location?.state || "";
  const { assistantCheckboxs } = location?.state || "";
  const { flag } = location?.state || "";
  console.log(draftData,userInfo, "flagflagbaaaa");

  const { addOnInfo } = location?.state || "";
  console.log(
    location?.state,

    "addOnInfo"
  );

  const [assistantCheckbox, setAssistantCheckbox] = useState({});
  console.log(assistantCheckbox, "assistantCheckbox");

  const [selectedOption, setSelectedOption] = useState({
    professional:
      selectedPublication?.draftData?.newAddOns?.professional ??
      selectedOptions?.professional ??
      addOnInfo?.newAddOns?.professional ??
      addOnInfo?.addOns?.professional ??
      userInfo?.newAddOns?.professional ??
      userInfo?.addOns?.professional ??
      draftData?.addOns?.professional ??
      false,
    interviewer:
      selectedPublication?.draftData?.newAddOns?.interviewer ??
      selectedOptions?.interviewer ??
      addOnInfo?.newAddOns?.interviewer ??
      addOnInfo?.addOns?.interviewer ??
      userInfo?.newAddOns?.interviewer ??
      userInfo?.addOns?.interviewer ??
      draftData?.addOns?.interviewer ??
      false,
    coverImage:
      selectedPublication?.draftData?.newAddOns?.coverImageFlag ??
      selectedOptions?.coverImage ??
      addOnInfo?.newAddOns?.coverImageFlag ??
      addOnInfo?.addOns?.coverImageFlag ??
      userInfo?.newAddOns?.coverImageFlag ??
      userInfo?.addOns?.coverImageFlag ??
      draftData?.addOns?.coverImageFlag ??
      false,
    coverImageUrl:
      addOnInfo?.addOns?.coverImageUrl ?? userInfo?.addOns?.coverImageUrl ?? "",
  });
  console.log(selectedOption, "selectedPublication3333");

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    let params = {
      images: imageFile,
    };
    dispatch(
      uploadArticleImage({
        ...params,
        cb(res) {
          if (res.status) {
            setSelectedOption({
              ...selectedOption,
              coverImageUrl: res?.data?.data?.[0],
            });
          }
        },
      })
    );
  };
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleAddOnArticle = () => {
    const publicationData = selectedPublication?.publicationIds?.map(
      (data) => data._id
    );
    if (
      selectedOption?.interviewer &&
      !assistantCheckbox?.needInterviewerAssistant?.includes(true)
    ) {
      showToast("Please select atleast 1 interviewer publication");
      return;
    }
    if (
      selectedOption?.professional &&
      !assistantCheckbox?.needProfessionalAssistant?.includes(true)
    ) {
      showToast("Please select atleast 1 professional publication");
      return;
    }

    if (
      selectedPublication?.type == "articleAI" ||
      addOnInfo?.articleType == "ai"
    ) {
      if (!htmlContent && !addOnInfo?.description) {
        showToast("No contents found");
        return;
      }
      if (
        flag === "AiArticle" ||
        selectedPublication?.draftData?.reject ||
        selectedPublication?.draftData
      ) {
        UpdateAIArticle(publicationData);
      } else {
        SubmitAIArticle(publicationData);
      }
    } else {
      // if (selectedOption?.coverImage && !selectedOption?.coverImageUrl[0]) {
      //   showToast("Please upload cover Image");
      //   return;
      // }
      SubmitArticle(publicationData);
    }
  };

  const SubmitArticle = (publicationData) => {
    let params = {
      userId:
        userInfo?.userId ||
        addOnInfo?.userId ||
        selectedPublication?.userInfo?.userId,
      id: userInfo?._id || addOnInfo?._id || selectedPublication?.userInfo?._id,
      articleType: "upload",
      creditImage: selectedPublication?.creditImgUpload || undefined,
      url: selectedPublication?.articleUrl,
      publicationId: publicationData,
      categoryName: selectedPublication?.categorydata?.name,
      categoryId: selectedPublication?.categorydata?._id,
      subCategoryName: selectedPublication?.subCategoryData?.name,
      subCategoryId: selectedPublication?.subCategoryData?._id,
      title: selectedPublication?.title || selectedPublication?.data?.title,
      description: selectedPublication?.ckEditorContent || undefined,
      googleDriveUrl: selectedPublication?.googleDriveUrl || undefined,
      photos: selectedPublication?.photo,
      multipleImages: selectedPublication?.multipleImages || undefined,
      needInterviewerAssistant: assistantCheckbox?.needInterviewerAssistant,
      needProfessionalAssistant: assistantCheckbox?.needProfessionalAssistant,
      saveAsDraft: "1",
      addOn: "1",
      professional: selectedOption?.professional,
      interviewer: selectedOption?.interviewer,
      coverImageFlag: selectedOption?.coverImage,
    };
    if (selectedOption?.coverImageUrl && selectedOption?.coverImage) {
      params = {
        ...params,
        coverImageUrl: selectedOption?.coverImageUrl,
      };
    }
    dispatch(
      updateArticleDraft({
        ...params,
        cb(res) {
          if (res?.data) {
            navigate("/addOn-Article-Detail", {
              state: {
                addOnInfo: res?.data?.data,
                selectedPublication,
                userInfo,
                htmlContent,
              },
            });
          }
        },
      })
    );
  };

  // API for create AI article ................
  const UpdateAIArticle = () => {
    const publicationData =
      addOnInfo?.publications?.map((data) => data?.publicationId) ||
      selectedPublication?.publicationIds?.map((data) => data?._id) ||
      [];
    let aiParams = {
      userId:
        userInfo?.userId ||
        addOnInfo?.userId ||
        selectedPublication?.userInfo?.userId ||
        selectedPublication?.draftData?.userId,
      id:
        userInfo?._id ||
        addOnInfo?._id ||
        selectedPublication?.userInfo?._id ||
        selectedPublication?.draftData?._id,
      articleType: "ai",
      creditImage:
        addOnInfo?.creditImgUpload || draftData?.creditImgUpload || undefined,
      url: addOnInfo?.articleUrl || draftData?.articleUrl,
      articleBy: addOnInfo?.articleBy || draftData?.articleBy,
      publicationId: publicationData || selectedPublication,
      categoryName:
        addOnInfo?.categoryName || selectedPublication?.categorydata?.name,
      categoryId:
        addOnInfo?.categoryId || selectedPublication?.categorydata?._id,
      subCategoryName: selectedPublication?.subCategoryData?.name,
      subCategoryId: selectedPublication?.subCategoryData?._id,
      photos: addOnInfo?.photos || draftData?.photos,
      description:
        htmlContent ||
        addOnInfo?.description ||
        selectedPublication?.ckEditorContent,
      saveAsDraft: "1",
      title: addOnInfo?.title || draftData?.title,
      addOn: "1",
      professional: selectedOption?.professional,
      interviewer: selectedOption?.interviewer,
      coverImageFlag: selectedOption?.coverImage,
      purpose:
        addOnInfo?.articlePurpose?.purpose ||
        selectedPublication?.data?.purpose,
      targetAudience:
        addOnInfo?.articlePurpose?.targetAudience ||
        selectedPublication?.data?.targetAudience,
      headline:
        addOnInfo?.headline || selectedPublication?.data?.headline || undefined, // optional
      mainMessage:
        addOnInfo?.keyMessages?.mainMessage ||
        selectedPublication?.data?.mainMessage,
      highlights:
        addOnInfo?.keyMessages?.highlights ||
        selectedPublication?.data?.highlights,
      desiredOutcomes:
        addOnInfo?.callToAction?.desiredOutcomes ||
        selectedPublication?.data?.desiredOutcomes,
      contactInfo:
        addOnInfo?.callToAction?.contactInfo ||
        selectedPublication?.data?.contactInfo,
      tone: addOnInfo?.Preferences?.tone || selectedPublication?.data?.tone,
      style: addOnInfo?.Preferences?.style || selectedPublication?.data?.style,
      additionalNote:
        addOnInfo?.additionalNote || selectedPublication?.data?.additionalNote,
      logo:
        addOnInfo?.attachments?.logo ||
        selectedPublication?.data?.logo ||
        undefined,
      files:
        addOnInfo?.attachments?.files ||
        selectedPublication?.data?.files ||
        undefined,
      needInterviewerAssistant: assistantCheckbox?.needInterviewerAssistant,
      needProfessionalAssistant: assistantCheckbox?.needProfessionalAssistant,
    };
    if (
      addOnInfo?.articleBy === "company" ||
      selectedPublication?.options === "company"
    ) {
      aiParams = {
        ...aiParams,
        industry:
          addOnInfo?.companiesData?.basicInfo?.industry ||
          selectedPublication?.data?.industry,
        companyName:
          addOnInfo?.companiesData?.basicInfo?.companyName ||
          selectedPublication?.data?.companyName,
        companyWebsite:
          addOnInfo?.companiesData?.basicInfo?.companyWebsite ||
          selectedPublication?.data?.companyWebsite,
        briefOverview:
          addOnInfo?.companiesData?.companyOverview?.briefOverview ||
          selectedPublication?.data?.briefOverview,
        foundHistory:
          addOnInfo?.companiesData?.companyOverview.foundHistory ||
          selectedPublication?.data?.foundHistory ||
          undefined, // optional
        achievements:
          addOnInfo?.companiesData?.companyOverview?.achievements ||
          selectedPublication?.data?.achievements,
        featuredProduct:
          addOnInfo?.companiesData?.products?.featuredProduct ||
          selectedPublication?.data?.featuredProduct,
        sellingPoints:
          addOnInfo?.companiesData?.products?.sellingPoints ||
          selectedPublication?.data?.sellingPoints,
        founderQuote:
          addOnInfo?.companiesData?.quotes?.founderQuote ||
          selectedPublication?.data?.founderQuote ||
          undefined, // optional
        optionalQuote:
          addOnInfo?.companiesData?.quotes?.optionalQuote ||
          selectedPublication?.data?.optionalQuote,
        companyMediaFeatures:
          addOnInfo?.companiesData?.media?.companyMediaFeatures ||
          selectedPublication?.data?.companyMediaFeatures,
        partnerhships:
          addOnInfo?.companiesData?.media?.partnerhships ||
          selectedPublication?.data?.partnerhships,
        companySocialMedialinks:
          addOnInfo?.companiesData?.media?.companySocialMedialinks?.[0] ||
          selectedPublication?.data?.companySocialMedialinks,
        preferredMedia:
          addOnInfo?.companiesData?.focus?.preferredMedia ||
          selectedPublication?.data?.preferredMedia ||
          undefined, // optional
        geographic:
          addOnInfo?.companiesData?.focus?.geographic ||
          selectedPublication?.data?.geographic,
      };
    } else {
      aiParams = {
        ...aiParams,
        fullName:
          addOnInfo?.individualsData?.basicInfo?.fullName ||
          selectedPublication?.data?.fullName,
        professionalTitle:
          addOnInfo?.individualsData?.basicInfo?.professionalTitle ||
          selectedPublication?.data?.professionalTitle,
        industry:
          addOnInfo?.individualsData?.basicInfo?.industry ||
          selectedPublication?.data?.industry,
        individualWebsite:
          addOnInfo?.individualsData?.basicInfo?.individualWebsite ||
          selectedPublication?.data?.individualWebsite,
        bio:
          addOnInfo?.individualsData?.personalInfo?.bio ||
          selectedPublication?.data?.bio,
        accomplishment:
          addOnInfo?.individualsData?.personalInfo?.accomplishment ||
          selectedPublication?.data?.accomplishment,
        philosophy:
          addOnInfo?.individualsData?.personalInfo?.philosophy ||
          selectedPublication?.data?.philosophy,
        currentPosition:
          addOnInfo?.individualsData?.professionalExperiennce
            ?.currentPosition || selectedPublication?.data?.currentPosition,
        previousRole:
          addOnInfo?.individualsData?.professionalExperiennce?.previousRole ||
          selectedPublication?.data?.previousRole ||
          undefined, // optional
        projects:
          addOnInfo?.individualsData?.professionalExperiennce?.projects ||
          selectedPublication?.data?.projects,
        quote:
          addOnInfo?.individualsData?.quote ||
          selectedPublication?.data?.quote ||
          undefined, // optional
        individualMediaFeature:
          addOnInfo?.individualsData?.media?.individualMediaFeature ||
          selectedPublication?.data?.individualMediaFeature,
        individualSocialMedialinks: [
          addOnInfo?.individualsData?.media?.individualSocialMedialinks ||
            selectedPublication?.data?.individualSocialMedialinks,
        ],
      };
    }
    if (selectedOption?.coverImageUrl.length > 0) {
      aiParams = {
        ...aiParams,
        coverImageUrl: selectedOption?.coverImageUrl,
      };
    }

    dispatch(
      updateArticleDraft({
        ...aiParams,
        cb(res) {
          if (res?.data) {
            navigate("/addOn-Article-Detail", {
              state: {
                addOnInfo: res?.data?.data,
                selectedPublication,
              },
            });
          }
        },
      })
    );
  };

  const SubmitAIArticle = (publicationData) => {
    let aiParams = {
      articleType: "ai",
      creditImage: selectedPublication?.creditImgUpload || undefined,
      url: selectedPublication?.data?.articleUrl,
      articleBy: selectedPublication?.options,
      publicationId: publicationData,
      categoryName:
        selectedPublication?.categorydata?.name ||
        selectedPublication?.draftData?.categoryName,
      categoryId:
        selectedPublication?.categorydata?._id ||
        selectedPublication?.draftData?.categoryId,
      subCategoryName: selectedPublication?.subCategoryData?.name,
      subCategoryId: selectedPublication?.subCategoryData?._id,
      photos: [selectedPublication?.photo],
      description:
        htmlContent ||
        addOnInfo?.description ||
        selectedPublication?.ckEditorContent,
      saveAsDraft: "1",
      title: selectedPublication?.title || selectedPublication?.data?.title,
      addOn: "1",
      professional: selectedOption?.professional,
      interviewer: selectedOption?.interviewer,
      coverImageFlag: selectedOption?.coverImage,
      purpose: selectedPublication?.data?.purpose,
      targetAudience: selectedPublication?.data?.targetAudience,
      headline: selectedPublication?.data?.headline || undefined, // optional
      mainMessage: selectedPublication?.data?.mainMessage,
      highlights: selectedPublication?.data?.highlights,
      desiredOutcomes: selectedPublication?.data?.desiredOutcomes,
      contactInfo: selectedPublication?.data?.contactInfo,
      tone: selectedPublication?.data?.tone,
      style: selectedPublication?.data?.style,
      additionalNote: selectedPublication?.data?.additionalNote,
      logo: selectedPublication?.data?.logo || undefined, // optional
      files: selectedPublication?.data?.files || undefined, // optional
      needInterviewerAssistant: assistantCheckbox?.needInterviewerAssistant,
      needProfessionalAssistant: assistantCheckbox?.needProfessionalAssistant,
    };
    if (selectedPublication?.options === "company") {
      aiParams = {
        ...aiParams,
        industry: selectedPublication?.data?.industry,
        companyName: selectedPublication?.data?.companyName,
        companyWebsite: selectedPublication?.data?.companyWebsite,
        briefOverview: selectedPublication?.data?.briefOverview,
        foundHistory: selectedPublication?.data?.foundHistory || undefined, // optional
        achievements: selectedPublication?.data?.achievements,
        featuredProduct: selectedPublication?.data?.featuredProduct,
        sellingPoints: selectedPublication?.data?.sellingPoints,
        founderQuote: selectedPublication?.data?.founderQuote || undefined, // optional
        optionalQuote: selectedPublication?.data?.optionalQuote,
        companyMediaFeatures: selectedPublication?.data?.companyMediaFeatures,
        partnerhships: selectedPublication?.data?.partnerhships,
        companySocialMedialinks:
          selectedPublication?.data?.companySocialMedialinks,
        preferredMedia: selectedPublication?.data?.preferredMedia || undefined, // optional
        geographic: selectedPublication?.data?.geographic,
      };
    } else {
      aiParams = {
        ...aiParams,
        fullName: selectedPublication?.data?.fullName,
        professionalTitle: selectedPublication?.data?.professionalTitle,
        industry: selectedPublication?.data?.industry,
        individualWebsite: selectedPublication?.data?.individualWebsite,
        bio: selectedPublication?.data?.bio,
        accomplishment: selectedPublication?.data?.accomplishment,
        philosophy: selectedPublication?.data?.philosophy,
        currentPosition: selectedPublication?.data?.currentPosition,
        previousRole: selectedPublication?.data?.previousRole || undefined, // optional
        projects: selectedPublication?.data?.projects,
        quote: selectedPublication?.data?.quote || undefined, // optional
        individualMediaFeature:
          selectedPublication?.data?.individualMediaFeature,
        individualSocialMedialinks: [
          selectedPublication?.data?.individualSocialMedialinks,
        ],
      };
    }
    if (selectedOption?.coverImageUrl.length > 0) {
      aiParams = {
        ...aiParams,
        coverImageUrl: selectedOption?.coverImageUrl,
      };
    }

    dispatch(
      createArticle({
        ...aiParams,
        cb(res) {
          if (res?.data) {
            navigate("/addOn-Article-Detail", {
              state: {
                addOnInfo: res?.data?.data,
                selectedPublication,
              },
            });
          }
        },
      })
    );
  };
  console.log(userInfo, "userInfo");
  const handleOptionClick = (option, flag) => {
    // if(location?.state?.type==="rejected"){
    //   return null
    // }
    // console.log(addOnInfo,'addOnInfo')
    // if (addOnInfo &&
    //   addOnInfo.reject &&
    //   addOnInfo.reject.length > 0 &&
    //   userInfo.addOns[flag] ||   // if this is true than show disable
    //   addOnInfo.addOns[flag]  // if this is true than show disable
    //   // addOnInfo.newAddOns[flag ]  // if this is true than show disable

    //  )
    //   return;

    if (flag === "professional") {
      setSelectedOption({ ...selectedOption, professional: option });
    } else if (flag === "interviewer") {
      setSelectedOption({ ...selectedOption, interviewer: option });
    } else if (flag === "coverImage") {
      if (!option) {
        // Reset the image URL when "No" is selected
        setSelectedOption({
          ...selectedOption,
          coverImage: option,
          coverImageUrl: "",
        });
      } else {
        setSelectedOption({
          ...selectedOption,
          coverImage: option,
        });
      }
    }
  };

  const handleCheckBox = (key, value, idx) => {
    let keyName = `need${key}Assistant`;
    setAssistantCheckbox((prev) => {
      const updated = { ...prev };
      if (prev[keyName] && prev[keyName].length) {
        let updatedCheckValue = [...prev[keyName]];
        updatedCheckValue[idx] = value;
        updated[keyName] = updatedCheckValue;
      } else {
        updated[keyName] = [value];
      }
      return updated;
    });
  };

  useEffect(() => {
    if (
      (selectedPublication &&
        selectedPublication.publicationIds &&
        selectedPublication.publicationIds.length > 0) ||
      (userInfo && userInfo.publications && userInfo.publications.length > 0) ||
      (draftData && draftData.publications && draftData.publications.length > 0)
    ) {
      // Initialize arrays for flags
      let needInterviewerAssistantValues = [];
      let needProfessionalAssistantValues = [];

      if (addOnInfo?.publications) {
        // Iterate through publications
        addOnInfo?.publications.forEach((publication) => {
          // Push values for needInterviewerAssistant
          needInterviewerAssistantValues.push(
            publication.needInterviewerAssistant || false // Default to false if not present
          );

          // Push values for needProfessionalAssistant
          needProfessionalAssistantValues.push(
            publication.needProfessionalAssistant || false // Default to false if not present
          );
        });
      } else {
        // Iterate through publications
        selectedPublication.publicationIds.forEach((publication) => {
          // Push values for needInterviewerAssistant
          needInterviewerAssistantValues.push(
            publication.needInterviewerAssistant || false // Default to false if not present
          );

          // Push values for needProfessionalAssistant
          needProfessionalAssistantValues.push(
            publication.needProfessionalAssistant || false // Default to false if not present
          );
        });
      }

      console.log(
        needInterviewerAssistantValues,
        "needInterviewerAssistantValues"
      );

      let interviewer =
        addOnInfo?.publications.map(
          (item, i) => item.needInterviewerAssistant
        ) ||
        userInfo?.publications.map(
          (item, i) => item.needInterviewerAssistant
        ) ||
        draftData?.publications.map((item, i) => item.needInterviewerAssistant);

      console.log(interviewer, "interviewer222");

      // let professional = selectedPublication.publication.map((item,i)=> item.needProfessionalAssistant)
      let professional =
        addOnInfo?.publications.map(
          (item, i) => item?.needProfessionalAssistant
        ) ||
        userInfo?.publications.map(
          (item, i) => item?.needProfessionalAssistant
        ) ||
        draftData?.publications.map(
          (item, i) => item.needProfessionalAssistant
        );
      setAssistantCheckbox({
        needInterviewerAssistant:
          assistantCheckboxs?.needInterviewerAssistant ||
          needInterviewerAssistantValues ||
          interviewer,
        needProfessionalAssistant:
          assistantCheckboxs?.needProfessionalAssistant ||
          needProfessionalAssistantValues ||
          professional,
      });
    }
  }, [selectedPublication || userInfo]);
  // const handleAddOnChange = (option, flag) => {
  //   console.log(userInfo?.addOns, addOnInfo, "addmn");
  //   if (
  //     addOnInfo.reject &&
  //     addOnInfo.reject.length > 0 &&
  //     addOnInfo.addOns[flag]
  //   )
  //     return;

  //   handleOptionClick(option, flag);
  // };
  return (
    <>
      <div className="content-wrapper  addOnArticleSection">
        <div className="innerHead">
          <img
            src={images.arrowLink}
            className="img-fluid pointCursor"
            alt="arrowLink image"
            onClick={() => {
              if (location?.state?.type === "draft") {
                navigate("/draftDetail", {
                  state: {
                    selectedPublicationDetails: selectedPublication,
                    draftData: userInfo,
                    type: location?.state?.type,
                    selectedOptions: selectedOption,
                    assistantCheckboxs: assistantCheckbox,
                  },
                });
              } else {
                navigate("/article-details", {
                  state: {
                    selectedPublicationDetails: selectedPublication,
                    draftData: userInfo,
                    type: "CreateArticle",
                    selectedOptions: selectedOption,
                    assistantCheckboxs: assistantCheckbox,
                  },
                });
              }
            }}
          />
          <h2 className="innerHeadText my-3">Add-On Article</h2>
        </div>
        <div className="addOnBox mt-4">
          <div className="addOnSubBox mt-3">
            <h4 className="stateText">
              Do you require a professional Writer to craft your article?{" "}
              {`(Cost: +$200)`}
            </h4>
            <p className="addOnPara mt-2">
              This service includes creating an article
            </p>
            <ul
              className={`planTabs_ ${
                location?.state?.type === "rejected" &&
                ((userInfo && userInfo?.addOns?.professional) ||
                  (draftData && draftData?.addOns?.professional))
                  ? "disabled"
                  : ""
              }`}
            >
              <li
                className={`planttabslist ${
                  !selectedOption.professional ? "active" : ""
                }`}
                // onClick={() => handleOptionClick(false, "professional")}
                // onClick={() => handleOptionClick(false, "professional")}
                onClick={() =>
                  (userInfo && !userInfo?.addOns?.professional) ||
                  !draftData?.addOns?.professional
                    ? handleOptionClick(false, "professional")
                    : void 0
                }
              >
                No
              </li>
              <li
                className={`planttabslist ${
                  selectedOption.professional ? "active" : ""
                }`}
                // onClick={() => handleOptionClick(true, "professional")}
                //  onClick={() => handleOptionClick(true, "professional")}
                onClick={() =>
                  // ((userInfo && !userInfo?.addOns?.professional) ||
                  //   (draftData && !draftData?.addOns?.professional)) &&
                  (userInfo && !userInfo?.addOns?.professional) ||
                  !draftData?.addOns?.professional
                    ? handleOptionClick(true, "professional")
                    : void 0
                }
              >
                Yes
              </li>
            </ul>

            <div className="addOnArticle_Checkbox">
              {selectedOption?.professional && (
                <>
                  {selectedPublication?.publicationIds?.length > 0
                    ? selectedPublication.publicationIds.map((item, idx) => (
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            id={idx}
                            className="form-check-input addOnArticle_CheckBox mt-0"
                            checked={
                              assistantCheckbox?.needProfessionalAssistant?.[
                                idx
                              ]
                            }
                            onChange={(event) => {
                              handleCheckBox(
                                "Professional",
                                event.target.checked,
                                idx
                              );
                            }}
                          />
                          <h4 className="sub_p_text ms-2" key={item.id}>
                            {item?.value}
                          </h4>
                        </div>
                      ))
                    : userInfo?.publications?.map((item, idx) => (
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            id={idx}
                            className="form-check-input addOnArticle_CheckBox mt-0"
                            checked={
                              assistantCheckbox?.needProfessionalAssistant?.[
                                idx
                              ]
                            }
                            onChange={(event) => {
                              handleCheckBox(
                                "Professional",
                                event.target.checked,
                                idx
                              );
                            }}
                          />
                          <h4 className="sub_p_text ms-2" key={item.id}>
                            {item?.publicationName}
                          </h4>
                        </div>
                      ))}
                </>
              )}
            </div>
          </div>
          <div className="addOnSubBox mt-4">
            <h4 className="stateText">
              Do you need an interviewer to assist with your Article?{" "}
              {`(Cost: +$100)`}
            </h4>
            <p className="addOnPara mt-2">
              An interviewer can help you gather expert insights and depth of
              your article
            </p>
            <ul
              className={`planTabs_ ${
                location?.state?.type === "rejected" &&
                ((userInfo && userInfo?.addOns?.interviewer) ||
                  (draftData && draftData?.addOns?.interviewer))
                  ? "disabled"
                  : ""
              }`}
            >
              {/* <ul className={`planTabs_ ${location?.state?.type === "rejected" ? "disabled" : ""}`}> */}
              <li
                className={`planttabslist ${
                  !selectedOption.interviewer ? "active" : ""
                }`}
                // onClick={() => handleOptionClick(false, "interviewer")}
                // onClick={() => handleOptionClick(false, "interviewer")}

                onClick={() =>
                  // ((userInfo && !userInfo?.addOns?.interviewer) ||
                  //   (draftData && !draftData?.addOns?.interviewer)) &&
                  // handleOptionClick(false, "interviewer")

                  (userInfo && !userInfo?.addOns?.interviewer) ||
                  !draftData?.addOns?.interviewer
                    ? handleOptionClick(false, "interviewer")
                    : void 0
                }
              >
                No
              </li>
              <li
                className={`planttabslist ${
                  selectedOption.interviewer ? "active" : ""
                }`}
                // onClick={() => handleOptionClick(true, "interviewer")}
                // onClick={() => handleOptionClick(true, "interviewer")}
                onClick={() =>
                  // (!userInfo?.addOns?.interviewer ||
                  //   !draftData?.addOns?.interviewer) &&
                  // handleOptionClick(true, "interviewer")

                  (userInfo && !userInfo?.addOns?.interviewer) ||
                  !draftData?.addOns?.interviewer
                    ? handleOptionClick(true, "interviewer")
                    : void 0
                }
              >
                Yes
              </li>
            </ul>
            <div className="addOnArticle_Checkbox">
              {selectedOption?.interviewer && (
                <>
                  {selectedPublication?.publicationIds?.length > 0
                    ? selectedPublication.publicationIds.map((item, idx) => (
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            id={idx}
                            className="form-check-input addOnArticle_CheckBox mt-0"
                            checked={
                              assistantCheckbox?.needInterviewerAssistant?.[idx]
                            }
                            onChange={(event) => {
                              handleCheckBox(
                                "Interviewer",
                                event.target.checked,
                                idx
                              );
                            }}
                          />
                          <h4 className="sub_p_text ms-2" key={item.id}>
                            {item?.value}
                          </h4>
                        </div>
                      ))
                    : userInfo?.publications?.map((item, idx) => (
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            id={idx}
                            className="form-check-input addOnArticle_CheckBox mt-0"
                            checked={
                              assistantCheckbox?.needInterviewerAssistant?.[idx]
                            }
                            onChange={(event) => {
                              handleCheckBox(
                                "Interviewer",
                                event.target.checked,
                                idx
                              );
                            }}
                          />
                          <h4 className="sub_p_text ms-2" key={item.id}>
                            {item?.publicationName}
                          </h4>
                        </div>
                      ))}
                </>
              )}
            </div>
          </div>

          <div className="row w-100">
            <div className="col-md-8 p-0">
              <div className="addOnSubBox mt-3">
                <h4 className="stateText">
                  Would you like to add cover art to your article?
                </h4>
                <p className="addOnPara mt-2">
                  Cover art visually represents your article
                </p>
                <ul
                  className={`planTabs_ ${
                    location?.state?.type === "rejected" &&
                    userInfo?.addOns?.coverImageFlag
                      ? "disabled"
                      : ""
                  }`}
                >
                  {/* <ul className={`planTabs_ ${location?.state?.type === "rejected" ? "disabled" : ""}`}> */}
                  <li
                    className={`planttabslist ${
                      !selectedOption.coverImage ? "active" : ""
                    }`}
                    // onClick={() => handleOptionClick(false, "coverImage")}
                    onClick={() =>
                      // ((userInfo && !userInfo?.addOns?.coverImageFlag) ||
                      //   (draftData && !draftData?.addOns?.coverImageFlag)) &&
                      // handleOptionClick(false, "coverImage")

                      (userInfo && !userInfo?.addOns?.coverImageFlag) ||
                  !draftData?.addOns?.coverImageFlag
                        ? handleOptionClick(false, "coverImage")
                        : void 0
                    }
                  >
                    No
                  </li>
                  <li
                    className={`planttabslist ${
                      selectedOption.coverImage ? "active" : ""
                    }`}
                    // onClick={() => handleOptionClick(true, "coverImage")}
                    onClick={() =>
                      // (!userInfo?.addOns?.coverImageFlag ||
                      //   !draftData?.addOns?.coverImageFlag) &&
                      // handleOptionClick(true, "coverImage")
                      (userInfo && !userInfo?.addOns?.coverImageFlag) ||
                  !draftData?.addOns?.coverImageFlag
                        ? handleOptionClick(true, "coverImage")
                        : void 0
                    }
                  >
                    Yes
                  </li>
                </ul>
                <p className="userText mt-3 w-100">
                  Select Both a writer and interviewer and receive a discount
                  fom
                  <span className="morePriceTxt"> $300 </span> to
                  <span className="lessPriceTxt"> $270! </span>
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="addOnDetailLeft">
                <div className="addOnDetailRight">
                  {selectedOption?.coverImage && (
                    // <figure className="uploadInner">
                    //   <label className="uploadAddLabel">
                    //     <input
                    //       type="file"
                    //       id="image"
                    //       name="image"
                    //       accept="image/*"
                    //       onChange={(e) => handleUploadImage(e)}
                    //     />
                    //     {selectedOption?.coverImageUrl &&
                    //     selectedOption.coverImageUrl !== undefined ? (
                    //       <img
                    //         src={selectedOption.coverImageUrl}
                    //         alt="defaultUser"
                    //         className="publishLogoImg"
                    //       />
                    //     ) : (
                    //       <img
                    //         src={images.uploadFile}
                    //         alt="defaultUser"
                    //         className="fileImg"
                    //       />
                    //     )}
                    //   </label>
                    // </figure>
                    <img
                      src={images.articlePic}
                      alt="articlePic"
                      className="articleCover_Img"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-end">
          {!selectedOption?.professional &&
          !selectedOption?.interviewer &&
          !selectedOption?.coverImage ? (
            <>
              <div className="d-flex align-items-center">
                <button
                  className="signUpBtn  me-3 mt-3"
                  type="button"
                  onClick={() => {
                    if (location?.state?.type === "draft") {
                      navigate("/draftDetail", {
                        state: {
                          selectedPublicationDetails: selectedPublication,
                          draftData: userInfo,
                          type: location?.state?.type,
                        },
                      });
                    } else {
                      navigate("/article-details", {
                        state: {
                          selectedPublicationDetails: selectedPublication,
                          draftData: userInfo,
                          type: location?.state?.type,
                        },
                      });
                    }
                  }}
                >
                  Back
                </button>

                <button
                  className="signUpBtn  mt-3 me-3"
                  type="button"
                  onClick={() => {
                    navigate("/article-details", {
                      state: {
                        ...location.state,
                        selectedPublication: {
                          ...location?.state?.selectedPublication,
                          type:
                            location?.state?.type == "articleAI"
                              ? "submitAIArticle"
                              : "submitArticle",
                        },
                        type:
                          location?.state?.type == "articleAI"
                            ? "submitAIArticle"
                            : "submitArticle",
                      },
                    });
                  }}
                >
                  Skip
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex align-items-center">
                <button
                  className="signUpBtn  me-3 mt-3"
                  type="button"
                  onClick={() => {
                    if (location?.state?.type === "draft") {
                      navigate("/draftDetail", {
                        state: {
                          selectedPublicationDetails: selectedPublication,
                          draftData: userInfo,
                          type: location?.state?.type,
                          selectedOptions: selectedOption,
                          assistantCheckboxs: assistantCheckbox,
                        },
                      });
                    } else {
                      navigate("/article-details", {
                        state: {
                          selectedPublicationDetails: selectedPublication,
                          draftData: userInfo,
                          type: "CreateArticle",
                          selectedOptions: selectedOption,
                          assistantCheckboxs: assistantCheckbox,
                        },
                      });
                    }
                  }}
                >
                  Back
                </button>

                <button
                  className="signUpBtn  mt-3 me-3"
                  type="button"
                  onClick={() => {
                    handleAddOnArticle();
                  }}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddOnArticle;

// import userSaga from "./user";
import { all, fork, spawn } from "redux-saga/effects";
import authSaga from "./auth";
import webSaga from "./web";
import articlesSaga from "./articles";
import ticketSaga from "./ticket";
import homePageSaga from "./homePage";
import notificationSaga from "./notification";
import subscriptionSaga from "./subscription";

export default function* rootSaga() {
  yield all([
    spawn(homePageSaga),
    spawn(authSaga),
    spawn(webSaga),
    spawn(articlesSaga),
    spawn(ticketSaga),
    spawn(notificationSaga),
    spawn(subscriptionSaga)
    // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
    // fork(saga2),
  ]);
}

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Image from "../../utilities/images";

const EmailSuccessful = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (location?.state?.flag?.flag === "Plan" && location?.state?.flag?.PlanData !="Free") {
        navigate("/subscription", {
          state:location.state?.flag});
      } else {
        navigate("/dashboard");
      } // Replace '/next-page' with your desired route
    }, 3000); // Delay of 3 seconds

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <>
      <section className="authbgSection emailSuccessSec">
        <img
          src={Image.authlogo}
          alt="authLogo"
          className="authLogoImg img-fluid"
        />
        <div className="authOuterSec">
          <div className="signupSection authInnerSec text-center">
            <img src={Image.verifyImg} alt="verifyImg" className="img-fluid" />
            <p className="authSubText mt-4">
              Your email address has been successfully verified!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailSuccessful;
